import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const ShieldSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M10.4902 2.23L5.50016 4.11C4.35016 4.54 3.41016 5.9 3.41016 7.12V14.55C3.41016 15.73 4.19016 17.28 5.14016 17.99L9.44016 21.2C10.8502 22.26 13.1702 22.26 14.5802 21.2L18.8802 17.99C19.8302 17.28 20.6102 15.73 20.6102 14.55V7.12C20.6102 5.89 19.6702 4.53 18.5202 4.1L13.5302 2.23C12.6802 1.92 11.3202 1.92 10.4902 2.23Z"
        stroke="white"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M9.0498 11.87L10.6598 13.48L14.9598 9.17999"
        stroke="white"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
