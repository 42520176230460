import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export type WalletSvgPropsType = {
  svgProps?: SvgIconProps;
  isSelected?: boolean;
};

export const WalletSvg: FC<WalletSvgPropsType> = ({ svgProps, isSelected }) => {
  return (
    <SvgIcon
      width="37"
      height="31"
      viewBox="0 0 37 31"
      {...svgProps}
      sx={{
        fill: 'none',
        ...svgProps?.sx,
      }}
    >
      {isSelected ? (
        <path
          d="M0 23.943C0 18.289 0 12.632 0 6.97797C0.0867188 6.5675 0.153203 6.14836 0.260156 5.74367C1.18516 2.28648 4.19719 0.00289063 7.85094 0C16.968 0 26.085 0 35.1991 0C35.3321 0 35.4651 0 35.5952 0.00867188C36.3525 0.0578125 36.8757 0.589687 36.9566 1.39617C37.0347 2.16219 36.5866 2.85594 35.8784 3.02648C35.6472 3.08141 35.4015 3.07852 35.1616 3.07852C28.2848 3.07852 21.408 3.07852 14.5341 3.07852C12.2938 3.07852 10.0536 3.07852 7.81336 3.07852C6.41719 3.07852 5.23492 3.58148 4.26656 4.625C4.35906 4.72328 4.43711 4.81289 4.52383 4.89383C5.4575 5.7668 6.56461 6.17727 7.83938 6.17727C15.9909 6.17438 24.1454 6.17438 32.297 6.17727C34.9592 6.17727 36.9942 8.21227 36.9971 10.8774C37.0029 15.988 37 21.0987 36.9942 26.2093C36.9942 26.5677 36.9624 26.9377 36.8815 27.2875C36.3843 29.4786 34.5661 30.9123 32.2738 30.9152C27.9639 30.921 23.6511 30.9152 19.3412 30.9152C15.4041 30.9152 11.4642 30.9441 7.52719 30.9008C3.99195 30.8603 0.997266 28.3802 0.202344 24.9259C0.127188 24.6021 0.0664844 24.2726 0 23.943ZM29.3283 18.5376C29.3225 19.4452 29.9382 20.0927 30.817 20.0985C31.6957 20.1043 32.3345 19.4655 32.3461 18.5694C32.3577 17.6791 31.7217 17.0171 30.8488 17.0055C29.9729 16.994 29.3341 17.6386 29.3283 18.5376Z"
          fill="#204D6C"
        />
      ) : (
        <>
          <path
            d="M0 23.943C0 18.2889 0 12.6348 0 6.97789C0.158984 6.33617 0.260156 5.67422 0.482734 5.05852C1.60141 1.96844 4.45156 0.00570421 7.81336 0.00281359C16.9651 -0.00296766 26.1139 0.00281359 35.2656 0.00281359C35.361 0.00281359 35.4593 0.00281359 35.5547 0.00859484C36.1617 0.0490636 36.5866 0.361251 36.8584 0.898907C36.9046 0.991407 36.9538 1.0868 37 1.1793V1.90484C36.6387 2.83852 35.9362 3.09867 34.9766 3.09578C28.1634 3.06977 21.3473 3.08133 14.5341 3.08133C12.2938 3.08133 10.0565 3.08133 7.81625 3.08133C6.42008 3.08133 5.23781 3.5843 4.26945 4.62781C4.36195 4.72609 4.44 4.8157 4.52672 4.89664C5.46039 5.76961 6.5675 6.18008 7.83938 6.18008C15.9909 6.17719 24.1396 6.1743 32.2912 6.18008C34.1036 6.18008 35.4969 6.97789 36.393 8.55328C36.6849 9.06492 36.8034 9.67484 37.0029 10.2385V26.8394C36.9855 26.8712 36.9624 26.903 36.9566 26.9377C36.3727 29.5768 34.7106 30.918 32.0223 30.918C27.8194 30.918 23.6164 30.918 19.4134 30.918C15.4504 30.918 11.4902 30.947 7.52719 30.9036C3.99195 30.8631 1.00016 28.383 0.205234 24.9287C0.130078 24.602 0.069375 24.2696 0.00289062 23.9401L0 23.943ZM3.10453 7.82484C3.09875 7.92024 3.09008 8.03586 3.09008 8.15149C3.09008 13.1176 3.08719 18.0808 3.09008 23.0469C3.09008 25.8161 5.09328 27.8366 7.84516 27.8366C15.962 27.8424 24.076 27.8366 32.1929 27.8366C33.2942 27.8366 33.9099 27.2152 33.9099 26.1052C33.9099 24.4633 33.9099 22.8185 33.9099 21.1766C33.9099 17.783 33.907 14.3866 33.9128 10.993C33.9128 10.5131 33.8116 10.0853 33.4705 9.73555C33.1063 9.36266 32.6525 9.27594 32.1495 9.27594C24.0702 9.28172 15.9909 9.27883 7.91164 9.28172C6.89125 9.28172 5.8882 9.14875 4.94875 8.74695C4.32438 8.48102 3.7318 8.13992 3.10742 7.82484H3.10453Z"
            fill="#204D6C"
          />
          <path
            d="M27.7501 18.5376C27.7559 17.6965 28.4612 16.9969 29.2966 17.0027C30.1348 17.0085 30.8344 17.7109 30.8344 18.5492C30.8344 19.4019 30.1204 20.1101 29.2648 20.0986C28.4207 20.087 27.7414 19.3875 27.7501 18.5376Z"
            fill="#204D6C"
          />
          <clipPath id="clip0_693_14797">
            <rect width="37" height="30.9297" fill="white" />
          </clipPath>
        </>
      )}
    </SvgIcon>
  );
};
