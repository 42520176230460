import { Typography } from '@mui/material';
import { FC } from 'react';
import EmptyPage from 'src/components/organisms/emptyPage/EmptyPage';

const Notfound: FC = () => {
  return (
    <EmptyPage
      titleElement={
        <>
          <Typography variant="h1" align="center" paragraph fontWeight="bolder">
            404
          </Typography>
          <Typography variant="h5" align="center" paragraph sx={{ mb: 6 }}>
            متاسفانه صفحه مورد نظر پیدا نشد
          </Typography>
        </>
      }
    />
  );
};

export default Notfound;
