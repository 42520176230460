import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { logoutAction } from '../slices/auth.slice';
import { RootStateType } from '../store';
import { toast } from 'react-toastify';

let baseUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEVELOPMENT_BASE_URL
    : process.env.REACT_APP_PRODUCTION_BASE_URL;

if (!baseUrl || baseUrl === 'auto') {
  baseUrl = window.location.origin;
}

if (baseUrl === 'https://melk-iota.vercel.app/') {
  baseUrl = 'https://bluk.ir';
}

export const baseQuery: BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
    abortController?: AbortController;
    onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
  },
  unknown,
  unknown
> = async (
  {
    url,
    method = 'GET',
    body,
    params,
    headers,
    abortController,
    onUploadProgress,
  },
  { getState, dispatch }
) => {
  const errorMessage =
    'مشکلی پیش آمده است، لطفاً چند دقیقه‌ی دیگر دوباره امتحان کنید';

  const { auth } = getState() as RootStateType;
  try {
    const result = await axios({
      url: baseUrl + url,
      method,
      data: body,
      params,
      headers: {
        ...headers,
        ...(auth?.token && { authorization: `Bearer ${auth.token}` }),
      },
      ...(abortController && { signal: abortController.signal }),
      onUploadProgress,
    });
    return { data: result.data };
  } catch (axiosError) {
    const e = axiosError as AxiosError;
    const error = {
      status: e.response?.status,
      data: (e.response?.data || e.message) as any,
    };

    console.log('rtk query error handler => ', error);
    if (error?.data === 'canceled' || !error.status) {
      return { error };
    }
    switch (error.status) {
      case 404:
        return { error };
      case 401:
        if (auth.token) {
          dispatch(logoutAction());
        }
        break;
      default:
        toast.error(
          error.data?.message + ` (status code: ${error.status})` ||
            errorMessage
        );
        console.log(
          error.data?.message
            ? error.status
              ? error.data?.message + ` (status code: ${error.status})`
              : error.data?.message
            : errorMessage
        );
        break;
    }
    return { error };
  }
};
