import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useProject } from 'src/components/hooks/useProject';
import { price2Persian } from 'src/utils/price2Persian.util';
import { projectMode2persian } from 'src/utils/projectMode.util';
import { useProfile } from 'src/components/hooks/useProfile';
import {
  OrderDto,
  OrderRo,
  useOrderControllerCreateProjectMutation,
} from 'src/app/services/generatedApi';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from 'src/app/reduxCustomHooks';
import {
  clearUnfinishedOrderAction,
  setUnfinishedOrderAction,
} from 'src/app/slices/latestUnfinishedOrder.slice';

type OrderReceiptPropsType = {
  setShowReceiptDialog: Dispatch<SetStateAction<boolean>>;
  setOrderStatus: Dispatch<SetStateAction<OrderRo['status'] | null>>;
} & OrderDto;

export const OrderReceipt: FC<OrderReceiptPropsType> = ({
  setShowReceiptDialog,
  setOrderStatus,
  shareAmount,
  project,
  orderType,
}) => {
  const { project: projectData } = useProject(project);
  const { profile } = useProfile();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!project || !projectData) return;
    dispatch(
      setUnfinishedOrderAction({
        project: project,
        shareAmount,
        orderType,
      })
    );
  }, [dispatch, orderType, project, projectData, shareAmount]);

  const [createOrder, { isLoading: createOrderLoading }] =
    useOrderControllerCreateProjectMutation();

  const investmentAmount = useMemo(
    () => (projectData ? shareAmount * projectData.priceOfShare : 0),
    [projectData, shareAmount]
  );

  const hasEnoughMoneyInWallet = useMemo(
    () =>
      profile && investmentAmount
        ? investmentAmount <= profile.walletBalance
        : false,
    [investmentAmount, profile]
  );

  const removeReceipt = () => {
    dispatch(clearUnfinishedOrderAction());
    setShowReceiptDialog(false);
  };

  const payHandler = () => {
    if (!projectData) return;
    if (hasEnoughMoneyInWallet) {
      createOrder({
        orderDto: {
          shareAmount: Math.floor(
            Number(investmentAmount) / projectData.priceOfShare
          ),
          project,
          orderType,
        },
      })
        .unwrap()
        .then((res) => {
          if (!res) return;
          dispatch(clearUnfinishedOrderAction());
          setOrderStatus(res.status);
        });
    } else {
      alert('بزودی اضافه خواهد شد');
      // TODO: Must going to bank payment gateway
    }
  };

  return (
    <Stack rowGap={1}>
      <Typography variant="t700_20">رسید</Typography>
      <Stack rowGap={1}>
        <Typography variant="t700_16">جزئیات سرمایه گذاری:</Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="t400_14">نوع سرمایه گذاری:</Typography>
          <Typography variant="t700_14">
            {projectMode2persian(projectData?.attributesFeatures.mode)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="t400_14">نام پروژه:</Typography>
          <Typography variant="t700_14">{projectData?.title}</Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="t400_14">مبلغ سرمایه گذاری:</Typography>
          <Typography variant="t700_14">
            {price2Persian(investmentAmount)} تومان
          </Typography>
        </Stack>
      </Stack>
      <Divider flexItem />
      <Stack rowGap={1}>
        <Typography variant="t700_16">جزئیات پرداخت:</Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="t400_14">مبلغ قابل پرداخت:</Typography>
          <Typography variant="t700_14">
            {price2Persian(investmentAmount)} تومان
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="t400_14">موجودی کیف پول:</Typography>
          <Typography variant="t700_14">
            {price2Persian(profile?.walletBalance || 0)} تومان
          </Typography>
        </Stack>
      </Stack>
      <Grid2
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt={4}
      >
        <Grid2 xs={4.5} lg={4}>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            onClick={removeReceipt}
          >
            حذف رسید
          </Button>
        </Grid2>
        <Grid2 xs={7} lg={7.5}>
          <LoadingButton
            loading={createOrderLoading}
            color="success"
            variant="contained"
            fullWidth
            onClick={payHandler}
          >
            {hasEnoughMoneyInWallet ? 'پرداخت' : 'شارژ کیف پول'}
          </LoadingButton>
        </Grid2>
      </Grid2>
    </Stack>
  );
};
