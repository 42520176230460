import { FC, useState, useEffect } from 'react';
import { OrderReceipt } from './OrderReceipt';
import { useProject } from 'src/components/hooks/useProject';
import { OrderRo } from 'src/app/services/generatedApi';
import { SuccessFailBanner } from 'src/components/molecules/SuccessFailBanner';
import { Navigate, useNavigate } from 'react-router-dom';
import { BuyOrder } from './BuyOrder';
import { SellOrder } from './SellOrder';

type CreateOrderPropsType = {
  orderType: 'BUY' | 'SELL';
  projectId: string;
};

export const CreateOrder: FC<CreateOrderPropsType> = ({
  orderType,
  projectId,
}) => {
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [showReceiptDialog, setShowReceiptDialog] = useState(false);
  const [orderStatus, setOrderStatus] = useState<OrderRo['status'] | null>(
    null
  );

  const { project } = useProject(projectId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!project || investmentAmount) return;
    setInvestmentAmount(project.priceOfShare.toString());
  }, [investmentAmount, project]);

  if (orderStatus) {
    return (
      <SuccessFailBanner
        isSuccess={orderStatus !== 'REJECT'}
        onClick={() => {
          setInvestmentAmount('');
          setShowReceiptDialog(false);
          navigate('/project/' + projectId);
        }}
      />
    );
  } else if (showReceiptDialog && project) {
    return (
      <OrderReceipt
        setShowReceiptDialog={setShowReceiptDialog}
        setOrderStatus={setOrderStatus}
        project={projectId}
        shareAmount={Math.floor(
          Number(investmentAmount) / project.priceOfShare
        )}
        orderType="BUY"
      />
    );
  } else if (orderType === 'BUY' && project) {
    return (
      <BuyOrder
        statusDuration={project.statusDuration}
        priceOfShare={project.priceOfShare}
        estimatedProfit={project.estimatedProfit}
        setShowReceiptDialog={setShowReceiptDialog}
        investmentAmount={investmentAmount}
        setInvestmentAmount={setInvestmentAmount}
      />
    );
  } else if (orderType === 'SELL' && project) {
    return (
      <SellOrder
        statusDuration={project.statusDuration}
        priceOfShare={project.priceOfShare}
        estimatedProfit={project.estimatedProfit}
        setShowReceiptDialog={setShowReceiptDialog}
        investmentAmount={investmentAmount}
        setInvestmentAmount={setInvestmentAmount}
      />
    );
  } else {
    return <Navigate to="/dashboard/projects" />;
  }
};
