import { createSlice } from '@reduxjs/toolkit';
import { OrderDto } from '../services/generatedApi';

const initialState = () => {
  let result: OrderDto | null = null;
  const localSt = localStorage.getItem('latestUnfinishedOrder');
  if (localSt) {
    result = JSON.parse(localSt);
  }
  return result;
};

const latestUnfinishedOrderSlice = createSlice({
  name: 'latestUnfinishedOrder',
  initialState: initialState(),
  reducers: {
    setUnfinishedOrder: (_, { payload }: { payload: OrderDto }) => {
      localStorage.setItem('latestUnfinishedOrder', JSON.stringify(payload));
      return payload;
    },
    clearUnfinishedOrder: () => {
      localStorage.removeItem('latestUnfinishedOrder');
      return null;
    },
  },
});

export const {
  setUnfinishedOrder: setUnfinishedOrderAction,
  clearUnfinishedOrder: clearUnfinishedOrderAction,
} = latestUnfinishedOrderSlice.actions;

export default latestUnfinishedOrderSlice.reducer;
