import { Box, Stack, Typography, Button, Paper } from '@mui/material';
import { useState } from 'react';
import { BackArrow } from 'src/components/atoms/icons/BackArrow';
import { ProgressBar } from 'src/components/atoms/ProgressBar';
import { contentText } from './TourContent';
import { useNavigate } from 'react-router-dom';

export const ProjectTour = () => {
  const [contentIndex, setContentIndex] = useState(0);

  const navigate = useNavigate();

  const handleNextClick = () => {
    if (contentIndex < contentText.length - 1) {
      setContentIndex(contentIndex + 1);
    }
    if (contentIndex === contentText.length - 1) {
      navigate('/login');
    }
    // else {handleClose}
  };

  const currentContent = contentText[contentIndex];

  return (
    <Paper sx={{ backgroundColor: 'white', height: '100%' }}>
      <Stack height="100%">
        <Stack
          justifyContent="space-between"
          width="100%"
          direction="row"
          p={3}
        >
          <Box
            sx={{
              width: '100px',
              height: '60px',
              backgroundColor: '#D9D9D9',
            }}
          />
          {contentIndex !== contentText.length - 1 && (
            <Button
              sx={{
                backgroundColor: '#DFDFDF',
                borderRadius: '100px',
                p: '5px 40px',
                color: '#252B5C',
              }}
              variant="contained"
              onClick={() => {
                setContentIndex(contentText.length - 1);
              }}
            >
              رد کردن
            </Button>
          )}
        </Stack>
        <Box sx={{ p: 3 }}>
          <Typography sx={{ fontSize: '30px', color: '#252B5C' }}>
            {currentContent.title}
          </Typography>
          <Typography sx={{ fontSize: '20px', color: '#53587A' }}>
            {currentContent.text}
          </Typography>
        </Box>
        <Box sx={{ position: 'relative', flexGrow: 1 }}>
          <Box p={2} height="100%">
            <Box
              sx={{
                height: '100%',
                textAlign: 'center',
                backgroundImage: 'url(/images/onboard/tour.png)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                borderRadius: 5,
              }}
            />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '5%',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <ProgressBar
              value={(contentIndex + 1) * 20}
              sx={{
                backgroundColor: '#FFFFFF66',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: 'white',
                },
                transform: 'scale(-1)',
              }}
            />
            <Stack direction="row" justifyContent="space-around" width="100%">
              <Button
                sx={{
                  backgroundColor: '#8BC83F',
                  borderRadius: '10px',
                  fontSize: '18px',
                  fontWeight: 600,
                  p: { md: '15px 80px', xs: '10px 60px' },
                }}
                onClick={() => {
                  handleNextClick();
                }}
                variant="contained"
              >
                {contentIndex === contentText.length - 1
                  ? 'سرمایه‌گذاری کن '
                  : 'بعدی'}
              </Button>
              {contentIndex !== 0 && (
                <Button
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '50px',
                    width: '70px',
                    height: '70px',
                  }}
                  variant="contained"
                  onClick={() => {
                    setContentIndex(contentIndex - 1);
                  }}
                >
                  <BackArrow />
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
};
