import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type PersonSvgPropsType = {
  svgProps?: SvgIconProps;
};

export const PersonSvg: FC<PersonSvgPropsType> = ({ svgProps }) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...svgProps}
      sx={{
        fill: 'none',
        ...svgProps?.sx,
      }}
    >
      <path
        d="M10.1331 9.05829C10.0498 9.04996 9.9498 9.04996 9.85814 9.05829C7.8748 8.99163 6.2998 7.36663 6.2998 5.36663C6.2998 3.32496 7.9498 1.66663 9.9998 1.66663C12.0415 1.66663 13.6998 3.32496 13.6998 5.36663C13.6915 7.36663 12.1165 8.99163 10.1331 9.05829Z"
        stroke="#204D6C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.9666 12.1334C3.94993 13.4834 3.94993 15.6834 5.9666 17.025C8.25827 18.5584 12.0166 18.5584 14.3083 17.025C16.3249 15.675 16.3249 13.475 14.3083 12.1334C12.0249 10.6084 8.2666 10.6084 5.9666 12.1334Z"
        stroke="#204D6C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
