import { FC, MouseEventHandler } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { BORDER_RADIUS50 } from 'src/configs/theme';

type SuccessFailBannerPropsType = {
  isSuccess?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const SuccessFailBanner: FC<SuccessFailBannerPropsType> = ({
  isSuccess = false,
  onClick,
}) => {
  return (
    <Stack rowGap={3.3} alignItems="center">
      <Stack
        alignItems="center"
        justifyContent="center"
        bgcolor={isSuccess ? 'success.main' : 'error.main'}
        width={70}
        height={70}
        borderRadius={BORDER_RADIUS50}
      >
        <Typography color="white" variant="t700_24">
          {isSuccess ? '✓' : '✗'}
        </Typography>
      </Stack>
      <Stack alignItems="center">
        <Typography variant="t400_24">تراکنش شما</Typography>
        <Typography variant="t700_24">
          با {isSuccess ? 'موفقیت' : 'شکست'}
        </Typography>
        <Typography variant="t400_24">
          {isSuccess ? 'انجام' : 'مواجه'} شد.
        </Typography>
      </Stack>
      {isSuccess && (
        <Typography variant="t500_16">
          می‌توانید داخل کیف پول، بخش درخواست‌ها وضعیت درخواست خود را ببینید.
        </Typography>
      )}
      <Button fullWidth onClick={onClick}>
        ادامه
      </Button>
    </Stack>
  );
};
