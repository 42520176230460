import { FC } from 'react';
import { Skeleton, Stack } from '@mui/material';

type ProjectsCardSkeletonPropsType = {};

export const ProjectsCardSkeleton: FC<ProjectsCardSkeletonPropsType> = () => {
  return (
    <Stack sx={{ height: 200, width: '100%' }}>
      <Skeleton />
    </Stack>
  );
};
