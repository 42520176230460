import { Box, Button } from '@mui/material';
import { Back } from 'src/components/atoms/icons/Back';
import { CreditCardList } from 'src/components/organisms/creditCard/CreditCardList';

export const CreditCardPage = () => {
  return (
    <>
      <Box sx={{ width: '100%', textAlign: 'right', p: 3 }}>
        <Button
          sx={{
            backgroundColor: '#6D86A233',
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            textAlign: 'center',
          }}
          variant="contained"
        >
          <Back />
        </Button>
      </Box>
      <CreditCardList />
    </>
  );
};
