import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export type PlaySvgPropsType = {
  svgProps?: SvgIconProps;
  isSelected?: boolean;
};

export const PlaySvg: FC<PlaySvgPropsType> = ({ svgProps, isSelected }) => {
  return (
    <SvgIcon
      width="24"
      height="26"
      viewBox="0 0 24 26"
      {...svgProps}
      sx={{
        fill: 'none',
        ...svgProps?.sx,
      }}
    >
      <path
        d="M1 13.0001V8.10506C1 2.03673 5.30833 -0.456604 10.57 2.58673L14.805 5.0434L19.04 7.50006C24.3017 10.5434 24.3017 15.5117 19.04 18.5551L14.805 21.0117L10.57 23.4684C5.30833 26.4567 1 23.9817 1 17.8951V13.0001Z"
        stroke="#204D6C"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
