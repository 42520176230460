import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { LandingSliderCard } from './LandingSliderCard';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './slider.css';
import { Box, IconButton, Stack } from '@mui/material';
import { DollarCircleSvg } from 'src/components/atoms/icons/DollarCircleSvg';
import { LikeDislikeSvg } from 'src/components/atoms/icons/LikeDislikeSvg';
import { GraphSvg } from 'src/components/atoms/icons/GraphSvg';
import { TrendUpSvg } from 'src/components/atoms/icons/TrendUpSvg';
import { ShieldSvg } from 'src/components/atoms/icons/ShieldSvg';
import { ChartSvg } from 'src/components/atoms/icons/ChartSvg';
import { BuildingSvg } from 'src/components/atoms/icons/BuildingSvg';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { useCallback, useRef } from 'react';

const sliderCards = [
  {
    title: 'فقط 100،000 تومان',
    text: 'برای سرمایه‌گذاری فقط کافیه اراده کنی! چون حتی با 100،000 تومان هم میتونی روی هر پروژه سرمایه‌گذاری کنی و به همون میزان هم در سود پروژه سهیم خواهی شد .',
    icon: DollarCircleSvg,
  },
  {
    title: 'دست به دست هم',
    text: 'ما هم مانند شما در پروژه‌ها سرمایه‌گذاری میکنیم و سهیم هستیم. این یعنی مالکیت شما به عنوان سرمایه‌گذار عین مالکیت ما در پروژه است و ما حافظ منافع شما هستیم.',
    icon: LikeDislikeSvg,
  },
  {
    title: 'مدیریت کامل سرمایه‌گذاری',
    text: 'ما به عنوان متخصصین در زمینه‌ی تکنولوژی روز و همچنین ساخت‌وساز ، تمام فرآیند سرمایه‌گذاری را کنترل و مدیریت میکنیم تا شما با خیال راحت به فکر سرمایه‌گذاری و کسب درآمد باشید.',
    icon: GraphSvg,
  },
  {
    title: 'سرمایه‌گذاری کم‌ریسک',
    text: 'سرمایه‌گذاری بر روی بازار ساخت‌وساز همیشه دارای امنیت بالایی بوده. شما به هر میزانی که در پروژه سرمایه‌گذاری میکنید در مالکیت پروژه هم سهیم هستید. ما با بررسی دقیق و گرفتن تضامین اجرایی، ریسک سرمایه‌گذاری شما را به حداقل میرسانیم',
    icon: TrendUpSvg,
  },
  {
    title: 'امنیت سرمایه‌گذاری',
    text: 'تمام مبالغی که سرمایه‌گذاری میکنی از طریق بخش کیف پول قابل مدیریته. تمام پروژه‌ها دارای تضامین کامل از سوی سازنده و مالک هستند و سرمایه شما تحت حمایت کامل حقوقی و قضایی ما قرار داره.',
    icon: ShieldSvg,
  },
  {
    title: 'سود دهی بالا',
    text: 'تمام پروژه ها از بین 100 ها پروژه مختلف به صورت دقیق و کامل بررسی میشوند تا از نظر سود دهی بالاترین عملکرد ممکن را برای سرمایه گذار ها داشته باشند',
    icon: ChartSvg,
  },
  {
    title: 'سرعت اجرا',
    text: 'اغلب پروژه های ما در بازه ی زمانی 6 تا 9 ماه به پایان میرسند و سرمایه گذار ها میتوانند سود خود را در انتهای پروژه دریافت کنند.',
    icon: BuildingSvg,
  },
];

export const LandingSlider = () => {
  const sliderRef = useRef<SwiperRef>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Box sx={{ my: 10, position: 'relative', width: '100%' }}>
      <Swiper
        ref={sliderRef}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
        spaceBetween={1}
        rewind={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {sliderCards.map((sliderCard, index) => {
          return (
            <SwiperSlide
              key={index}
              style={{
                borderRadius: '15px',
                backgroundColor: '#034F4F',
              }}
            >
              <LandingSliderCard sliderCard={sliderCard} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 3,
        }}
      >
        <IconButton
          sx={{
            color: '#ffffff',
            backgroundColor: '#115957',
            border: '2px solid #daedc2',
            borderRadius: '50%',
            width: { xs: 40, md: 60 },
            height: { xs: 40, md: 60 },
          }}
          onClick={handlePrev}
        >
          <ArrowForwardIos sx={{ fontSize: { xs: 25, md: 35 } }} />
        </IconButton>
        <IconButton
          sx={{
            color: '#ffffff',
            backgroundColor: '#115957',
            border: '2px solid #daedc2',
            borderRadius: '50%',
            width: { xs: 40, md: 60 },
            height: { xs: 40, md: 60 },
          }}
          onClick={handleNext}
        >
          <ArrowBackIosNew sx={{ fontSize: { xs: 25, md: 35 } }} />
        </IconButton>
      </Stack>
    </Box>
  );
};
