import { FC, useMemo } from 'react';
import { Slider, SliderProps } from '@mui/material';
import { styled } from '@mui/system';

const MySlider = styled(Slider)<SliderProps>(({ theme }) => ({
  color: 'white',
  height: 15,
  cursor: 'default',
  padding: 0,
  width: '90%',
  margin: '0 auto',
  '& .MuiSlider-track': {
    border: 'none',
    height: 15,
    backgroundColor: '#8BC83F',
  },
  '& .MuiSlider-rail': {
    height: 15,
    backgroundColor: 'white',
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    height: 21,
    minWidth: 40,
    backgroundColor: '#204D6C',
    color: 'white',
    borderRadius: '17px',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    color: 'white',
    top: 0,
    padding: '0',
    margin: '0',
    height: 21,
    width: 40,
    fontSize: '12px',
    lineHeight: '21px',
    transform: 'scale(1)',
    display: 'block',
    textAlign: 'center',
    backgroundColor: 'transparent',
    '&:after': { content: '"%"', position: 'static' },
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'scale(1)',
    },
  },
}));

type CustomSliderPropsType = {
  totalValue?: number;
  currentValue?: number;
};

export const CustomSlider: FC<CustomSliderPropsType> = ({
  totalValue = 0,
  currentValue = 0,
}) => {
  const currentPercent = useMemo(() => {
    let result = 0;
    result = Math.floor((currentValue * 100) / totalValue);
    if (result >= 100) {
      result = totalValue;
    }
    if (result <= 0) {
      result = 0;
    }
    return result;
  }, [currentValue, totalValue]);

  return (
    <MySlider
      valueLabelDisplay="auto"
      aria-label="my slider"
      value={currentPercent}
    />
  );
};
