import { Box, Typography } from '@mui/material';
import { East } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export const BlogCard = () => {
  return (
    <Box
      sx={{
        borderRadius: '25px 25px 25px 0',
        width: 325,
        height: 180,
        position: 'relative',
      }}
    >
      <img
        src="/images/blogCard.png"
        alt="blogImage"
        style={{
          width: '100%',
          height: '100%',
          filter: 'brightness(70%) saturate(90%)',
          opacity: 0.7,
        }}
      />
      <Typography
        sx={{
          color: 'white',
          fontSize: '18px',
          fontWeight: 700,
          position: 'absolute',
          top: '20%',
          left: 20,
          width: '35%',
        }}
      >
        نام مقاله اینجا قرار دارد.
      </Typography>
      <Box
        sx={{
          backgroundColor: '#204D6C',
          color: 'white',
          borderTopRightRadius: '25px',
          width: 93,
          height: 56,
          position: 'absolute',
          bottom: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        component={Link}
        to="/blogContent"
      >
        <East />
      </Box>
    </Box>
  );
};
