import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

type TimerSvgPropsType = {
  svgProps?: SvgIconProps;
  isSelected?: boolean;
};

export const TimerSvg: FC<TimerSvgPropsType> = ({
  svgProps,
  isSelected = false,
}) => {
  return (
    <SvgIcon
      width="16"
      height="19"
      viewBox="0 0 16 19"
      {...svgProps}
      sx={{
        fill: 'none',
        '&>path': {
          stroke: isSelected ? '#252B5C' : '#F5F4F8',
        },
        ...svgProps?.sx,
      }}
    >
      <path
        d="M5.08716 1.16602H10.0872"
        // stroke="#F5F4F8"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.58716 7.83203V11.1654"
        // stroke="#F5F4F8"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5872 17.8333C9.35531 17.8333 11.051 17.131 12.3012 15.8807C13.5515 14.6305 14.2539 12.9348 14.2539 11.1667C14.2539 9.39856 13.5515 7.70286 12.3012 6.45262C11.051 5.20238 9.35531 4.5 7.5872 4.5C5.81909 4.5 4.1234 5.20238 2.87315 6.45262C1.62291 7.70286 0.920532 9.39856 0.920532 11.1667C0.920532 12.9348 1.62291 14.6305 2.87315 15.8807C4.1234 17.131 5.81909 17.8333 7.5872 17.8333V17.8333Z"
        // stroke="#F5F4F8"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
