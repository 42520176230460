import { Box, Stack, Typography } from '@mui/material';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LandingBlogCard } from './LandingBlogCard';

const blogCards = [
  {
    title: 'سرمایه گذاری برای تازه کار ها',
    text: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،',
  },
  {
    title: 'آموزش استفاده از بلوک',
    text: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،',
  },
  {
    title: 'چطور به ما اعتماد کنید',
    text: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،',
  },
  {
    title: 'روند بازار ساخت و ساز',
    text: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،',
  },
  {
    title: 'بلوک چگونه کار می کند؟',
    text: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،',
  },
];

export const LandingBlogSection = () => {
  return (
    <>
      <Stack py={5} spacing={4}>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <img src="/images/adamsOrbit.png" alt="melkBlog" />
        </Box>
        <Typography
          sx={{
            fontSize: { xs: '32px', md: '52px' },
            fontWeight: 600,
            color: 'white',
            textAlign: 'center',
          }}
        >
          به آینده سرمایه گذاری <br />
          املاک و مستغلات بپیوندید
        </Typography>

        <Swiper
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          spaceBetween={100}
          rewind={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Autoplay]}
          className="mySwiper"
          style={{
            marginTop: '50px',
            marginBottom: '50px',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          {blogCards.map((blogCard, index) => {
            return (
              <SwiperSlide
                key={index}
                style={{
                  borderRadius: '15px',
                  backgroundColor: '#034F4F',
                  textAlign: 'right',
                }}
              >
                <LandingBlogCard blogCards={blogCard} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Stack>
    </>
  );
};
