export const getBankName = (code: string) => {
  switch (code.toString().substring(6, -16)) {
    case '603799':
      return 'ملی';

    case '589210':
      return 'سپاه';

    case '627961':
      return 'صنعت‌معدن';

    case '603770':
      return 'کشاورزی';

    case '628023':
      return 'مسکن';

    case '627760':
      return 'پست‌بانک';

    case '502908':
      return 'توسعه';

    case '627412':
      return 'اقتصاد';

    case '622106':
      return 'پارسیان';

    case '502229':
      return 'پاسارگاد';

    case '627488':
      return 'کارآفرین';

    case '621986':
      return 'سامان';

    case '639346':
      return 'سینا';

    case '639607':
      return 'سرمایه';

    case '502806':
    case '504806':
      return 'شهر';

    case '502938':
      return 'دی';

    case '603769':
      return 'صادرات';

    case '610433':
      return 'ملت';

    case '627353':
    case '504706':
      return 'تجارت';

    case '589463':
      return 'رفاه';

    case '627381':
      return 'انصار';

    case '639370':
      return 'مهراقتصاد';

    case '639599':
      return 'قوامین';

    case '504172':
      return 'رسالت';

    default:
      return '';
  }
};

export const validateCardNumber = (code: string) => {
  const L = code.length;
  if (
    L < 16 ||
    parseInt(code.substr(1, 10), 10) === 0 ||
    parseInt(code.substr(10, 6), 10) === 0
  )
    return false;
  let s = 0;
  let k, d;
  for (let i = 0; i < 16; i++) {
    k = i % 2 === 0 ? 2 : 1;
    d = parseInt(code.substr(i, 1), 10) * k;
    s += d > 9 ? d - 9 : d;
  }
  return s % 10 === 0;
};
