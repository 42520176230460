import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const LikeDislikeSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M4.91978 20.28L6.68979 21.65C6.91979 21.88 7.42979 21.99 7.77979 21.99H9.94978C10.6398 21.99 11.3798 21.48 11.5498 20.79L12.9198 16.62C13.2098 15.82 12.6898 15.13 11.8298 15.13H9.53979C9.19979 15.13 8.90979 14.8399 8.96979 14.4399L9.25979 12.61C9.36979 12.1 9.02978 11.52 8.51978 11.35C8.05978 11.18 7.48979 11.41 7.25979 11.75L4.91978 15.24"
        stroke="white"
        strokeWidth="1"
        strokeMiterlimit="10"
        fill="none"
      />
      <path
        d="M1.99986 20.28V14.68C1.99986 13.88 2.33986 13.59 3.13986 13.59H3.70986C4.50986 13.59 4.84986 13.88 4.84986 14.68V20.28C4.84986 21.08 4.50986 21.37 3.70986 21.37H3.13986C2.33986 21.37 1.99986 21.09 1.99986 20.28Z"
        stroke="white"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M19.08 3.71999L17.31 2.34999C17.08 2.11999 16.57 2.01003 16.22 2.01003H14.05C13.36 2.01003 12.62 2.51998 12.45 3.20998L11.08 7.38002C10.79 8.18002 11.31 8.87001 12.17 8.87001H14.46C14.8 8.87001 15.09 9.16007 15.03 9.56007L14.74 11.39C14.63 11.9 14.97 12.48 15.48 12.65C15.94 12.82 16.51 12.59 16.74 12.25L19.08 8.76003"
        stroke="white"
        strokeWidth="1"
        strokeMiterlimit="10"
        fill="none"
      />
      <path
        d="M21.9996 3.71999V9.31996C21.9996 10.12 21.6596 10.4101 20.8596 10.4101H20.2896C19.4896 10.4101 19.1496 10.12 19.1496 9.31996V3.71999C19.1496 2.91999 19.4896 2.63002 20.2896 2.63002H20.8596C21.6596 2.63002 21.9996 2.90999 21.9996 3.71999Z"
        stroke="white"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
