import { Box, Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { ContentPropsType } from './constant';

export const SplashScreen: FC<ContentPropsType> = ({ changePage }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        color: 'white',
      }}
    >
      <Box
        sx={{
          backgroundImage: 'url("/images/onboard/splash.png")',
          width: '100%',
          height: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <Box
        sx={{
          background: 'rgba(33, 98, 138, 0.8)',
          opacity: 0.5,
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          height: '100%',
          width: '100%',
          backgroundImage:
            'radial-gradient(746.5% 129.57% at 50% 100%, rgba(33, 98, 138, 0) 5.19%, #1F4C6B 56.53%)',
          transform: 'scaleY(-1)',
          zIndex: 2,
        }}
      />
      <Stack
        sx={{
          position: 'absolute',
          bottom: 0,
          height: '70%',
          width: '100%',
          textAlign: 'center',
          zIndex: 5,
          justifyContent: 'space-between',
          py: 2,
        }}
      >
        <Stack>
          <Box
            sx={{
              width: { md: '200px', xs: '150px' },
              height: { md: '110px', xs: '80px' },
              backgroundColor: '#D9D9D9',
              mx: 'auto',
            }}
          />

          <Typography
            sx={{ fontSize: { md: '36px', xs: '30px' }, fontWeight: 900 }}
          >
            واهه
          </Typography>
          <Typography
            sx={{ fontSize: { md: '20px', xs: '18px' }, fontWeight: 600 }}
          >
            سراب نیست...
          </Typography>
        </Stack>
        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#8BC83F',
              borderRadius: '10px',
              fontSize: '18px',
              fontWeight: 600,
              p: { md: '15px 80px', xs: '10px 60px' },
            }}
            onClick={() => changePage(1)}
          >
            شروع
          </Button>
          <Typography sx={{ mt: 3, fontSize: { md: '16px', xs: '13px' } }}>
            ساخته شده با عشق
          </Typography>
          <Typography
            sx={{ fontWeight: 500, fontSize: { md: '16px', xs: '13px' } }}
          >
            v.1.0
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
