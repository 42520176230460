import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MelkDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: '50px',
    borderTopRightRadius: '50px',
    padding: 30,
  },
}));
