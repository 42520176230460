import { FC } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import moment from 'jalali-moment';
import { BORDER_RADIUS10, BORDER_RADIUS20 } from 'src/configs/theme';
import image from 'src/assets/images/Shape 2.jpg';

const notificationsArray = [
  {
    title: 'عنوان اعلان',
    text: 'متن اعلان هر چه باشد، کامل نمایش داده نمی‌شود. متن اعلان هر چه باشد، کامل نمایش داده نمی‌شود.',
    date: moment().subtract(1, 'days').format(),
  },
  {
    title: 'عنوان اعلان',
    text: 'متن اعلان هر چه باشد، کامل نمایش داده نمی‌شود. متن اعلان هر چه باشد، کامل نمایش داده نمی‌شود.',
    date: moment().subtract(1, 'days').format(),
  },
];

type NoticePropsType = {};

export const Notice: FC<NoticePropsType> = () => {
  const theme = useTheme();
  const isDesktop = theme.breakpoints.up('lg');

  return (
    <Stack rowGap={{ xs: 1.5, lg: 1 }}>
      {notificationsArray.map(({ title, text, date }, index) => {
        return (
          <Stack
            key={index}
            direction="row"
            columnGap={1.25}
            alignItems="center"
            bgcolor="customColor.color2"
            p={1.25}
            borderRadius={BORDER_RADIUS20}
          >
            <Box
              sx={{
                width: { xs: 113, lg: 165 },
                border: '3px solid white',
                borderRadius: BORDER_RADIUS10,
                bgcolor: '#eee',
              }}
            >
              <img
                style={{ width: '100%', verticalAlign: 'middle' }}
                src={image}
                alt=""
              />
            </Box>
            <Stack rowGap={2}>
              <Typography variant={isDesktop ? 't700_20' : 't700_16'}>
                {title}
              </Typography>
              <Typography
                variant={isDesktop ? 't400_14' : 't500_16'}
                color="text.secondary"
              >
                {text}
              </Typography>
              <Typography
                variant={isDesktop ? 't400_14' : 't400_12'}
                color="customColor.color6"
              >
                {moment(date).locale('fa').fromNow()}
              </Typography>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
