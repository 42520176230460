import { Button, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { RemoveCardSection } from '../organisms/creditCard/RemoveCardSection';

export type creditCardType = {
  bank: string;
  cardNumber: string;
  sheba: string;
  fullName: string;
};

export const CreditCard: FC<{
  creditCard: creditCardType;
  showRemoveButton?: boolean;
  disabled?: boolean;
}> = ({ creditCard, showRemoveButton = false, disabled = false }) => {
  const [openRemoveCardSection, setOpenRemoveCardSection] = useState(false);

  return (
    <>
      <Stack
        sx={{
          background:
            'linear-gradient(66.27deg, #4285F4 -10.45%, #34A853 23.22%)',
          width: '100%',
          borderRadius: '24px',
          ...(disabled && {
            opacity: 0.5,
          }),
        }}
        p={3}
        spacing={2}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack alignItems="center">
              <Typography
                sx={{
                  color: 'white',
                  lineHeight: '10px',
                  fontSize: '12px',
                }}
              >
                نام
              </Typography>
              <Typography
                sx={{
                  color: 'white',
                  lineHeight: '10px',
                  fontSize: '12px',
                }}
              >
                بانک
              </Typography>
            </Stack>
            <Typography sx={{ color: 'white', fontSize: '19px' }}>
              {creditCard.bank}
            </Typography>
          </Stack>
          <img src="/images/Chip.svg" alt="" />
        </Stack>
        <Stack spacing={1}>
          <Typography
            sx={{
              color: 'white',
              fontFamily: 'cardNumberFont',
              fontSize: '20px',
              textAlign: 'right',
            }}
          >
            {creditCard.cardNumber}
          </Typography>
          <Typography
            sx={{
              color: 'white',
              fontFamily: 'cardNumberFont',
              textAlign: 'right',
            }}
          >
            {creditCard.sheba}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack alignItems="center">
              <Typography
                sx={{
                  color: 'white',
                  lineHeight: '10px',
                  fontSize: '12px',
                }}
              >
                به
              </Typography>
              <Typography
                sx={{
                  color: 'white',
                  lineHeight: '10px',
                  fontSize: '12px',
                }}
              >
                نام
              </Typography>
            </Stack>
            <Typography sx={{ color: 'white', fontSize: '19px' }}>
              {creditCard.fullName}
            </Typography>
          </Stack>
          {showRemoveButton && (
            <Button
              sx={{
                backgroundColor: '#FF5C5C',
                '&:hover': {
                  backgroundColor: '#FF5C5C',
                },
                borderRadius: '50px',
                padding: '3px 30px',
              }}
              variant="contained"
              onClick={() => setOpenRemoveCardSection(true)}
            >
              حذف
            </Button>
          )}
        </Stack>
      </Stack>
      <RemoveCardSection
        creditCard={creditCard}
        open={openRemoveCardSection}
        handleClose={() => setOpenRemoveCardSection(false)}
      />
    </>
  );
};
