import { FC } from 'react';
import { UploadNationalCard } from 'src/components/organisms/validation/UploadNationalCard';
import { VideoValidation } from 'src/components/organisms/validation/VideoValidation';

const Validation: FC = () => {
  return (
    <>
      {/* <UploadNationalCard /> */}
      <VideoValidation />
    </>
  );
};

export default Validation;
