import { Box, Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import {
  CreditCard,
  creditCardType,
} from 'src/components/molecules/CreditCard';
export const RemoveCardContent: FC<{ creditCard: creditCardType }> = ({
  creditCard,
}) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#53587A',
          height: '5px',
          width: '100px',
          borderRadius: '20px',
          mx: 'auto',
        }}
      />
      <Stack p={4} justifyContent="space-between" height="100%">
        <Typography
          sx={{ color: '#252B5C', fontSize: { md: '35px', xs: '27px' } }}
        >
          حذف کارت بانکی
        </Typography>
        <Typography
          sx={{ color: '#53587A', fontSize: { md: '19px', xs: '15px' } }}
        >
          آیا مطمئن هستید می‌خواهید حساب بانکی زیر را حذف کنید؟
        </Typography>
        <CreditCard creditCard={creditCard} />
        <Button
          variant="outlined"
          sx={{
            color: '#DF3131',
            border: '3px solid #DF3131',
            py: 2,
            fontSize: '19px',
            fontWeight: 800,
            borderRadius: '10px',
            width: '100%',
          }}
        >
          حذف حساب
        </Button>
      </Stack>
    </>
  );
};
