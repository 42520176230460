import { FC } from 'react';
import { ProjectTour } from './ProjectTour';
import { SplashScreen } from './SplashScreen';

type onboardPageType = { title: string; component: FC<ContentPropsType> };

export const onboardPages: onboardPageType[] = [
  { title: 'splash', component: SplashScreen },
  { title: 'projectTour', component: ProjectTour },
];

export type ContentPropsType = {
  changePage: (page: number) => void;
  handleCloseDialog: () => void;
};
