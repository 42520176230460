import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GuideButtonDesktop = styled(Button)(() => ({
  backgroundColor: '#034F4D',
  color: 'white',
  width: '25%',
  fontSize: '28px',
  fontWeight: 700,
  py: 0.1,
}));
