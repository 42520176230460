import { Avatar, Badge, IconButton, Stack } from '@mui/material';
import { FC } from 'react';
import { Pencil } from '../atoms/icons/Pencil';

export const ProfileAvatar: FC<{ editMode: boolean }> = ({ editMode }) => {
  return (
    <Stack
      sx={{
        padding: 3,
        width: '100%',
      }}
      alignItems="center"
    >
      {editMode ? (
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          badgeContent={
            <IconButton
              sx={{
                backgroundColor: '#234F68',
                borderRadius: '50%',
                textAlign: 'center',
                width: '35px',
                height: '35px',
                '&:hover, &.Mui-focusVisible ': {
                  backgroundColor: '#234F68',
                },
              }}
            >
              <Pencil
                sx={{
                  fontSize: '16px',
                  stroke: 'white',
                }}
              />
            </IconButton>
          }
        >
          <Avatar src="/images/avatar.png" sx={{ width: 100, height: 100 }} />
        </Badge>
      ) : (
        <Avatar src="/images/avatar.png" sx={{ width: 100, height: 100 }} />
      )}
    </Stack>
  );
};
