import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';

export const ProfileStatus: FC<{
  submit: () => void;
  editMode: boolean;
  isLoading: boolean;
}> = ({ submit, editMode, isLoading }) => {
  return (
    <Stack spacing={3} p={3}>
      <Box
        sx={{ backgroundColor: '#F5F4F8', borderRadius: '30px', padding: 4 }}
      >
        <Typography sx={{ color: '#DF3131' }}>
          <ul>
            <li>برای اضافه کردن حساب بانکی نیاز است هویت خود را احراز کنید.</li>
            <li>
              به محض اینکه هویت شما احراز شد درخواست‌های مختلف شما بررسی شده و
              نتیجه در کیف پول شما قابل مشاهده خواهد بود.
            </li>
          </ul>
        </Typography>
        <Button variant="contained" fullWidth sx={{ fontSize: '18px', p: 2 }}>
          احراز هویت
        </Button>
      </Box>
      {editMode ? (
        <LoadingButton
          variant="outlined"
          fullWidth
          sx={{
            border: '3px solid #204D6C',
            '&:hover': {
              border: '3px solid #204D6C',
            },
            borderRadius: '10px',
            color: '#204D6C',
            fontSize: '19px',
            p: 2,
          }}
          type="submit"
          loading={isLoading}
          onClick={() => {
            submit();
          }}
        >
          ثبت تغییرات
        </LoadingButton>
      ) : (
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#204D6C',
            '&:focus': { backgroundColor: '#204D6C' },
            '&:hover': { backgroundColor: '#204D6C' },
            fontSize: '18px',
            p: 2,
          }}
          fullWidth
          onClick={() => submit()}
        >
          ویرایش اطلاعات
        </Button>
      )}
    </Stack>
  );
};
