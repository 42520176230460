import { Box, Button, InputAdornment, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CreditCardSvg } from 'src/components/atoms/icons/CreditCardSvg';
import { MelkTextField } from 'src/components/atoms/MelkTextField';
import { CreditCard } from 'src/components/molecules/CreditCard';
import { getBankName, validateCardNumber } from 'src/utils/bankCard.util';
import { MelkDrawer } from 'src/components/atoms/MelkDrawer';
import { CardCodeContent } from './CardCodeContent';
import { CardContentDrawer } from './CardContentDrawer';

export const AddCardContent = () => {
  const [selected, setSelected] = useState('cardNumber');
  const [cardNumber, setCardNumber] = useState('');

  const newCardInfo = {
    bank: getBankName(cardNumber),
    cardNumber: `${cardNumber}`,
    sheba: 'IR620610000000100842009999',
    fullName: 'پارسا یاهوئی',
  };

  console.log(validateCardNumber(cardNumber.toString()));

  const [cardCodeDrawerOpen, setCardCodeDrawerOpen] = useState(false);

  return (
    <Stack spacing={4} p={3}>
      <Stack spacing={1}>
        <Typography
          sx={{ color: '#1F4C6B', fontSize: '30px', fontWeight: 'bold' }}
        >
          کارت بانکی
        </Typography>
        <Typography sx={{ color: '#252B5C', fontSize: '28px' }}>
          خود را اضافه کنید.
        </Typography>
      </Stack>
      {cardNumber !== '' && <CreditCard creditCard={newCardInfo} />}
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            sx={{
              backgroundColor:
                selected === 'cardNumber' ? '#53587A' : '#F5F4F8',
              color: selected === 'cardNumber' ? 'white' : '#53587A',
              p: '20px 30px',
              borderRadius: '100px',
            }}
            onClick={() => setSelected('cardNumber')}
          >
            شماره کارت
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor:
                selected === 'accountNumber' ? '#53587A' : '#F5F4F8',
              color: selected === 'accountNumber' ? 'white' : '#53587A',
              p: '20px 30px',
              borderRadius: '100px',
            }}
            onClick={() => setSelected('accountNumber')}
          >
            شماره حساب
          </Button>
        </Stack>
        <MelkTextField
          placeholder="۱۲۵۲ ۲۶۷۲ ۵۷۵۵ ۲۵۶۷"
          value={cardNumber}
          onChange={(event) => {
            setCardNumber(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CreditCardSvg />
              </InputAdornment>
            ),
            sx: {
              '& input::placeholder': {
                textAlign: 'right',
              },
              py: 1,
              px: 2,
              direction: 'rtl',
            },
          }}
        />
      </Stack>
      {cardNumber !== '' && (
        <Box sx={{ pt: 5, px: 8, justifyContent: 'center' }}>
          <Button
            variant="contained"
            fullWidth
            sx={{ p: 3, fontSize: '20px' }}
            onClick={() => setCardCodeDrawerOpen(true)}
          >
            افزودن حساب
          </Button>
          {/* <CardContentDrawer /> */}
        </Box>
      )}
    </Stack>
  );
};
