import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export type NotificationSvgPropsType = {
  svgProps?: SvgIconProps;
  isSelected?: boolean;
};

export const NotificationSvg: FC<NotificationSvgPropsType> = ({
  svgProps,
  isSelected,
}) => {
  return (
    <SvgIcon
      width="37"
      height={isSelected ? '41' : '42'}
      viewBox={isSelected ? '0 0 37 41' : '0 0 37 42'}
      {...svgProps}
      sx={{
        fill: 'none',
        ...svgProps?.sx,
      }}
    >
      {isSelected ? (
        <>
          <path
            d="M31.0008 10.9087C33.6424 10.9087 35.7838 8.76724 35.7838 6.12566C35.7838 3.48408 33.6424 1.34265 31.0008 1.34265C28.3592 1.34265 26.2178 3.48408 26.2178 6.12566C26.2178 8.76724 28.3592 10.9087 31.0008 10.9087Z"
            fill="#FF0000"
          />
          <path
            d="M11.0551 36.4535C12.0698 38.4726 13.6243 39.9109 15.7425 40.6898C16.4497 40.9495 17.2115 41.0725 17.9461 41.257H19.5586C19.6201 41.2331 19.6782 41.1989 19.7431 41.1886C21.9467 40.9256 23.7711 39.9485 25.2333 38.2915C25.8004 37.6492 26.2651 36.9386 26.5896 36.1255H10.915C10.9731 36.2587 11.0107 36.3578 11.0551 36.4501V36.4535Z"
            fill="#204D6C"
          />
          <path
            d="M33.6245 11.6808C32.8285 12.0566 31.9402 12.2753 31.0007 12.2753C27.6048 12.2753 24.8511 9.52164 24.8511 6.12571C24.8511 4.61223 25.4012 3.22857 26.3065 2.15923C23.1737 0.31435 19.3985 -0.440682 15.4867 0.256271C9.12528 1.39053 4.85815 5.19644 2.90395 11.3563C1.92002 14.455 1.31531 17.6733 0.529528 20.8335C0.20155 22.1522 -0.0956801 23.4607 0.030728 24.8444C0.427035 29.1491 3.99721 32.5655 8.30875 32.6338C11.7047 32.6885 15.104 32.6441 18.5 32.6441C21.8857 32.6441 25.2679 32.6987 28.6536 32.6338C34.2019 32.5279 38.1889 27.1436 36.672 21.8003C35.712 18.4112 34.6358 15.0563 33.6211 11.6808H33.6245Z"
            fill="#204D6C"
          />
        </>
      ) : (
        <path
          d="M17.8529 40.7984C17.2379 40.6497 16.6025 40.5517 16.0078 40.3421C13.1658 39.3351 11.3275 37.3616 10.5198 34.452C10.4184 34.0803 10.2731 33.9823 9.90815 33.9924C8.56656 34.0296 7.22836 34.0161 5.92733 33.5937C1.76065 32.2453 -0.726521 28.0212 0.18927 23.7396C1.02734 19.823 1.89244 15.9098 2.80485 12.0101C4.21402 5.99152 9.41477 1.17601 15.4671 0.216291C23.7396 -1.09826 31.2349 3.70711 33.4957 11.787C34.5973 15.7205 35.7429 19.6439 36.7533 23.6011C37.953 28.3084 34.6548 33.1611 29.8528 33.8674C29.0418 33.9857 28.2138 33.9654 27.3927 33.9992C27.1967 34.006 27.0716 34.0093 26.9973 34.2628C25.9598 37.9564 23.5538 40.1292 19.7521 40.7409C19.7014 40.7477 19.6541 40.7781 19.6068 40.7984H17.8529ZM18.4916 30.5929C21.7729 30.5929 25.0542 30.5929 28.3321 30.5929C31.9345 30.5929 34.3169 27.44 33.3538 23.966C32.3265 20.2589 31.2518 16.5654 30.2786 12.8447C28.67 6.6809 22.8813 2.68318 16.5688 3.48746C11.5944 4.12277 7.36691 7.73862 6.14698 12.6927C5.27174 16.2477 4.54181 19.8365 3.73754 23.4085C3.52802 24.3445 3.29485 25.2738 3.45706 26.2403C3.89637 28.839 5.9645 30.5861 8.6105 30.5895C11.9053 30.5929 15.1968 30.5895 18.4916 30.5895V30.5929ZM14.0005 34.0195C14.3486 35.4726 16.2443 37.5306 19.0154 37.3819C21.0058 37.2771 23.2496 35.5976 23.4355 34.0195H14.0005Z"
          fill="#204D6C"
        />
      )}
    </SvgIcon>
  );
};
