import * as yup from 'yup';
import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useUserControllerGetCurrentUserProfileQuery,
  useUserControllerUpdateCurrentUserMutation,
} from 'src/app/services/generatedApi';
import { ProfileHeader } from 'src/components/molecules/ProfileHeader';
import { CreditCardList } from 'src/components/organisms/creditCard/CreditCardList';
import { ProfileContent } from 'src/components/organisms/profile/ProfileContent';
import { ProfileStatus } from 'src/components/organisms/profile/ProfileStatus';
import { DesktopTemplate } from 'src/components/templates/DesktopTemplate';
import { userInitialValues } from 'src/components/organisms/profile/constant';

export const Profile = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const [updateUser, { isLoading }] =
    useUserControllerUpdateCurrentUserMutation();

  const [editMode, setEditMode] = useState(false);

  const submit = () => {
    if (editMode === false) {
      setEditMode(true);
    } else {
      updateUser({ userUpdateDto: formik.values });
    }
  };

  const { data: user } = useUserControllerGetCurrentUserProfileQuery();

  let userSchema = yup.object({
    firstName: yup.string().required('لطفا نام خود را وارد کنید.'),
    lastName: yup.string().required('لطفا نام خانوادگی خود را وارد کنید.'),
    phoneNumber: yup
      .string()
      .required('وارد کردن شماره تلفن الزامی می‌باشد.')
      .matches(/^\d+$/, 'شماره تلفن باید عدد باشد.')
      .matches(/^09/, 'شماره تلفن باید با ۰۹ شروع شود.')
      .matches(/\d{11}$/, 'شماره تلفن باید ۱۱ رقم باشد.'),
    nationalCode: yup
      .string()
      .matches(/^\d+$/, 'کد ملی باید عدد باشد.')
      .length(10, 'کد ملی باید ۱۰ رقم باشد')
      .required('وارد کردن کد ملی الزامی می‌باشد.')
      .test('nationalCode', 'نامعتبر', (value) => {
        let sum = 0,
          lastDigit,
          remainder;

        for (let i = 0; i < 9; i++) sum += +value[i] * (10 - i);

        remainder = sum % 11;
        lastDigit = remainder < 2 ? remainder : 11 - remainder;

        return +value[9] === lastDigit;
      }),
    birthDate: yup.date().required('لطفا تاریخ تولد خود را وارد کنید.'),
    gender: yup
      .string()
      .oneOf(['MALE', 'FEMALE', 'UNKNOWN'])
      .required('لطفا جنسیت خود را مشخص کنید.'),
  });

  const formik = useFormik({
    initialValues: userInitialValues,
    validationSchema: userSchema,
    onSubmit: (values) => {
      updateUser({
        userUpdateDto: values,
      })
        .unwrap()
        .then(() => {
          console.log('Done');
        })
        .catch((err) => {
          toast.error(err.data);
        });
    },
  });

  useEffect(() => {
    if (!user) return;
    formik.setValues({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      nationalCode: user.nationalCode || '',
      birthDate: user.birthDate || '',
      gender: user.gender || 'MALE',
    });
  }, [user]);

  return (
    <>
      {isMobile ? (
        <Box>
          <ProfileHeader editMode={editMode} setEditMode={setEditMode} />
          <ProfileContent
            editMode={editMode}
            isLoading={isLoading}
            user={user}
            formik={formik}
          />
        </Box>
      ) : (
        <DesktopTemplate>
          <Stack direction="row" justifyContent="space-between">
            <Box sx={{ width: '30%' }}>
              <ProfileContent
                editMode={editMode}
                isLoading={isLoading}
                user={user}
                formik={formik}
              />
            </Box>
            <Box sx={{ width: '30%' }}>
              <CreditCardList />
            </Box>
            <Box sx={{ width: '30%', p: 3 }}>
              <ProfileStatus
                editMode={editMode}
                submit={submit}
                isLoading={isLoading}
              />
            </Box>
          </Stack>
        </DesktopTemplate>
      )}
    </>
  );
};
