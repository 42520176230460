import { IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { PlusSvg } from 'src/components/atoms/icons/PlusSvg';
import { CreditCard } from 'src/components/molecules/CreditCard';
import { ValidationState } from '../profile/ProfileContent';
import { Link } from 'react-router-dom';

const creditCards = [
  {
    bank: 'شهر',
    cardNumber: '5047 2672 5755 2567',
    sheba: 'IR620610000000100842008970',
    fullName: 'پارسا یاهوئی',
  },
  {
    bank: 'ملی',
    cardNumber: '5047 2672 5755 2567',
    sheba: 'IR620610000000100842008970',
    fullName: 'پارسا یاهوئی',
  },
  {
    bank: 'تجارت',
    cardNumber: '5047 2672 5755 2567',
    sheba: 'IR620610000000100842008970',
    fullName: 'پارسا یاهوئی',
  },
  {
    bank: 'صادرات',
    cardNumber: '5047 2672 5755 2567',
    sheba: 'IR620610000000100842008970',
    fullName: 'پارسا یاهوئی',
  },
];

export const CreditCardList = () => {
  const [validationState, setValidationState] = useState(
    ValidationState.NOT_VERIFIED
  );
  console.log(setValidationState);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <Typography
          sx={{ color: '#1F4C6B', fontSize: '30px', fontWeight: 'bold' }}
        >
          کار‌ت‌های بانکی
        </Typography>
        <IconButton
          sx={{
            backgroundColor: '#234F68',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
          }}
          component={Link}
          to="/addCreditCard"
        >
          <PlusSvg />
        </IconButton>
      </Stack>
      <Stack spacing={2} alignItems="center" p={3}>
        {creditCards.map((creditCard) => {
          return (
            <CreditCard
              creditCard={creditCard}
              showRemoveButton
              disabled={validationState !== ValidationState.VERIFIED}
            />
          );
        })}
      </Stack>
    </>
  );
};
