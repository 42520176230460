import type { FC } from 'react';
import { AppBar, Stack, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { SIDEBAR_MIN_WIDTH } from 'src/components/templates/DesktopTemplate';
import { ProfileMenu } from './ProfileMenu';
import { NotificationMenu } from './NotificationMenu';
import { Back } from 'src/components/atoms/icons/Back';
import { CustomIconButton } from 'src/components/atoms/CustomIconButton';
import { BreadCrumbs } from './BreadCrumbs';
import { useNavigate } from 'react-router-dom';
import { useProfile } from 'src/components/hooks/useProfile';

type HeaderPropsType = {
  openSandwichMenu?: boolean;
  showBackButton?: boolean;
};

export const Header: FC<HeaderPropsType> = ({
  openSandwichMenu = false,
  showBackButton = false,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  const { profile } = useProfile();

  return (
    <AppBar
      elevation={0}
      position="fixed"
      sx={{
        transition: '300ms',
        bgcolor: 'white',
        pl: isMd
          ? openSandwichMenu
            ? '269px'
            : SIDEBAR_MIN_WIDTH + 'px'
          : '0',
        borderBottom: ({ palette }) =>
          '1px solid ' + palette.customColor.color2,
      }}
    >
      <Toolbar>
        <Stack
          sx={{ width: '100%' }}
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          {isMd ? (
            <>
              <Stack direction="row" alignItems="center" columnGap={1}>
                {profile && (
                  <>
                    <NotificationMenu />
                    <ProfileMenu />
                  </>
                )}
              </Stack>
              {showBackButton && <BreadCrumbs />}
            </>
          ) : showBackButton ? (
            <>
              <CustomIconButton onClick={goBack}>
                <Back fontSize="small" />
              </CustomIconButton>
              <BreadCrumbs />
            </>
          ) : (
            <Stack direction="row" alignItems="center" columnGap={1}>
              {profile && (
                <>
                  <NotificationMenu />
                  <ProfileMenu />
                </>
              )}
            </Stack>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
