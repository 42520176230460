import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { useProfile } from 'src/components/hooks/useProfile';

export const PrivateRoute: FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  const token = useAppSelector((store) => store.auth.token);
  const { profile } = useProfile();
  if (profile && isAdmin && !profile.roleNames.includes('ADMIN')) {
    return <Navigate to="/" />;
  }
  return token ? <Outlet /> : <Navigate to="/" />;
};
