import { useAppSelector } from 'src/app/reduxCustomHooks';
import {
  useUserControllerGetCurrentUserProfileQuery,
  UserRo,
} from 'src/app/services/generatedApi';

type useProfileType = () => {
  profile: UserRo | null;
  isLoading: boolean;
};

export const useProfile: useProfileType = () => {
  const token = useAppSelector((store) => store.auth.token);

  const { data: profile, isLoading } =
    useUserControllerGetCurrentUserProfileQuery(undefined as any, {
      skip: !token,
    });

  return { profile: profile || null, isLoading };
};
