import { MenuItem } from '@mui/material';
import { memo } from 'react';

export const CycleSelectItem = memo<{
  diffFromSelected: number;
  label: string;
  onClick: () => void;
}>(({ diffFromSelected, label, onClick }) => {
  return (
    <MenuItem
      sx={{
        height: 50,
        color: diffFromSelected === 0 ? '#16191C' : '#AEAEAE',
        fontSize: diffFromSelected === 0 ? 23 : 18,
        transform: `perspective(100px) rotateX(${diffFromSelected * 20}deg)`,
        width: 'fit-content',
        borderRadius: '30px',
      }}
      onClick={onClick}
    >
      {label}
    </MenuItem>
  );
});
