import { FC } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { BlogCard } from 'src/components/molecules/BlogCard';

const Blog: FC = () => {
  return (
    <Stack spacing={3} py={3} width="75%">
      <Stack spacing={2}>
        <Typography
          sx={{ color: '#252B5C', fontWeight: 700, fontSize: 20, px: 4 }}
        >
          مقالات آموزشی
        </Typography>
        <Grid container spacing={3} width="100%">
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
        </Grid>
      </Stack>
      <Stack spacing={2}>
        <Typography
          sx={{ color: '#252B5C', fontWeight: 700, fontSize: 20, px: 4 }}
        >
          معرفی سازندگان
        </Typography>
        <Grid container spacing={3} width="100%">
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
          <Grid item xs={4}>
            <BlogCard />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default Blog;
