import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

type MelkTextFieldProps = {
  error?: boolean;
};

export const MelkTextField = styled(TextField)<MelkTextFieldProps>(
  ({ error }) => ({
    '&> .MuiInputBase-root': {
      ...(!error && {
        backgroundColor: '#F5F4F8',

        '& fieldset': { border: 'none' },
      }),

      fontSize: '18px',
      color: '#252B5C',
      '& input::placeholder': {
        color: '#A1A5C1',
      },
    },
    // '& .MuiInputBase-root.Mui-focused': {
    //   backgroundColor: '#53587A',
    //   color: 'white',
    //   '& .MuiInputAdornment-root .MuiSvgIcon-root': {
    //     stroke: 'white',
    //   },
    // },
    backgroundColor: '#F5F4F8',
    borderRadius: '10px',
    padding: '5px 8px',
    '& fieldset': {
      border: 'none!important',
    },
    'input:-webkit-autofill': {
      boxShadow: 'unset',
      textFillColor: '#252B5C',
    },
  })
);
