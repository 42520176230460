import { West } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { FC } from 'react';

export const LandingBlogCard: FC<{
  blogCards: { title: string; text: string };
}> = ({ blogCards }) => {
  return (
    <Stack
      sx={{ backgroundColor: 'white', borderRadius: '24px', height: 450 }}
      p={3}
      justifyContent="space-between"
    >
      <Stack spacing={3}>
        <Box sx={{ width: '100%' }}>
          <Typography
            sx={{
              backgroundColor: '#CAFFA8',
              color: '#034F4D',
              fontSize: '17px',
              borderRadius: '16px',
              px: 6,
              py: 1,
              width: 'fit-content',
              ml: 'auto',
            }}
          >
            بلوک
          </Typography>
        </Box>
        <Typography sx={{ fontSize: '31px', fontWeight: 800, color: 'black' }}>
          {blogCards.title}
        </Typography>
        <Typography sx={{ fontSize: '17px', fontWeight: 600, color: 'black' }}>
          {blogCards.text}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          sx={{ fontSize: '20px', fontWeight: 500, color: '#000000CC' }}
        >
          ادامه بیشتر و خواندن مطالب
        </Typography>
        <IconButton sx={{ backgroundColor: '#034F4D' }}>
          <West sx={{ color: 'white' }} />
        </IconButton>
      </Stack>
    </Stack>
  );
};
