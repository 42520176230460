import { api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authControllerSendOtp: build.mutation<
      AuthControllerSendOtpApiResponse,
      AuthControllerSendOtpApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/send-otp`,
        method: 'POST',
        body: queryArg.phoneNumberDto,
      }),
    }),
    authControllerLogin: build.mutation<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/login`,
        method: 'POST',
        body: queryArg.loginDto,
      }),
    }),
    userControllerCreateUserByAdmin: build.mutation<
      UserControllerCreateUserByAdminApiResponse,
      UserControllerCreateUserByAdminApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: 'POST',
        body: queryArg.userCreateByAdminDto,
      }),
    }),
    userControllerFindAll: build.query<
      UserControllerFindAllApiResponse,
      UserControllerFindAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        params: {
          firstName: queryArg.firstName,
          lastName: queryArg.lastName,
          nationalCode: queryArg.nationalCode,
          phoneNumber: queryArg.phoneNumber,
          address: queryArg.address,
          postalCode: queryArg.postalCode,
          referralPhoneNumber: queryArg.referralPhoneNumber,
          birthDate: queryArg.birthDate,
          isVerified: queryArg.isVerified,
        },
      }),
    }),
    userControllerGetById: build.query<
      UserControllerGetByIdApiResponse,
      UserControllerGetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/users/by-id/${queryArg.id}` }),
    }),
    userControllerUpdateUserByAdmin: build.mutation<
      UserControllerUpdateUserByAdminApiResponse,
      UserControllerUpdateUserByAdminApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/by-id/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.userUpdateByAdminDto,
      }),
    }),
    userControllerGetCurrentUserProfile: build.query<
      UserControllerGetCurrentUserProfileApiResponse,
      UserControllerGetCurrentUserProfileApiArg
    >({
      query: () => ({ url: `/api/v1/users/me/profile` }),
    }),
    userControllerUpdateCurrentUser: build.mutation<
      UserControllerUpdateCurrentUserApiResponse,
      UserControllerUpdateCurrentUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/me`,
        method: 'PATCH',
        body: queryArg.userUpdateDto,
      }),
    }),
    userControllerAssignRole: build.mutation<
      UserControllerAssignRoleApiResponse,
      UserControllerAssignRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/role`,
        method: 'POST',
        body: queryArg.userRoleDto,
      }),
    }),
    userControllerSuspend: build.mutation<
      UserControllerSuspendApiResponse,
      UserControllerSuspendApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/suspend`,
        method: 'PUT',
        body: queryArg.phoneNumberDto,
      }),
    }),
    userControllerDeleteMedia: build.mutation<
      UserControllerDeleteMediaApiResponse,
      UserControllerDeleteMediaApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/document/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    userControllerDeleteAvatar: build.mutation<
      UserControllerDeleteAvatarApiResponse,
      UserControllerDeleteAvatarApiArg
    >({
      query: () => ({ url: `/api/v1/users/delete-avatar`, method: 'DELETE' }),
    }),
    bankAccountControllerCreate: build.mutation<
      BankAccountControllerCreateApiResponse,
      BankAccountControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/BankAccount`,
        method: 'POST',
        body: queryArg.createBankAccountDto,
      }),
    }),
    bankAccountControllerDeleteRole: build.mutation<
      BankAccountControllerDeleteRoleApiResponse,
      BankAccountControllerDeleteRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/BankAccount`,
        method: 'DELETE',
        body: queryArg.idDto,
      }),
    }),
    bankAccountControllerGetUsage: build.query<
      BankAccountControllerGetUsageApiResponse,
      BankAccountControllerGetUsageApiArg
    >({
      query: () => ({ url: `/api/v1/BankAccount/all` }),
    }),
    bankAccountControllerUpdate: build.mutation<
      BankAccountControllerUpdateApiResponse,
      BankAccountControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/BankAccount/by-id/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateBankAccountDto,
      }),
    }),
    transactionControllerChargeWallet: build.mutation<
      TransactionControllerChargeWalletApiResponse,
      TransactionControllerChargeWalletApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/transactions/charge-wallet`,
        method: 'POST',
        body: queryArg.walletTransactionDto,
      }),
    }),
    transactionControllerCreatePortalTransaction: build.mutation<
      TransactionControllerCreatePortalTransactionApiResponse,
      TransactionControllerCreatePortalTransactionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/transactions/create-portal-transaction`,
        method: 'POST',
        body: queryArg.portalTransactionDto,
      }),
    }),
    transactionControllerGetMyTransactions: build.query<
      TransactionControllerGetMyTransactionsApiResponse,
      TransactionControllerGetMyTransactionsApiArg
    >({
      query: () => ({ url: `/api/v1/transactions/get-my-transactions` }),
    }),
    mediaControllerUploadDocumentForProject: build.mutation<
      MediaControllerUploadDocumentForProjectApiResponse,
      MediaControllerUploadDocumentForProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/project-media`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    mediaControllerUpdateDocumentForProject: build.mutation<
      MediaControllerUpdateDocumentForProjectApiResponse,
      MediaControllerUpdateDocumentForProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/project-media`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    mediaControllerUploadGalleryForProject: build.mutation<
      MediaControllerUploadGalleryForProjectApiResponse,
      MediaControllerUploadGalleryForProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/project-gallery`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    mediaControllerUpdateGalleryForProject: build.mutation<
      MediaControllerUpdateGalleryForProjectApiResponse,
      MediaControllerUpdateGalleryForProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/project-gallery`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    mediaControllerUploadDocumentForUser: build.mutation<
      MediaControllerUploadDocumentForUserApiResponse,
      MediaControllerUploadDocumentForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/user-document`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    mediaControllerUploadAvatarForUser: build.mutation<
      MediaControllerUploadAvatarForUserApiResponse,
      MediaControllerUploadAvatarForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/user-avatar`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    projectControllerCreateProject: build.mutation<
      ProjectControllerCreateProjectApiResponse,
      ProjectControllerCreateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects`,
        method: 'POST',
        body: queryArg.createProjectDto,
      }),
    }),
    projectControllerFindAll: build.query<
      ProjectControllerFindAllApiResponse,
      ProjectControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/v1/projects` }),
    }),
    projectControllerFindProjectById: build.query<
      ProjectControllerFindProjectByIdApiResponse,
      ProjectControllerFindProjectByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/projects/by-id/${queryArg.id}` }),
    }),
    projectControllerDeleteProject: build.mutation<
      ProjectControllerDeleteProjectApiResponse,
      ProjectControllerDeleteProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects/by-id/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    projectControllerUpdateProject: build.mutation<
      ProjectControllerUpdateProjectApiResponse,
      ProjectControllerUpdateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateProjectDto,
      }),
    }),
    projectControllerArchiveProject: build.mutation<
      ProjectControllerArchiveProjectApiResponse,
      ProjectControllerArchiveProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects/archive/${queryArg.id}`,
        method: 'PATCH',
      }),
    }),
    projectControllerProjectProgress: build.query<
      ProjectControllerProjectProgressApiResponse,
      ProjectControllerProjectProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects/progress/${queryArg.id}`,
      }),
    }),
    projectControllerDeleteMedia: build.mutation<
      ProjectControllerDeleteMediaApiResponse,
      ProjectControllerDeleteMediaApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects/media/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    projectControllerDeleteGallery: build.mutation<
      ProjectControllerDeleteGalleryApiResponse,
      ProjectControllerDeleteGalleryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects/gallery/${queryArg.id}`,
        method: 'DELETE',
        body: queryArg.filePathDto,
      }),
    }),
    assetControllerBuyAsset: build.mutation<
      AssetControllerBuyAssetApiResponse,
      AssetControllerBuyAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/buy`,
        method: 'POST',
        body: queryArg.exchangeAssetDto,
      }),
    }),
    assetControllerSellAsset: build.mutation<
      AssetControllerSellAssetApiResponse,
      AssetControllerSellAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/sell`,
        method: 'PUT',
        body: queryArg.exchangeAssetDto,
      }),
    }),
    assetControllerGetAssetById: build.query<
      AssetControllerGetAssetByIdApiResponse,
      AssetControllerGetAssetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/assets/by-id/${queryArg.id}` }),
    }),
    assetControllerGetAssetByProjectId: build.query<
      AssetControllerGetAssetByProjectIdApiResponse,
      AssetControllerGetAssetByProjectIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/by-project-id/${queryArg.id}`,
      }),
    }),
    assetControllerGetCurrentUserAssets: build.query<
      AssetControllerGetCurrentUserAssetsApiResponse,
      AssetControllerGetCurrentUserAssetsApiArg
    >({
      query: () => ({ url: `/api/v1/assets/me` }),
    }),
    assetControllerGetCurrentUserAssetsValuation: build.query<
      AssetControllerGetCurrentUserAssetsValuationApiResponse,
      AssetControllerGetCurrentUserAssetsValuationApiArg
    >({
      query: () => ({ url: `/api/v1/assets/valuation` }),
    }),
    rolesControllerReadPermissions: build.query<
      RolesControllerReadPermissionsApiResponse,
      RolesControllerReadPermissionsApiArg
    >({
      query: () => ({ url: `/api/v1/roles/permissions` }),
    }),
    rolesControllerCreateRole: build.mutation<
      RolesControllerCreateRoleApiResponse,
      RolesControllerCreateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: 'POST',
        body: queryArg.roleDto,
      }),
    }),
    rolesControllerUpdateRole: build.mutation<
      RolesControllerUpdateRoleApiResponse,
      RolesControllerUpdateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: 'PUT',
        body: queryArg.updateRoleDto,
      }),
    }),
    rolesControllerFindRole: build.query<
      RolesControllerFindRoleApiResponse,
      RolesControllerFindRoleApiArg
    >({
      query: () => ({ url: `/api/v1/roles` }),
    }),
    rolesControllerDeleteRole: build.mutation<
      RolesControllerDeleteRoleApiResponse,
      RolesControllerDeleteRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: 'DELETE',
        body: queryArg.idDto,
      }),
    }),
    rolesControllerReadRole: build.query<
      RolesControllerReadRoleApiResponse,
      RolesControllerReadRoleApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/roles/${queryArg.roleId}` }),
    }),
    orderControllerCreateProject: build.mutation<
      OrderControllerCreateProjectApiResponse,
      OrderControllerCreateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/orders`,
        method: 'POST',
        body: queryArg.orderDto,
      }),
    }),
    orderControllerGetMyOrders: build.query<
      OrderControllerGetMyOrdersApiResponse,
      OrderControllerGetMyOrdersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/orders/get-my-orders/${queryArg.status}`,
      }),
    }),
    orderControllerGetProjectOrders: build.query<
      OrderControllerGetProjectOrdersApiResponse,
      OrderControllerGetProjectOrdersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/orders/get-orders-of-project/${queryArg.id}`,
      }),
    }),
    orderControllerChangeOrderStatus: build.mutation<
      OrderControllerChangeOrderStatusApiResponse,
      OrderControllerChangeOrderStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/orders/change-order-status/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.orderStatusDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AuthControllerSendOtpApiResponse = unknown;
export type AuthControllerSendOtpApiArg = {
  phoneNumberDto: PhoneNumberDto;
};
export type AuthControllerLoginApiResponse = /** status 200  */ LoginRo;
export type AuthControllerLoginApiArg = {
  loginDto: LoginDto;
};
export type UserControllerCreateUserByAdminApiResponse =
  /** status 201  */ UserRo;
export type UserControllerCreateUserByAdminApiArg = {
  userCreateByAdminDto: UserCreateByAdminDto;
};
export type UserControllerFindAllApiResponse = /** status 200  */ UserRo[];
export type UserControllerFindAllApiArg = {
  firstName?: string;
  lastName?: string;
  nationalCode?: string;
  phoneNumber?: string;
  address?: string;
  postalCode: string;
  referralPhoneNumber: string;
  birthDate: string;
  isVerified: boolean;
};
export type UserControllerGetByIdApiResponse = /** status 200  */ UserRo;
export type UserControllerGetByIdApiArg = {
  id: string;
};
export type UserControllerUpdateUserByAdminApiResponse =
  /** status 200  */ UserRo;
export type UserControllerUpdateUserByAdminApiArg = {
  id: string;
  userUpdateByAdminDto: UserUpdateByAdminDto;
};
export type UserControllerGetCurrentUserProfileApiResponse =
  /** status 200  */ UserRo;
export type UserControllerGetCurrentUserProfileApiArg = void;
export type UserControllerUpdateCurrentUserApiResponse =
  /** status 200  */ UserRo;
export type UserControllerUpdateCurrentUserApiArg = {
  userUpdateDto: UserUpdateDto;
};
export type UserControllerAssignRoleApiResponse = unknown;
export type UserControllerAssignRoleApiArg = {
  userRoleDto: UserRoleDto;
};
export type UserControllerSuspendApiResponse = unknown;
export type UserControllerSuspendApiArg = {
  phoneNumberDto: PhoneNumberDto;
};
export type UserControllerDeleteMediaApiResponse = unknown;
export type UserControllerDeleteMediaApiArg = {
  id: string;
};
export type UserControllerDeleteAvatarApiResponse = unknown;
export type UserControllerDeleteAvatarApiArg = void;
export type BankAccountControllerCreateApiResponse = unknown;
export type BankAccountControllerCreateApiArg = {
  createBankAccountDto: CreateBankAccountDto;
};
export type BankAccountControllerDeleteRoleApiResponse = unknown;
export type BankAccountControllerDeleteRoleApiArg = {
  idDto: IdDto;
};
export type BankAccountControllerGetUsageApiResponse =
  /** status 200  */ BankAccountRo[];
export type BankAccountControllerGetUsageApiArg = void;
export type BankAccountControllerUpdateApiResponse = unknown;
export type BankAccountControllerUpdateApiArg = {
  id: string;
  updateBankAccountDto: UpdateBankAccountDto;
};
export type TransactionControllerChargeWalletApiResponse =
  /** status 201  */ string;
export type TransactionControllerChargeWalletApiArg = {
  walletTransactionDto: WalletTransactionDto;
};
export type TransactionControllerCreatePortalTransactionApiResponse =
  /** status 201  */ string;
export type TransactionControllerCreatePortalTransactionApiArg = {
  portalTransactionDto: PortalTransactionDto;
};
export type TransactionControllerGetMyTransactionsApiResponse =
  /** status 201  */ TransactionRo[];
export type TransactionControllerGetMyTransactionsApiArg = void;
export type MediaControllerUploadDocumentForProjectApiResponse =
  /** status 200  */ IdRo;
export type MediaControllerUploadDocumentForProjectApiArg = {
  body: {
    file: Blob;
    type: 'DOCUMENTATION' | 'GUARANTEE';
    projectId: string;
  };
};
export type MediaControllerUpdateDocumentForProjectApiResponse =
  /** status 200  */ IdRo;
export type MediaControllerUpdateDocumentForProjectApiArg = {
  body: {
    file: Blob;
    type: 'DOCUMENTATION' | 'GUARANTEE';
    mediaId: string;
    projectId: string;
  };
};
export type MediaControllerUploadGalleryForProjectApiResponse =
  /** status 200  */ IdRo;
export type MediaControllerUploadGalleryForProjectApiArg = {
  body: {
    file: Blob;
    projectId: string;
  };
};
export type MediaControllerUpdateGalleryForProjectApiResponse =
  /** status 200  */ IdRo;
export type MediaControllerUpdateGalleryForProjectApiArg = {
  body: {
    file: Blob;
    filePath: string;
    projectId: string;
  };
};
export type MediaControllerUploadDocumentForUserApiResponse =
  /** status 200  */ IdRo;
export type MediaControllerUploadDocumentForUserApiArg = {
  body: {
    file: Blob;
    documentType: 'NATIONAL_ID_CARD' | 'VIDEO';
  };
};
export type MediaControllerUploadAvatarForUserApiResponse =
  /** status 200  */ IdRo;
export type MediaControllerUploadAvatarForUserApiArg = {
  body: {
    file: Blob;
  };
};
export type ProjectControllerCreateProjectApiResponse =
  /** status 201  */ ProjectRo;
export type ProjectControllerCreateProjectApiArg = {
  createProjectDto: CreateProjectDto;
};
export type ProjectControllerFindAllApiResponse =
  /** status 201  */ ProjectRo[];
export type ProjectControllerFindAllApiArg = void;
export type ProjectControllerFindProjectByIdApiResponse =
  /** status 201  */ ProjectRo;
export type ProjectControllerFindProjectByIdApiArg = {
  id: string;
};
export type ProjectControllerDeleteProjectApiResponse = unknown;
export type ProjectControllerDeleteProjectApiArg = {
  id: string;
};
export type ProjectControllerUpdateProjectApiResponse = unknown;
export type ProjectControllerUpdateProjectApiArg = {
  id: string;
  updateProjectDto: UpdateProjectDto;
};
export type ProjectControllerArchiveProjectApiResponse = unknown;
export type ProjectControllerArchiveProjectApiArg = {
  id: string;
};
export type ProjectControllerProjectProgressApiResponse =
  /** status 201  */ number;
export type ProjectControllerProjectProgressApiArg = {
  id: string;
};
export type ProjectControllerDeleteMediaApiResponse = unknown;
export type ProjectControllerDeleteMediaApiArg = {
  id: string;
};
export type ProjectControllerDeleteGalleryApiResponse = unknown;
export type ProjectControllerDeleteGalleryApiArg = {
  id: string;
  filePathDto: FilePathDto;
};
export type AssetControllerBuyAssetApiResponse = /** status 201  */ AssetRo;
export type AssetControllerBuyAssetApiArg = {
  exchangeAssetDto: ExchangeAssetDto;
};
export type AssetControllerSellAssetApiResponse = /** status 201  */ AssetRo;
export type AssetControllerSellAssetApiArg = {
  exchangeAssetDto: ExchangeAssetDto;
};
export type AssetControllerGetAssetByIdApiResponse = /** status 201  */ AssetRo;
export type AssetControllerGetAssetByIdApiArg = {
  id: string;
};
export type AssetControllerGetAssetByProjectIdApiResponse =
  /** status 201  */ AssetRo;
export type AssetControllerGetAssetByProjectIdApiArg = {
  id: string;
};
export type AssetControllerGetCurrentUserAssetsApiResponse =
  /** status 201  */ AssetRo[];
export type AssetControllerGetCurrentUserAssetsApiArg = void;
export type AssetControllerGetCurrentUserAssetsValuationApiResponse =
  /** status 201  */ AssetValuationRo[];
export type AssetControllerGetCurrentUserAssetsValuationApiArg = void;
export type RolesControllerReadPermissionsApiResponse =
  /** status 200  */ PermissionViewRo[];
export type RolesControllerReadPermissionsApiArg = void;
export type RolesControllerCreateRoleApiResponse = /** status 201  */ RoleRo;
export type RolesControllerCreateRoleApiArg = {
  roleDto: RoleDto;
};
export type RolesControllerUpdateRoleApiResponse = unknown;
export type RolesControllerUpdateRoleApiArg = {
  updateRoleDto: UpdateRoleDto;
};
export type RolesControllerFindRoleApiResponse = /** status 200  */ RoleRo;
export type RolesControllerFindRoleApiArg = void;
export type RolesControllerDeleteRoleApiResponse = unknown;
export type RolesControllerDeleteRoleApiArg = {
  idDto: IdDto;
};
export type RolesControllerReadRoleApiResponse = /** status 200  */ RoleRo;
export type RolesControllerReadRoleApiArg = {
  roleId: string;
};
export type OrderControllerCreateProjectApiResponse =
  /** status 201  */ OrderRo;
export type OrderControllerCreateProjectApiArg = {
  orderDto: OrderDto;
};
export type OrderControllerGetMyOrdersApiResponse =
  /** status 201  */ OrderRo[];
export type OrderControllerGetMyOrdersApiArg = {
  status: 'ACCEPT' | 'REJECT' | 'PENDING';
};
export type OrderControllerGetProjectOrdersApiResponse =
  /** status 201  */ OrderRo[];
export type OrderControllerGetProjectOrdersApiArg = {
  id: string;
};
export type OrderControllerChangeOrderStatusApiResponse =
  /** status 201  */ OrderRo;
export type OrderControllerChangeOrderStatusApiArg = {
  id: string;
  orderStatusDto: OrderStatusDto;
};
export type PhoneNumberDto = {
  phoneNumber: string;
};
export type UserMediaRo = {
  id: string;
  documentType: string;
  filePath: string;
  state: string;
};
export type UserRo = {
  id: string;
  firstName: string;
  lastName: string;
  nationalCode: string;
  phoneNumber: string;
  email: string;
  address: string;
  postalCode: string;
  referralPhoneNumber: string;
  walletBalance: number;
  termsAndConditions: boolean;
  roleNames: string[];
  gender: 'MALE' | 'FEMALE' | 'UNKNOWN';
  avatar: string;
  birthDate: string;
  medias: UserMediaRo[];
  isVerified: boolean;
};
export type LoginRo = {
  token: string;
  user: UserRo;
};
export type LoginDto = {
  phoneNumber: string;
  OTPCode: string;
};
export type UserCreateByAdminDto = {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  nationalCode: string;
  email?: string;
  address: string;
  postalCode: string;
  referralPhoneNumber: string;
  termsAndConditions: boolean;
  gender: 'MALE' | 'FEMALE' | 'UNKNOWN';
  birthDate: string;
};
export type UserUpdateByAdminDto = {
  firstName: string;
  lastName: string;
  email?: string;
  address: string;
  postalCode: string;
  referralPhoneNumber: string;
  termsAndConditions: boolean;
  gender: 'MALE' | 'FEMALE' | 'UNKNOWN';
  birthDate: string;
  isVerified: boolean;
};
export type UserUpdateDto = {
  firstName: string;
  lastName: string;
  nationalCode: string;
  gender: 'MALE' | 'FEMALE' | 'UNKNOWN';
  birthDate: string;
};
export type UserRoleDto = {
  phoneNumber: string;
  roleIds: string[];
};
export type CreateBankAccountDto = {
  OTPCode: string;
  name: string;
  IBN: string;
  bankAccountID: string;
  cardNumber: string;
  bankName: string;
};
export type IdDto = {
  id: string;
};
export type BankAccountRo = {
  id: string;
  name: string;
  IBN: string;
  bankAccountID: string;
  cardNumber: string;
  bankName: string;
};
export type UpdateBankAccountDto = {
  OTPCode: string;
  name: string;
  IBN: string;
  bankAccountID: string;
  cardNumber: string;
  bankName: string;
};
export type WalletTransactionDto = {
  amount: number;
  transactionType: 'PAYMENT_PORTAL' | 'OFFLINE_PAYMENT' | 'INCREASE_WALLET';
  description: string;
};
export type PortalTransactionDto = {
  owner?: string;
  asset?: string;
  amount: number;
  walletAmount: number;
  transactionType: 'PAYMENT_PORTAL' | 'OFFLINE_PAYMENT' | 'INCREASE_WALLET';
};
export type TransactionRo = {
  id: string;
  owner: string;
  amount: number;
  transactionType: 'PAYMENT_PORTAL' | 'OFFLINE_PAYMENT' | 'INCREASE_WALLET';
  transId: string;
  increaseOrDecreaseInventory: boolean;
  token: string;
  isVerified: string;
  createdAt: string;
  description: string;
};
export type IdRo = {
  id: string;
};
export type ParticipatedConstructionData = {
  mode: string;
  features: string[];
  attributes: string[];
};
export type StatusDurationRo = {
  duration: number;
  status: 'PREPARING' | 'FUND_RAISE' | 'BUILDING_PROGRESS' | 'SALE' | 'DONE';
};
export type LocationRo = {
  lat: string;
  long: string;
  address: string;
};
export type ProjectMediaRo = {
  id: string;
  name: string;
  filePath: string;
  type: string;
};
export type ProjectRo = {
  id: string;
  title: string;
  description: string;
  priceOfShare: number;
  totalShare: number;
  totalSize: number;
  minimumFundRaise: number;
  earnedProfit: number;
  estimatedProfit: number;
  supervisingEngineer: string;
  detail: string;
  attributesFeatures: ParticipatedConstructionData;
  statusDuration: StatusDurationRo;
  location: LocationRo;
  medias: ProjectMediaRo[];
  gallery: string[];
  state: string;
};
export type StatusDurationDto = {
  duration: number;
  status: 'PREPARING' | 'FUND_RAISE' | 'BUILDING_PROGRESS' | 'SALE' | 'DONE';
};
export type LocationDto = {
  lat: string;
  long: string;
  address: string;
};
export type CreateProjectDto = {
  title: string;
  description: string;
  priceOfShare: number;
  totalShare: number;
  totalSize: number;
  minimumFundRaise: number;
  earnedProfit: number;
  estimatedProfit: number;
  supervisingEngineer: string;
  detail: string;
  attributesFeatures: ParticipatedConstructionData;
  statusDuration: StatusDurationDto;
  location: LocationDto;
};
export type UpdateProjectDto = {
  title: string;
  description: string;
  priceOfShare: number;
  totalShare: number;
  totalSize: number;
  minimumFundRaise: number;
  earnedProfit: number;
  estimatedProfit: number;
  supervisingEngineer: string;
  detail: string;
  attributesFeatures: ParticipatedConstructionData;
  statusDuration: StatusDurationDto;
  location: LocationDto;
  state: 'PREPARING' | 'FUND_RAISE' | 'BUILDING_PROGRESS' | 'SALE' | 'DONE';
};
export type FilePathDto = {
  filePath: string;
};
export type AssetRo = {
  id: string;
  buyer: string;
  project: string;
  shareAmount: number;
  isConfirmedContract: boolean;
  createAt: string;
};
export type ExchangeAssetDto = {
  project: string;
  shareAmount: number;
};
export type AssetValuationRo = {
  project: string;
  totalValue: number;
  totalShare: number;
};
export type PermissionViewRo = {
  id: string;
  category: string;
  group: string;
  name: string;
};
export type RoleRo = {
  id: string;
  name: string;
  permissions: 'ADMIN';
};
export type RoleDto = {
  name: string;
  permissions: 'ADMIN'[];
};
export type UpdateRoleDto = {
  id: string;
  name: string;
  permissions: 'ADMIN'[];
};
export type OrderRo = {
  id: string;
  shareAmount: number;
  priceOfShare: number;
  owner: string;
  project: string;
  orderType: 'BUY' | 'SELL';
  status: 'ACCEPT' | 'REJECT' | 'PENDING';
};
export type OrderDto = {
  shareAmount: number;
  project: string;
  orderType: 'BUY' | 'SELL';
};
export type OrderStatusDto = {
  status: 'ACCEPT' | 'REJECT' | 'PENDING';
};
export const {
  useAuthControllerSendOtpMutation,
  useAuthControllerLoginMutation,
  useUserControllerCreateUserByAdminMutation,
  useUserControllerFindAllQuery,
  useUserControllerGetByIdQuery,
  useUserControllerUpdateUserByAdminMutation,
  useUserControllerGetCurrentUserProfileQuery,
  useUserControllerUpdateCurrentUserMutation,
  useUserControllerAssignRoleMutation,
  useUserControllerSuspendMutation,
  useUserControllerDeleteMediaMutation,
  useUserControllerDeleteAvatarMutation,
  useBankAccountControllerCreateMutation,
  useBankAccountControllerDeleteRoleMutation,
  useBankAccountControllerGetUsageQuery,
  useBankAccountControllerUpdateMutation,
  useTransactionControllerChargeWalletMutation,
  useTransactionControllerCreatePortalTransactionMutation,
  useTransactionControllerGetMyTransactionsQuery,
  useMediaControllerUploadDocumentForProjectMutation,
  useMediaControllerUpdateDocumentForProjectMutation,
  useMediaControllerUploadGalleryForProjectMutation,
  useMediaControllerUpdateGalleryForProjectMutation,
  useMediaControllerUploadDocumentForUserMutation,
  useMediaControllerUploadAvatarForUserMutation,
  useProjectControllerCreateProjectMutation,
  useProjectControllerFindAllQuery,
  useProjectControllerFindProjectByIdQuery,
  useProjectControllerDeleteProjectMutation,
  useProjectControllerUpdateProjectMutation,
  useProjectControllerArchiveProjectMutation,
  useProjectControllerProjectProgressQuery,
  useProjectControllerDeleteMediaMutation,
  useProjectControllerDeleteGalleryMutation,
  useAssetControllerBuyAssetMutation,
  useAssetControllerSellAssetMutation,
  useAssetControllerGetAssetByIdQuery,
  useAssetControllerGetAssetByProjectIdQuery,
  useAssetControllerGetCurrentUserAssetsQuery,
  useAssetControllerGetCurrentUserAssetsValuationQuery,
  useRolesControllerReadPermissionsQuery,
  useRolesControllerCreateRoleMutation,
  useRolesControllerUpdateRoleMutation,
  useRolesControllerFindRoleQuery,
  useRolesControllerDeleteRoleMutation,
  useRolesControllerReadRoleQuery,
  useOrderControllerCreateProjectMutation,
  useOrderControllerGetMyOrdersQuery,
  useOrderControllerGetProjectOrdersQuery,
  useOrderControllerChangeOrderStatusMutation,
} = injectedRtkApi;
