/* eslint-disable jsx-a11y/iframe-has-title */
import { FC } from 'react';
import { LocationRo } from 'src/app/services/generatedApi';

type MapPropsType = {
  location?: LocationRo;
};

export const Map: FC<MapPropsType> = ({ location }) => {
  if (!location) return <></>;
  return (
    <iframe
      width="100%"
      height="100%"
      style={{ border: 0 }}
      loading="lazy"
      src={`https://map.amaj.dev/?marker=1&lat=${location.lat}&lng=${location.long}&z=12`}
    />
  );
};
