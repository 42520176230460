import { FC } from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { DocSvg } from 'src/components/atoms/icons/DocSvg';
import { BORDER_RADIUS15, BORDER_RADIUS25 } from 'src/configs/theme';
import { useProject } from 'src/components/hooks/useProject';
// import { useAsset } from 'src/components/hooks/useAsset';
// import { useParams } from 'react-router-dom';
import { price2Persian } from 'src/utils/price2Persian.util';

const statisticsInfo = [
  {
    title: 'تعداد زمین‌ها',
    value: 10,
  },
  {
    title: 'متراژ هر زمین (متر)',
    value: 200,
  },
  {
    title: 'حداقل قیمت متری هر زمین (تومان)',
    value: price2Persian(5000000),
  },
  {
    title: 'سود پیش بینی شده (درصد)',
    value: 120,
  },
];

type YourAssetPropsType = {};

export const YourAsset: FC<YourAssetPropsType> = () => {
  // const { projectId } = useParams();
  // const { assets } = useAsset();

  // const hasAsset = useMemo(
  //   () =>
  //     assets ? assets.find(({ project }) => project === projectId) : false,
  //   [assets, projectId]
  // );

  const { project } = useProject();

  return (
    <Stack rowGap={1.9}>
      <Stack>
        <Typography variant="t700_20">اطلاعات آماری</Typography>
        <Stack
          sx={{
            border: ({ palette }) => '1px solid' + palette.customColor.color4,
            borderRadius: '25px',
          }}
          p={2}
          rowGap={1.1}
        >
          {statisticsInfo.map(({ title, value }, index) => {
            return (
              <Stack
                key={index}
                direction="row"
                sx={{ width: '100%' }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="t400_14">{title}</Typography>
                <Typography variant="t400_14">{value}</Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>

      <Box sx={{ minHeight: 95 }}>
        <Typography variant="t700_20">اسناد شما</Typography>
        {project?.medias.map(({ filePath, name }, index) => {
          return (
            <Chip
              component="a"
              href={filePath}
              key={index}
              label={name}
              sx={{
                bgcolor: 'customColor.color5',
                borderRadius: BORDER_RADIUS25,
                height: 60,
                px: 1.25,
                my: 0.5,
                mr: 1,
                cursor: 'pointer',
              }}
              icon={
                <Box
                  sx={{
                    borderRadius: BORDER_RADIUS15,
                    bgcolor: 'success.main',
                    width: 40,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <DocSvg fontSize="small" />
                </Box>
              }
            />
          );
        })}
      </Box>
    </Stack>
  );
};
