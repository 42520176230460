import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const Back: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M15 18L9 12L15 6"
        stroke="#252B5C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </SvgIcon>
  );
};
