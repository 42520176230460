import { Dialog, Theme, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import { MelkDrawer } from 'src/components/atoms/MelkDrawer';
import { creditCardType } from 'src/components/molecules/CreditCard';
import { RemoveCardContent } from './RemoveCardContent';

export const RemoveCardSection: FC<{
  creditCard: creditCardType;
  open: boolean;
  handleClose: () => void;
}> = ({ creditCard, open, handleClose }) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  if (!open) return <></>;
  return isMobile ? (
    <MelkDrawer anchor={'bottom'} open={open} onClose={handleClose}>
      <RemoveCardContent creditCard={creditCard} />
    </MelkDrawer>
  ) : (
    <Dialog open={open} onClose={handleClose}>
      <RemoveCardContent creditCard={creditCard} />
    </Dialog>
  );
};
