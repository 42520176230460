import { FC } from 'react';
import { NotificationSvg } from 'src/components/atoms/icons/NotificationSvg';
import { CustomIconButton } from 'src/components/atoms/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import { IconButton, Stack, Typography } from '@mui/material';
import { BORDER_RADIUS25 } from 'src/configs/theme';

type NotificationMenuPropsType = {};

export const NotificationMenu: FC<NotificationMenuPropsType> = () => {
  const navigate = useNavigate();

  const goToNotificationPage = () => navigate('/dashboard/notification');

  return (
    <Stack
      direction="row"
      sx={{
        bgcolor: 'rgba(109, 134, 162, 0.2)',
        borderRadius: BORDER_RADIUS25,
        py: 0.6,
        px: 1.8,
      }}
      alignItems="center"
      justifyContent="center"
      columnGap={1.1}
    >
      <IconButton sx={{ width: 25, height: 25 }} onClick={goToNotificationPage}>
        <NotificationSvg svgProps={{ fontSize: 'small' }} />
      </IconButton>
      <CustomIconButton sx={{ width: 25, height: 25 }}>
        <Typography
          component="span"
          fontSize={16}
          fontWeight={900}
          lineHeight="40px"
          sx={{ mb: -0.5 }}
        >
          ؟
        </Typography>
      </CustomIconButton>
    </Stack>
  );
};
