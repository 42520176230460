import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import { Box, Container, IconButton, Stack, Typography } from '@mui/material';

export const DesktopFooter = () => {
  return (
    <Box
      sx={{
        borderTop: '2px solid #1D605E',
        px: 5,
        pt: 10,
        pb: 5,
      }}
    >
      <Container maxWidth="xl" sx={{ p: '0 !important' }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          spacing={2}
        >
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={10}>
            <Box>
              <img
                src="/images/enamad.png"
                alt="enamad"
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
            <Stack direction="row" spacing={10}>
              <Stack spacing={2} sx={{ width: '120px' }}>
                <Typography
                  sx={{
                    color: '#FFF3CA',
                    borderBottom: '1px solid #EDEDED',
                    pb: 1,
                    fontSize: '20px',
                  }}
                >
                  میانبر
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '16px' }}>
                  صفحه اصلی
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '16px' }}>
                  درباره ما
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '16px' }}>
                  تماس با ما
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '16px' }}>
                  قوانین و مقررات
                </Typography>
              </Stack>
              <Stack spacing={2} sx={{ width: '120px' }}>
                <Typography
                  sx={{
                    color: '#FFF3CA',
                    borderBottom: '1px solid #EDEDED',
                    pb: 1,
                    fontSize: '20px',
                  }}
                >
                  تماس با ما
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '16px' }}>
                  فلان جا
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '16px' }}>
                  فلان شماره
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '16px' }}>
                  تماس با ما
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '16px' }}>
                  قوانین و مقررات
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={5}>
            <Box>
              <img
                src="/images/enamad.png"
                alt="enamad"
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
            <Box>
              <img
                src="/images/enamad.png"
                alt="enamad"
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          </Stack>
        </Stack>
        <Box
          sx={{
            width: '100%',
            mt: 5,
            justifyContent: 'left',
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', md: '90%' },
            }}
          >
            <Box
              sx={{
                borderBottom: '1px solid white',
                pb: 1,
                width: 'fit-content',
              }}
            >
              <Stack
                sx={{
                  borderRadius: '8px',
                  border: '1px solid white',
                  px: 2,
                  width: 'fit-content',
                }}
                direction="row"
                spacing={2}
              >
                <IconButton>
                  <Instagram sx={{ color: 'white' }} />
                </IconButton>
                <IconButton>
                  <Facebook sx={{ color: 'white' }} />
                </IconButton>
                <IconButton>
                  <Twitter sx={{ color: 'white' }} />
                </IconButton>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
