import { ProjectRo } from 'src/app/services/generatedApi';

export const projectMode2persian = (projectState?: ProjectRo['state']) => {
  if (!projectState) return '';

  switch (projectState) {
    case 'PARTICIPATED_CONSTRUCTION':
      return 'مشارکت در ساخت';

    default:
      return '';
  }
};

