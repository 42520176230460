import jMoment from 'jalali-moment';

import { Box, Stack } from '@mui/material';
import { CycleSelect } from './CycleSelect';
import { FC } from 'react';

export const DateSelector: FC<{
  initValue?: Date;
  onChange: (x: Date) => void;
}> = ({ initValue = new Date('2000-01-01'), onChange }) => {
  const days = [...Array(30)].map((_, index) => (index + 1).toString());
  const months = [...Array(12)].map((_, index) => (index + 1).toString());
  const years = [...Array(100)].map((_, index) => (index + 1300).toString());

  const year = +jMoment(initValue).locale('fa').format('YYYY');
  const month = +jMoment(initValue).locale('fa').format('MM');
  const day = +jMoment(initValue).locale('fa').format('DD');

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack direction="row" justifyContent="space-around">
        <CycleSelect
          initIndex={day}
          items={days}
          onChange={(index) => console.log(index)}
        />
        <CycleSelect
          initIndex={month}
          items={months}
          onChange={(index) => console.log(index)}
        />
        <CycleSelect
          initIndex={year - 1300}
          items={years}
          onChange={(index) => console.log(index)}
        />
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          width: '100%',
          height: 50,
          borderTop: '1px solid #CCCCCC',
          borderBottom: '1px solid #CCCCCC',
          transform: 'translateY(-50%)',
        }}
      ></Box>
    </Box>
  );
};
