import { FC, useState } from 'react';
import { Container, Stack, Toolbar } from '@mui/material';
import { Header } from '../organisms/layout/header/Header';
import { Sidebar } from '../organisms/layout/sidebar/Sidebar';

export const SIDEBAR_MIN_WIDTH = 82;

type DesktopTemplatePropsType = {
  children: any;
  showBackButton?: boolean;
};

export const DesktopTemplate: FC<DesktopTemplatePropsType> = ({
  children,
  showBackButton,
}) => {
  const [openSandwichMenu, setOpenSandwichMenu] = useState(false);

  return (
    <Stack
      direction="row"
      sx={{
        minHeight: '100vh',
        width: '100%',
        maxWidth: '100%',
        overflowX: 'hidden',
      }}
    >
      <Sidebar
        openSandwichMenu={openSandwichMenu}
        setOpenSandwichMenu={setOpenSandwichMenu}
      />
      <Stack
        sx={{
          overflowX: 'hidden',
          width: '100%',
          transition: '300ms',
        }}
      >
        <Header
          openSandwichMenu={openSandwichMenu}
          showBackButton={showBackButton}
        />
        <Toolbar />
        <Container maxWidth="xl" sx={{ p: 0, transition: '300ms' }}>
          {children}
        </Container>
      </Stack>
    </Stack>
  );
};
