import { FC, useMemo } from 'react';
import { Breadcrumbs, Skeleton } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Link, useParams } from 'react-router-dom';
import { useProject } from 'src/components/hooks/useProject';

type itemsType = {
  path: string;
  text: string;
  isLoading?: boolean;
};

type BreadCrumbsPropsType = {};

export const BreadCrumbs: FC<BreadCrumbsPropsType> = () => {
  const { transactionId, blogId, projectId } = useParams();

  const { project, isLoading: getProjectLoading } = useProject();

  const array = useMemo(() => {
    let result: itemsType[] = [];

    if (transactionId) {
      result = [
        { path: '/dashboard/transactions', text: 'فروش' },
        {
          path: `/dashboard/transactions/${transactionId}`,
          text: 'فروش شماره ۱',
        },
      ];
    }
    if (blogId) {
      result = [
        { path: '/dashboard/blog', text: 'آکادمی' },
        { path: `/dashboard/blog/${blogId}`, text: 'پست شماره ۱' },
      ];
    }
    if (projectId) {
      result = [
        { path: '/dashboard/projects', text: 'فرصت‌ها' },
        {
          path: `/dashboard/projects/${projectId}`,
          text: project?.title || '',
          isLoading: getProjectLoading,
        },
      ];
    }

    return result;
  }, [blogId, getProjectLoading, project?.title, projectId, transactionId]);

  return (
    <Breadcrumbs
      separator={<NavigateBeforeIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{
        color: 'text.primary',
        bgcolor: 'customColor.color1',
        borderRadius: '50px',
        py: 1,
        px: 2,
        fontWeight: 400,
        fontSize: 14,
        '&:hover': {
          bgcolor: 'customColor.color1',
        },
      }}
    >
      {array.map(({ text, path, isLoading }, index) => {
        if (isLoading) {
          return <Skeleton width={70} key={index} />;
        } else {
          return (
            <Link
              style={{
                textDecoration: 'none',
                color: index === 0 ? '#53587A' : '#252B5C',
                fontWeight: index === 0 ? 'normal' : 'bold',
              }}
              key={index}
              to={path}
            >
              {text}
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
};
