import { FC, useState } from 'react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { TimerSvg } from 'src/components/atoms/icons/TimerSvg';
import { useProfile } from 'src/components/hooks/useProfile';
import { BORDER_RADIUS20, BORDER_RADIUS50 } from 'src/configs/theme';
import { price2Persian } from 'src/utils/price2Persian.util';
import { useTheme } from '@mui/system';
import { CreateOrder } from 'src/components/organisms/order/CreateOrder';
import { useProject } from 'src/components/hooks/useProject';
import { useAssetControllerGetAssetByProjectIdQuery } from 'src/app/services/generatedApi';

type FundraisingActionPropsType = {};

export const FundraisingAction: FC<FundraisingActionPropsType> = () => {
  const [showOrderDialog, setShowOrderDialog] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { projectId } = useParams();
  const { project } = useProject();
  const { profile } = useProfile();

  const { data: hasAsset } = useAssetControllerGetAssetByProjectIdQuery(
    { id: projectId! },
    { skip: !projectId }
  );

  const createOrder = () => {
    if (!profile) {
      navigate('/login');
      return;
    }
    setShowOrderDialog(true);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        columnGap={0.8}
        bgcolor="secondary.main"
        width="fit-content"
        height={35}
        p={1.5}
        ml="auto"
        borderRadius={BORDER_RADIUS50}
      >
        <TimerSvg />
        <Typography color="white">تنها ۲۰ روز باقی مانده</Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{
          bgcolor: 'customColor.color2',
          borderRadius: BORDER_RADIUS20,
          p: 1.25,
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="t700_14">
            {hasAsset ? 'سرمایه گذاری شما' : 'حداقل سرمایه گذاری'}
          </Typography>
          <Typography variant="t400_14">
            {price2Persian(
              (hasAsset
                ? hasAsset.shareAmount * (project?.priceOfShare || 0)
                : project?.priceOfShare) || 0
            )}{' '}
            تومان
          </Typography>
        </Stack>
        <Button variant="contained" onClick={createOrder}>
          سرمایه گذاری
        </Button>
      </Stack>
      <Dialog
        open={showOrderDialog && isSm}
        onClose={() => setShowOrderDialog(false)}
        maxWidth="xs"
        PaperProps={{
          sx: {
            position: 'relative',
            p: 2,
            width: '100%',
            borderRadius: BORDER_RADIUS50,
          },
        }}
      >
        <IconButton
          onClick={() => setShowOrderDialog(false)}
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            bgcolor: 'customColor.color1',
            backdropFilter: 'blur(10px)',
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          {projectId && <CreateOrder orderType="BUY" projectId={projectId} />}
        </DialogContent>
      </Dialog>
      <SwipeableDrawer
        anchor="bottom"
        open={showOrderDialog && !isSm}
        onClose={() => setShowOrderDialog(false)}
        onOpen={() => setShowOrderDialog(true)}
        disableSwipeToOpen={true}
        PaperProps={{
          sx: {
            borderTopRightRadius: BORDER_RADIUS50,
            borderTopLeftRadius: BORDER_RADIUS50,
            p: 3.2,
          },
        }}
      >
        <Box
          sx={{
            width: 60,
            height: 3,
            backgroundColor: 'text.secondary',
            borderRadius: 3,
            mb: 3.1,
            mx: 'auto',
          }}
        />
        {projectId && <CreateOrder orderType="BUY" projectId={projectId} />}
      </SwipeableDrawer>
    </>
  );
};
