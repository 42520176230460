import { FC } from 'react';
import { useProject } from 'src/components/hooks/useProject';
import { FundraisingAction } from './actions/FundraisingAction';

type ActionsPropsType = {};

export const Actions: FC<ActionsPropsType> = () => {
  const { project } = useProject();

  if (!project) return <></>;
  switch (project?.state) {
    case 'FUND_RAISE':
      return <FundraisingAction />;

    default:
      return <></>;
  }
};
