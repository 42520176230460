import { FC } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, Stack, Typography } from '@mui/material';
import { WalletSvg } from 'src/components/atoms/icons/WalletSvg';
import { GemSvg } from 'src/components/atoms/icons/GemSvg';
import { CoinSvg } from 'src/components/atoms/icons/CoinSvg';
import { useLocation, useNavigate } from 'react-router-dom';
import { BasketSvg } from 'src/components/atoms/icons/BasketSvg';
import { HatSvg } from 'src/components/atoms/icons/HatSvg';

export const NAVBAR_HEIGHT = 90;

const itemsArray = [
  {
    Icon: GemSvg,
    text: 'فرصت‌ها',
    path: '/dashboard/projects',
  },
  {
    Icon: CoinSvg,
    text: 'دارایی‌ها',
    path: '/dashboard/assets',
  },
  {},
  {
    Icon: BasketSvg,
    text: 'فروش',
    path: '/dashboard/transactions',
  },
  {
    Icon: HatSvg,
    text: 'آکادمی',
    path: '/dashboard/blog',
  },
];

type NavbarPropsType = {};

export const Navbar: FC<NavbarPropsType> = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <AppBar
      elevation={0}
      position="fixed"
      sx={{
        top: 'auto',
        bottom: 0,
        height: NAVBAR_HEIGHT,
        bgcolor: 'transparent',
      }}
    >
      <Toolbar sx={{ p: '0 !important' }}>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: -28,
            left: 0,
            right: 0,
            mx: 'auto',
            width: 76,
            height: 76,
            background: 'rgba(109, 134, 162, 0.2)',
            backdropFilter: 'blur(12.5px)',
            border: '2px solid white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => navigate('/dashboard/wallet')}
        >
          <Stack alignItems="center" rowGap={0.1}>
            <WalletSvg isSelected={pathname.includes('/wallet')} />
            <Typography
              fontSize={13}
              fontWeight={pathname.includes('/wallet') ? 'bold' : 'normal'}
              color="text.primary"
            >
              کیف پول
            </Typography>
          </Stack>
        </Box>
        <Stack
          sx={{
            width: '100%',
            height: NAVBAR_HEIGHT,
            borderTopRightRadius: 50,
            borderTopLeftRadius: 50,
            background: 'rgba(109, 134, 162, 0.2)',
            backdropFilter: 'blur(12.5px)',
          }}
          direction="row"
          alignItems="center"
          justifyContent="space-around"
        >
          {itemsArray.map(({ Icon, text, path }, index) => {
            const isSelected = path ? pathname.includes(path) : false;
            return (
              <Stack
                key={index}
                alignItems="center"
                rowGap={0.1}
                onClick={() => path && navigate(path)}
                sx={{
                  width: 50,
                }}
              >
                {Icon && <Icon isSelected={isSelected} />}
                <Typography
                  fontSize={13}
                  fontWeight={isSelected ? 'bold' : 'normal'}
                  color="text.primary"
                >
                  {text}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
