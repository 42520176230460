import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Back } from 'src/components/atoms/icons/Back';
import { Pencil } from 'src/components/atoms/icons/Pencil';
import { Setting } from '../atoms/icons/Setting';

export const ProfileHeader: FC<{
  setEditMode: (value: boolean) => void;
  editMode: boolean;
}> = ({ setEditMode, editMode }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Button
        sx={{
          backgroundColor: '#F5F4F8',
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          textAlign: 'center',
        }}
        onClick={() => setEditMode(true)}
      >
        {editMode ? (
          <Setting sx={{ fontSize: '35px' }} />
        ) : (
          <Pencil sx={{ stroke: '#204D6C' }} />
        )}
      </Button>
      <Typography sx={{ fontSize: '20px', fontWeight: 900 }}>
        حساب کاربری
      </Typography>
      <Button
        sx={{
          backgroundColor: '#F5F4F8',
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          textAlign: 'center',
        }}
      >
        <Back />
      </Button>
    </Stack>
  );
};
