import { Dialog, Theme, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { authContents } from './AuthContent';

export const LoginDialog: FC<{
  open: boolean;
  handleClose: () => void;
  flatMode?: boolean;
}> = ({ open, handleClose, flatMode }) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const [phoneNumber, setPhoneNumber] = useState('');

  const [pageNumber, setPageNumber] = useState(0);

  const LoginContent = authContents[pageNumber].component;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          height: isMobile ? '100vh' : '80vh',
          maxHeight: isMobile ? '100vh' : '900px',
          borderRadius: isMobile ? 0 : '50px',
          ...(flatMode && {
            boxShadow: 'unset',
            border: '1px solid #204D6C',
          }),
        },
      }}
      BackdropProps={{
        sx: {
          ...(flatMode && {
            background: 'white',
          }),
        },
      }}
    >
      <LoginContent
        changePage={(page: number) => setPageNumber(page)}
        handleCloseDialog={handleClose}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
      />
    </Dialog>
  );
};
