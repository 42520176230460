import type { Dispatch, FC, SetStateAction } from 'react';
import {
  Box,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Stack,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation } from 'react-router';
import { ArrowForwardIos, Menu } from '@mui/icons-material';
import { WalletSvg } from 'src/components/atoms/icons/WalletSvg';
import { GemSvg } from 'src/components/atoms/icons/GemSvg';
import { CoinSvg } from 'src/components/atoms/icons/CoinSvg';
import { BasketSvg } from 'src/components/atoms/icons/BasketSvg';
import { HatSvg } from 'src/components/atoms/icons/HatSvg';
import CustomListItemButton from 'src/components/atoms/CustomListItemButton';
import { SIDEBAR_MIN_WIDTH } from 'src/components/templates/DesktopTemplate';

type SidebarPropsType = {
  openSandwichMenu: boolean;
  setOpenSandwichMenu: Dispatch<SetStateAction<boolean>>;
};

export const Sidebar: FC<SidebarPropsType> = ({
  openSandwichMenu,
  setOpenSandwichMenu,
}) => {
  const { pathname } = useLocation();
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const listItemsArray = [
    {
      title: 'فرصت‌ها',
      path: '/dashboard/projects',
      Icon: GemSvg,
    },
    {
      title: 'دارایی‌ها',
      path: '/dashboard/assets',
      Icon: CoinSvg,
    },
    {
      title: 'فروش',
      path: '/dashboard/transactions',
      Icon: BasketSvg,
    },
    {
      title: 'آکادمی',
      path: '/dashboard/blog',
      Icon: HatSvg,
    },
    {
      title: 'کیف پول ',
      path: '/dashboard/wallet',
      Icon: WalletSvg,
    },
  ];

  return (
    <SwipeableDrawer
      className="swipeable-drawer-wrapper"
      variant="permanent"
      open={openSandwichMenu}
      onOpen={() => setOpenSandwichMenu(true)}
      PaperProps={{
        className: 'swipeable-drawer',
        sx: {
          width: openSandwichMenu ? 270 : SIDEBAR_MIN_WIDTH,
          transition: '300ms',
          borderRight: ({ palette }) =>
            '1px solid ' + palette.customColor.color2,
          overflow: 'hidden',
        },
      }}
      sx={{
        minWidth: SIDEBAR_MIN_WIDTH,
        width: openSandwichMenu ? 270 : SIDEBAR_MIN_WIDTH,
      }}
      anchor="left"
      onClose={() => setOpenSandwichMenu(false)}
    >
      <Stack
        direction="row"
        alignItems="center"
        pt={2}
        px={2}
        height={70}
        justifyContent={openSandwichMenu ? 'space-between' : 'center'}
      >
        <img
          style={{ display: openSandwichMenu ? 'inline-block' : 'none' }}
          className="sidebar-logo"
          src={process.env.PUBLIC_URL + '/icons/svg/logo.svg'}
          alt="logo"
        />
        {openSandwichMenu ? (
          <IconButton
            onClick={() => setOpenSandwichMenu(false)}
            sx={{ pl: 1.7 }}
          >
            <ArrowForwardIos fontSize="medium" sx={{ ml: -0.7 }} />
          </IconButton>
        ) : (
          <IconButton onClick={() => setOpenSandwichMenu(true)}>
            <Menu fontSize="large" />
          </IconButton>
        )}
      </Stack>
      <List
        component={Stack}
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: '100%', transition: '300ms' }}
      >
        <Box
          className="listItem-wrapper"
          sx={{
            width: '100%',
          }}
        >
          {listItemsArray.map(({ title, Icon, path }, index) => {
            const isSelected = pathname.includes(path);

            return (
              <CustomListItemButton
                key={index}
                to={path}
                sx={{
                  px: 3.7,
                  my: 1,
                  transform: '300ms',
                }}
              >
                <ListItemIcon
                  className="sidebar-itemIcon"
                  sx={{
                    ...(!isMd && { minWidth: 35 }),
                    transition: '300ms',
                  }}
                >
                  <Icon isSelected={isSelected} />
                </ListItemIcon>
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{
                    color: ({ palette }) => palette.text.primary,
                    fontWeight: isSelected ? 'bold' : undefined,
                    fontSize: '18px',
                    whiteSpace: 'nowrap',
                  }}
                />
              </CustomListItemButton>
            );
          })}
        </Box>
      </List>
    </SwipeableDrawer>
  );
};
