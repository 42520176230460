import { onlyNumber } from './onlyNumber.util';

/**
 * add comma to number and convert numbers to fa (1250333 --> 1,250,333)
 * @param price number
 * @returns string with fa numbers and commas
 */
export const price2Persian: (p: number | string) => string = (p) => {
  const price = typeof p === 'string' ? Number(p) : p;
  if (isNaN(price)) {
    console.log('price2Persian func input is not a number');
    return '0';
  }
  if (price < 0) {
    const reverse = String(price).substring(1);
    return (
      Math.ceil(Number(reverse))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' -'
    );
  }
  const round = Math.floor(Number(price));
  const priceString = Number(onlyNumber(String(round)));
  if (priceString === undefined || priceString === null) return '0';
  return priceString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
