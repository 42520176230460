import { PaletteColor, PaletteColorOptions, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    customColor: PaletteColor & {
      color1: string;
      color2: string;
      color3: string;
      color4: string;
      color5: string;
      color6: string;
    };
  }

  interface PaletteOptions {
    customColor: PaletteColorOptions & {
      color1: string;
      color2: string;
      color3: string;
      color4: string;
      color5: string;
      color6: string;
    };
  }
  interface TypographyVariants {
    t700_24: React.CSSProperties;
    t700_20: React.CSSProperties;
    t700_16: React.CSSProperties;
    t700_14: React.CSSProperties;
    t600_14: React.CSSProperties;
    t500_16: React.CSSProperties;
    t400_24: React.CSSProperties;
    t400_16: React.CSSProperties;
    t400_14: React.CSSProperties;
    t400_12: React.CSSProperties;
    t400_9: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    t700_24: React.CSSProperties;
    t700_20: React.CSSProperties;
    t700_16: React.CSSProperties;
    t700_14: React.CSSProperties;
    t600_14: React.CSSProperties;
    t500_16: React.CSSProperties;
    t400_24: React.CSSProperties;
    t400_16: React.CSSProperties;
    t400_14: React.CSSProperties;
    t400_12: React.CSSProperties;
    t400_9: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    t700_24: true;
    t700_20: true;
    t700_16: true;
    t700_14: true;
    t600_14: true;
    t500_16: true;
    t400_24: true;
    t400_16: true;
    t400_14: true;
    t400_12: true;
    t400_9: true;
  }
}

export const customBreakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1285,
  xl: 1536,
};

export const BORDER_RADIUS = 10;
export const BORDER_RADIUS10 = '10px';
export const BORDER_RADIUS15 = '15px';
export const BORDER_RADIUS20 = '20px';
export const BORDER_RADIUS25 = '25px';
export const BORDER_RADIUS30 = '30px';
export const BORDER_RADIUS50 = '50px';

const theme = createTheme({
  direction: 'rtl',
  breakpoints: {
    values: customBreakpoints,
  },
  palette: {
    mode: 'light',
    primary: { main: '#37A835' },
    secondary: { main: '#6D9EA4' },
    error: { main: '#DF3131', light: '#FF8686' },
    success: { main: '#37A835', light: '#DAEDC2' },
    warning: { main: '#E7B401', light: '#FFF3CA' },
    text: { primary: '#252B5C', secondary: '#53587A' },
    customColor: {
      color1: '#6d86a233',
      color2: '#F5F4F8',
      color3: '#204D6C',
      color4: '#ECEDF3',
      color5: '#E2EcED',
      color6: '#A1A5C1',
    },
  },
  typography: {
    fontFamily: "'iransansxv', iransansx, tahoma",
    button: {
      textTransform: 'capitalize',
    },
    t700_24: { fontSize: 24, fontWeight: 700 },
    t700_20: { fontSize: 20, fontWeight: 700 },
    t700_16: { fontSize: 16, fontWeight: 700 },
    t700_14: { fontSize: 14, fontWeight: 700 },
    t600_14: { fontSize: 14, fontWeight: 600 },
    t500_16: { fontSize: 16, fontWeight: 500 },
    t400_24: { fontSize: 24, fontWeight: 400 },
    t400_16: { fontSize: 16, fontWeight: 400 },
    t400_14: { fontSize: 14, fontWeight: 400 },
    t400_12: { fontSize: 12, fontWeight: 400 },
    t400_9: { fontSize: 9, fontWeight: 400 },
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '::placeholder': '#A1A5C1',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '16px',
          borderRadius: BORDER_RADIUS,
          fontSize: '16px',
          fontWeight: 700,
        },
        contained: {
          color: 'white',
        },
      },
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: BORDER_RADIUS,
          overflow: 'hidden',
          '&:before': { display: 'none' },
        },
      },
      // defaultProps: {
      //   hiddenLabel: true,
      // },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
    },
    MuiContainer: {
      defaultProps: { maxWidth: 'lg' },
    },
    MuiTableCell: {
      styleOverrides: { root: { border: 'none' } },
    },
    MuiDialogActions: {
      styleOverrides: { root: { padding: 24 } },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          sx: {
            borderRadius: '50px',
          },
          elevation: 0,
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(31, 76, 107, 0.69)',
          backdropFilter: 'blur(4px)',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          t700_24: 'p',
          t700_20: 'p',
          t700_16: 'p',
          t700_14: 'p',
          t600_14: 'p',
          t500_16: 'p',
          t400_24: 'p',
          t400_16: 'p',
          t400_14: 'p',
          t400_12: 'p',
          t400_9: 'p',
        },
      },
    },
  },
});

export default theme;
