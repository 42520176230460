import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useState } from 'react';
import { ArrowDownSvg } from 'src/components/atoms/icons/ArrowDownSvg';

const frequentQuestions = [
  {
    panel: 'panel1',
    question: 'سرمایه گذاری در بلوک چیست؟',
    answer:
      'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
  },
  {
    panel: 'panel2',
    question: 'سرمایه گذاری در بلوک چیست؟',
    answer:
      'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
  },
  {
    panel: 'panel3',
    question: 'سرمایه گذاری در بلوک چیست؟',
    answer:
      'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
  },
  {
    panel: 'panel4',
    question: 'سرمایه گذاری در بلوک چیست؟',
    answer:
      'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
  },
  {
    panel: 'panel5',
    question: 'سرمایه گذاری در بلوک چیست؟',
    answer:
      'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
  },
  {
    panel: 'panel6',
    question: 'سرمایه گذاری در بلوک چیست؟',
    answer:
      'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
  },
];

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `2px solid #A0A0A0`,
  borderRadius: '16px',
  overflow: 'hidden',
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowDownSvg
        sx={{
          fontSize: '2rem',
          color: 'transparent',
          transform: 'rotate(-90deg)',
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#CAFFA8',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
    '& path': {
      stroke: '#034F4F',
    },
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '&.Mui-expanded': {
    backgroundColor: '#CAFFA8',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: '#CAFFA8',
  padding: theme.spacing(3),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const FAQContent = () => {
  const [expanded, setExpanded] = useState<string | false>('');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <>
      {frequentQuestions.map((frequentQuestion, index) => {
        return (
          <Accordion
            key={index}
            expanded={expanded === `${frequentQuestion.panel}`}
            onChange={handleChange(`${frequentQuestion.panel}`)}
            sx={{ my: { xs: '15px', sm: '5px' } }}
          >
            <AccordionSummary id={`${frequentQuestion.panel}d-header`}>
              <Typography
                sx={{
                  fontSize: { xs: 20, md: '24px' },
                  color: '#252B5C',
                  fontWeight: 700,
                }}
              >
                {frequentQuestion.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{frequentQuestion.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
