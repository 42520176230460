import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const GooglePlaySvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="20" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M0.540999 23.844L13.311 11.9746L0.551698 0.126438C0.210898 0.310901 0 0.738002 0 1.29707V22.6847C0.00457066 23.2438 0.207841 23.6397 0.540999 23.844V23.844Z"
        fill="white"
      />
      <path
        d="M13.7691 11.6467L17.5729 8.11066L2.08257 0.215683C1.82173 0.074815 1.52545 0.000407984 1.22369 0L13.7676 11.6452L13.7691 11.6467Z"
        fill="white"
      />
      <path
        d="M13.7689 12.3037L1.18533 24C1.50117 24.0049 1.81226 23.9283 2.08394 23.7787L17.6048 15.8624L13.7689 12.3037Z"
        fill="white"
      />
      <path
        d="M23.1841 10.9757L18.0293 8.34361L14.1216 11.9761L18.0584 15.6313L23.1826 13.0062C24.2722 12.4486 24.2722 11.5305 23.1841 10.9757Z"
        fill="white"
      />
    </SvgIcon>
  );
};
