import { ProjectRo } from 'src/app/services/generatedApi';

export const projectState2persian = (projectState?: ProjectRo['state']) => {
  if (!projectState) return '';

  switch (projectState) {
    case 'FUND_RAISE':
      return 'در حال جذب سرمایه';
    // case 'FUND_RAISE':
    //   return 'به زودی';
    // case 'FUND_RAISE':
    //   return 'اتمام جذب سرمایه';
    // case 'FUND_RAISE':
    //   return 'پایان یافته';
    // case 'FUND_RAISE':
    //   return 'در حال ساخت';

    default:
      return '';
  }
};
