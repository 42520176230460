import { createSlice } from '@reduxjs/toolkit';
import { enhancedApi } from '../services/generatedApi';

type authSliceType = {
  token?: string;
};

const initialState = () => {
  const localStorageUserToken = localStorage.getItem('token');
  let result: authSliceType = {};

  if (localStorageUserToken) {
    const userLocalToken: string = localStorageUserToken;
    result = {
      token: userLocalToken,
    };
  }
  return result;
};

const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout: (state) => {
      if (state.token) {
        localStorage.removeItem('token');
        return initialState();
      }
    },
  },
  extraReducers: ({ addMatcher }) => {
    addMatcher(
      enhancedApi.endpoints.authControllerLogin.matchFulfilled,
      (state, { payload }) => {
        const { token } = payload;
        state.token = token;
        localStorage.setItem('token', token);
      }
    );
  },
});

export const { logout: logoutAction } = authSlice.actions;

export default authSlice.reducer;
