import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { FC } from 'react';
import { DesktopTemplate } from './DesktopTemplate';
import { MobileTemplate } from './MobileTemplate';

export type MainTemplatePropsType = {
  children?: any;
  hideFooter?: boolean;
  showBackButton?: boolean;
};

const MainTemplate: FC<MainTemplatePropsType> = ({
  children,
  hideFooter,
  showBackButton,
}) => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return isMd ? (
    <DesktopTemplate children={children} showBackButton={showBackButton} />
  ) : (
    <MobileTemplate
      children={children}
      showBackButton={showBackButton}
      hideFooter={hideFooter}
    />
  );
};

export default MainTemplate;
