import { Box, Rating, Typography } from '@mui/material';
import { FC } from 'react';

export const LandingCommentCard: FC<{
  commentSliders: {
    ratingValue: number;
    comment: string;
    commentUser: string;
    date: string;
  };
}> = ({ commentSliders }) => {
  return (
    <Box
      sx={{
        border: '1px solid #CAFFA8',
        borderRadius: '16px',
        p: 4,
      }}
    >
      <Box sx={{ width: '100%', textAlign: 'right' }}>
        <Rating
          readOnly
          value={commentSliders.ratingValue}
          size="small"
          sx={{
            color: '#CAFFA8',
            direction: 'rtl',
            '& .MuiSvgIcon-root ': {
              fill: '#CAFFA8',
            },
          }}
        />
      </Box>
      <Typography sx={{ color: 'white', fontSize: '13px', fontWeight: 700 }}>
        {commentSliders.comment}
      </Typography>
      <Box sx={{ mt: 5 }}>
        <Typography sx={{ color: 'white', fontSize: '13px', fontWeight: 700 }}>
          {commentSliders.commentUser}
        </Typography>
        <Typography sx={{ color: 'white', fontSize: '13px', fontWeight: 700 }}>
          {commentSliders.date}
        </Typography>
      </Box>
    </Box>
  );
};
