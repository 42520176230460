import { Box, Button, Stack, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  LoginRo,
  useAuthControllerLoginMutation,
  useAuthControllerSendOtpMutation,
  // useAuthControllerSendOtpMutation,
} from 'src/app/services/generatedApi';
import { CodeTextfield } from 'src/components/atoms/CodeTextField';
import { Back } from 'src/components/atoms/icons/Back';
import { TimerSvg } from 'src/components/atoms/icons/TimerSvg';
import { AuthContentPropsType } from './AuthContent';
import { useNavigate } from 'react-router-dom';

export const AuthCode: FC<AuthContentPropsType> = ({
  handleCloseDialog,
  changePage,
  phoneNumber,
}) => {
  const [codeTextFields, setCodeTextFields] = useState<(number | undefined)[]>([
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ]);
  const focusInput = (index: number) => {
    const elem = inputs.current[index];
    if (elem === null) return;
    elem.selectionStart = 2;
    elem.selectionEnd = 2;
    elem.focus();
  };

  const inputs = useRef<(HTMLInputElement | null)[]>([
    null,
    null,
    null,
    null,
    null,
  ]);

  const [sendOtp] = useAuthControllerSendOtpMutation();
  const [login, { isLoading: submitLogin }] = useAuthControllerLoginMutation();

  const [remaining, setRemaining] = useState(60);

  const navigate = useNavigate();

  const resend = () => {
    sendOtp({ phoneNumberDto: { phoneNumber } })
      .unwrap()
      .then(() => {
        toast.success('کد ورود دوباره برای شما ارسال شد.');
        setRemaining(60);
        focusInput(0);
        setCodeTextFields([
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        ]);
      });
  };

  const submit = useCallback(
    (newCodeTextFields: (number | undefined)[]) => {
      const isFilled = newCodeTextFields.every((item) => item !== undefined);
      if (!isFilled) return;

      const code = newCodeTextFields.map((item) => item?.toString()).join('');
      login({ loginDto: { phoneNumber, OTPCode: code } })
        .unwrap()
        .then((res: LoginRo) => {
          if (!res) return;
          toast.success('خوش آمدید!');
          handleCloseDialog();
          navigate('/dashboard/projects');
        })
        .catch((err) => {
          toast.error('دوباره تلاش کنید.');
          focusInput(4);
        });
    },
    [handleCloseDialog, login, navigate, phoneNumber]
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setRemaining((prev) => Math.max(0, prev - 1));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Stack p={4} justifyContent="space-between" height="100%">
      <Stack>
        <Box sx={{ width: '100%', textAlign: 'right' }}>
          <Button
            sx={{
              backgroundColor: '#F5F4F8',
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              textAlign: 'center',
            }}
            onClick={() => {
              changePage(0);
            }}
          >
            <Back />
          </Button>
        </Box>
        <Typography
          sx={{ color: '#252B5C', fontSize: { md: '35px', xs: '27px' } }}
        >
          <span style={{ color: '#204D6C', fontWeight: 'bold' }}>کد</span> را
          وارد کنید
        </Typography>
        <Typography
          sx={{ color: '#53587A', fontSize: { md: '19px', xs: '15px' } }}
        >
          کد ۵ رقمی که به شماره زیر ارسال شده است را وارد کنید
        </Typography>
        <Typography
          sx={{
            color: '#252B5C',
            fontSize: '18px',
            fontWeight: 700,
          }}
        >
          {phoneNumber}
        </Typography>

        <Stack
          direction="row-reverse"
          width="100%"
          justifyContent="space-between"
          spacing={1}
          my={5}
        >
          {codeTextFields.map((codeTextField, index) => (
            <CodeTextfield
              loading={submitLogin}
              autoFocus={index === 0}
              ref={(el) => (inputs.current[index] = el)}
              key={index}
              goNext={() => {
                if (index < 4) {
                  focusInput(index + 1);
                }
              }}
              goBack={() => {
                if (index > 0) {
                  focusInput(index - 1);
                }
              }}
              value={codeTextField}
              setValue={(value) => {
                const newCodeTextFields = codeTextFields.map(
                  (codeTextField, idx) =>
                    index === idx ? value : codeTextField
                );
                setCodeTextFields(newCodeTextFields);
                if (index >= 4) {
                  submit(newCodeTextFields);
                }
              }}
            />
          ))}
        </Stack>
      </Stack>
      <Stack spacing={3} mb={5}>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button
            endIcon={<TimerSvg isSelected />}
            sx={{
              backgroundColor: '#F5F4F8',
              color: '#252B5C',
              fontWeight: 500,
              borderRadius: '100px',
              width: '90px',
            }}
          >
            {remaining}
          </Button>
        </Box>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Typography sx={{ color: '#53587A' }}>
            کد را دریافت نکردید؟
          </Typography>
          <Button
            disabled={remaining > 0}
            variant="text"
            sx={{ color: '#204D6C', fontWeight: 600 }}
            onClick={resend}
          >
            ارسال دوباره
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
