import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

export const ProgressBar = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
        my: 2,
      }}
    >
      <Box sx={{ width: '20%', mx: 'auto' }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
};
