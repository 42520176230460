import { FC } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useProjectControllerFindAllQuery } from 'src/app/services/generatedApi';
import { Typography } from '@mui/material';
import { ProjectsCardSkeleton } from 'src/components/organisms/projects/ProjectsCardSkeleton';
import { ProjectsCard } from 'src/components/organisms/projects/ProjectsCard';
import Loading from 'src/components/atoms/Loading';
import { AssetsSummary } from 'src/components/organisms/asset/AssetsSummary';

const Projects: FC = () => {
  const { data: projects, isLoading: getProjectsLoading } =
    useProjectControllerFindAllQuery();

  if (getProjectsLoading) return <Loading />;

  return (
    <Grid2
      direction={{ md: 'row-reverse' }}
      container
      justifyContent="space-between"
      p={{ xs: 2, md: 0 }}
      pt={{ md: 2.5, lg: 5 }}
      rowGap={6}
    >
      <Grid2 xs={12} md={5} lg={3}>
        <Typography variant="t700_24" gutterBottom>
          خلاصه دارایی‌های شما
        </Typography>
        <AssetsSummary />
      </Grid2>
      <Grid2 container xs={12} md={5.5} lg={8.5}>
        <Grid2 xs={12}>
          <Typography variant="t700_24" gutterBottom>
            فرصت‌های سرمایه گذاری
          </Typography>
        </Grid2>

        <Grid2 xs={12} container spacing={2.3} alignItems='stretch'>
          {getProjectsLoading
            ? [...Array(3)].map((_, index) => {
                return (
                  <Grid2 key={index} xs={12} sm={6} lg={4}>
                    <ProjectsCardSkeleton />
                  </Grid2>
                );
              })
            : projects?.map((project, index) => {
                return (
                  <Grid2 key={index} xs={12} lg={6} xl={4}>
                    <ProjectsCard project={project} />
                  </Grid2>
                );
              })}
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default Projects;
