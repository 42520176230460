type tourContentType = { title: string | any; text: string | any };

export const contentText: tourContentType[] = [
  {
    title: (
      <span>
        <strong style={{ color: '#204D6C' }}>سرمایه‌گذاری </strong>در ساخت
      </span>
    ),
    text: (
      <span>
        همراه با هزاران سرمایه گذار دیگر در ساخت انواع پروژه‌های عمرانی و
        ساختمانی <strong style={{ color: '#204D6C' }}>سهیم باش!</strong>
      </span>
    ),
  },
  {
    title: (
      <span>
        <strong style={{ color: '#204D6C' }}>سود بالا</strong> در زمان کوتاه
      </span>
    ),
    text: (
      <span>
        سود بالای ۵۰ درصد رو در مدت زمان ۶ تا ۸ ماه
        <strong style={{ color: '#204D6C' }}> تجربه کن!</strong>
      </span>
    ),
  },
  {
    title: (
      <span>
        خاطری
        <strong style={{ color: '#204D6C' }}> آسوده</strong>
      </span>
    ),
    text: (
      <span>
        در بازار املاک، به پشتوانه تضامین مالی و اسناد حقوقی معتبر با ریسک پایین{' '}
        <strong style={{ color: '#204D6C' }}> سرمایه گذاری کن!</strong>
      </span>
    ),
  },
  {
    title: (
      <span>
        حتی <strong style={{ color: '#204D6C' }}>با یک میلیون</strong> تومان
      </span>
    ),
    text: (
      <span>
        با کمترین مبلغی که فکرش رو میتونی بکنی <br />
        <strong style={{ color: '#204D6C' }}>شروع کن!</strong>
      </span>
    ),
  },
  {
    title: (
      <span>
        همه چیز، از همه جا، <strong style={{ color: '#204D6C' }}>اینجا</strong>
      </span>
    ),
    text: (
      <span>
        ثبت نام کن تا عقب نمونی...
        <strong style={{ color: '#204D6C' }}>
          <br />
          داستان خودت رو رقم بزن!
        </strong>
      </span>
    ),
  },
];
