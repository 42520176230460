import { FC, useMemo } from 'react';
import { ImagesSlider } from './ImagesSlider';
import { Avatar, Box, Paper, Stack, Typography } from '@mui/material';
import { LocationSvg } from 'src/components/atoms/icons/LocationSvg';
import { ArrowRightAlt } from '@mui/icons-material';
import { CustomSlider } from 'src/components/atoms/CustomSlider';
import Num2persian from 'src/utils/num2persian.util';
import { ShowMoreText } from 'src/components/atoms/ShowMoreText';
import { price2Persian } from 'src/utils/price2Persian.util';
import { useProject } from 'src/components/hooks/useProject';
import { useProjectControllerProjectProgressQuery } from 'src/app/services/generatedApi';
import { BORDER_RADIUS20 } from 'src/configs/theme';
import { projectState2persian } from 'src/utils/projectState.util';

type OverviewPropsType = {};

export const Overview: FC<OverviewPropsType> = () => {
  const { project } = useProject();

  const { data: progress } = useProjectControllerProjectProgressQuery(
    { id: project!.id },
    { skip: !project?.id }
  );

  const enhancedProgress = useMemo(() => {
    if (!progress || (progress as number | string) === 'Infinity') return 0;
    return progress;
  }, [progress]);

  const totalFundraising = useMemo(() => {
    if (!project) return 0;
    return (enhancedProgress / 100) * project.totalShare * project.priceOfShare;
  }, [enhancedProgress, project]);

  const statisticsInfo = [
    {
      title: 'تعداد زمین‌ها',
      value: 10,
    },
    {
      title: 'متراژ هر زمین (متر)',
      value: 200,
    },
    {
      title: 'حداقل قیمت متری هر زمین (تومان)',
      value: price2Persian(5000000),
    },
    {
      title: 'سود پیش بینی شده (درصد)',
      value: 120,
    },
  ];

  return (
    <Stack rowGap={1}>
      <Box sx={{ position: 'relative' }}>
        <ImagesSlider />
        <Box
          sx={{
            position: 'absolute',
            bottom: 20,
            left: 20,
            bgcolor: 'rgba(35, 79, 104, 0.7)',
            backdropFilter: 'blur(4px)',
            borderRadius: BORDER_RADIUS20,
            py: 1.3,
            px: 2,
          }}
        >
          <Typography color="white" variant="t400_14">
            {projectState2persian(project?.state)}
          </Typography>
        </Box>
      </Box>
      <Stack rowGap={2} sx={{ p: 2 }}>
        <Typography variant="t700_24">{project?.title}</Typography>
        <Stack rowGap={1}>
          <Stack direction="row" columnGap={0.5}>
            <LocationSvg fontSize="inherit" />
            <Typography
              textOverflow="ellipsis"
              noWrap
              maxWidth="300px"
              variant="t400_14"
            >
              {project?.location.address}
            </Typography>
          </Stack>
          <Stack direction="row" columnGap={0.5}>
            <Typography variant="t400_14">طول مدت پروژه: </Typography>
            <Typography variant="t700_14">
              {project?.statusDuration.duration} روز
            </Typography>
          </Stack>
        </Stack>
        <Paper
          component={Stack}
          direction="row"
          sx={{
            bgcolor: 'customColor.color2',
            p: 2,
            width: '100%',
            borderRadius: '20px',
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" columnGap={1}>
            <Avatar />
            <Stack>
              <Typography variant="t700_14">
                {project?.supervisingEngineer}
              </Typography>
              <Typography variant="t400_9">مهندس ناظر</Typography>
            </Stack>
          </Stack>
          <ArrowRightAlt sx={{ transform: 'rotate(180deg)' }} />
        </Paper>
        <Paper sx={{ bgcolor: 'secondary.main', px: 2, py: 1.4 }}>
          <Stack rowGap={1.5}>
            <Stack rowGap={0.8}>
              <Typography color="white" variant="t400_14">
                کل سرمایه مورد نیاز:{' '}
                {Num2persian(
                  (project?.priceOfShare || 1) * (project?.totalShare || 1)
                )}{' '}
                تومان
              </Typography>
              <CustomSlider
                totalValue={
                  (project?.priceOfShare || 0) * (project?.totalShare || 0)
                }
                currentValue={totalFundraising}
              />
            </Stack>
            <Stack rowGap={0.8} pb={1}>
              <Typography color="white" variant="t400_14">
                پیشرفت پروژه
              </Typography>
              <CustomSlider
                totalValue={100}
                currentValue={enhancedProgress || 0}
              />
            </Stack>
          </Stack>
        </Paper>
        <Stack>
          <Typography variant="t700_20">توضیحات کلی پروژه</Typography>
          <ShowMoreText text={project?.description || ''} />
        </Stack>
        <Stack>
          <Typography variant="t700_20">اطلاعات آماری</Typography>
          <Stack
            sx={{
              border: ({ palette }) => '1px solid' + palette.customColor.color4,
              borderRadius: '25px',
            }}
            p={2}
            rowGap={1.1}
          >
            {statisticsInfo.map(({ title, value }, index) => {
              return (
                <Stack
                  key={index}
                  direction="row"
                  sx={{ width: '100%' }}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="t400_14">{title}</Typography>
                  <Typography variant="t400_14">{value}</Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
