import { FC } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useAssetControllerGetCurrentUserAssetsQuery } from 'src/app/services/generatedApi';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Loading from 'src/components/atoms/Loading';
import { AssetsSummary } from 'src/components/organisms/asset/AssetsSummary';
import { AssetsCard } from 'src/components/organisms/asset/AssetsCard';
import { OrderCard } from 'src/components/organisms/order/OrderCard';
import { useAppSelector } from 'src/app/reduxCustomHooks';

const Assets: FC = () => {
  const { data: assets, isLoading: getAssetsLoading } =
    useAssetControllerGetCurrentUserAssetsQuery();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const latestUnfinishedOrder = useAppSelector(
    (state) => state.latestUnfinishedOrder
  );

  if (getAssetsLoading) return <Loading />;

  return (
    <Grid2
      direction={{ md: 'row-reverse' }}
      container
      justifyContent="space-between"
      alignItems="start"
      p={{ xs: 2, md: 0 }}
      pt={{ md: 2.5, lg: 5 }}
      rowGap={6}
    >
      <Grid2 xs={12} md={5} lg={3}>
        <AssetsSummary />
        {isMd && latestUnfinishedOrder && (
          <>
            <Typography variant="t700_24" pt={8} pb={1.25}>
              رسید
            </Typography>
            <OrderCard />
          </>
        )}
      </Grid2>
      {latestUnfinishedOrder && (
        <Grid2 container xs={12} md={5.5} lg={8.5} display={{ md: 'none' }}>
          <Grid2 xs={12}>
            <Typography variant="t700_24" pb={1.25}>
              رسید
            </Typography>
          </Grid2>
          <Grid2 xs={12} container spacing={2.3}>
            <OrderCard />
          </Grid2>
        </Grid2>
      )}
      <Grid2 container xs={12} md={5.5} lg={8.5}>
        <Grid2 xs={12}>
          <Typography variant="t700_24" pb={2.5}>
            سرمایه گذاری‌ها
          </Typography>
        </Grid2>
        <Grid2 xs={12} container spacing={2.3}>
          {assets?.map((asset, index) => {
            return (
              <Grid2 key={index} xs={12} sm={6} md={12} lg={6} xl={4}>
                <AssetsCard asset={asset} />
              </Grid2>
            );
          })}
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default Assets;
