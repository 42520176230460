import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DocSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="37"
      height="45"
      viewBox="0 0 37 45"
      {...props}
      sx={{
        fill: 'none',
        ...props?.sx,
      }}
    >
      <path
        d="M28.6382 44.4H8.35397C8.1443 44.3589 7.93875 44.3095 7.72908 44.2725C3.09586 43.3928 0.0207482 39.6969 0.012526 34.9814C0.0043038 28.9299 0.012526 22.8783 0.012526 16.8226C0.012526 14.2368 -0.0203629 11.6509 0.0248594 9.06497C0.111193 4.06175 4.16886 0.0616393 9.17208 0.0205282C12.0622 -2.74047e-05 14.9482 -0.0164718 17.8383 0.0287504C21.1519 0.0780837 24.0625 1.23742 26.4511 3.53553C28.8437 5.83775 31.1953 8.18931 33.4934 10.5861C35.6846 12.8719 36.9056 15.651 36.9468 18.8206C37.0207 24.3501 37.0084 29.8836 36.9591 35.4172C36.9262 39.1748 34.5047 42.513 30.9692 43.8039C30.2169 44.0793 29.4152 44.2068 28.6382 44.4041V44.4ZM33.2139 18.5041C33.0083 18.5041 32.8397 18.5041 32.6671 18.5041C29.8345 18.5041 27.0061 18.5123 24.1735 18.5041C20.8887 18.4959 18.5002 16.0868 18.4961 12.802C18.4961 9.96942 18.4961 7.14097 18.4961 4.30842C18.4961 4.15219 18.4961 3.99597 18.4961 3.79042C18.1302 3.76575 17.8054 3.72875 17.4765 3.72875C14.7591 3.72464 12.0457 3.72464 9.3283 3.72875C9.01175 3.72875 8.69108 3.73697 8.37864 3.79042C5.56253 4.28786 3.69608 6.53253 3.69608 9.43497C3.69197 17.945 3.69197 26.4509 3.69608 34.9609C3.69608 38.2991 6.09697 40.6918 9.43519 40.6959C15.4744 40.7 21.5095 40.6959 27.5487 40.6959C27.9105 40.6959 28.2764 40.6918 28.6259 40.626C31.4214 40.1285 33.2879 37.8839 33.292 35.0102C33.3002 29.8836 33.292 24.753 33.292 19.6264C33.292 19.2688 33.2426 18.9152 33.2097 18.5041H33.2139ZM31.9106 14.8164C31.9517 14.7753 31.9929 14.7342 32.0299 14.6931C31.5406 14.0805 31.1049 13.4186 30.5581 12.8595C28.4367 10.693 26.2907 8.54697 24.1242 6.42153C23.5774 5.88708 22.9279 5.45542 22.2619 4.92919C22.2249 5.11831 22.2084 5.17175 22.2084 5.22519C22.2084 7.82342 22.1961 10.4258 22.2166 13.024C22.2249 14.0271 22.9895 14.7917 24.0296 14.8041C26.6566 14.8288 29.2877 14.8123 31.9147 14.8123L31.9106 14.8164Z"
        fill="#204D6C"
      />
      <path
        d="M18.4958 27.7541C20.9214 27.7541 23.3469 27.7541 25.7725 27.7541C26.8948 27.7541 27.717 26.9894 27.7417 25.937C27.7664 24.9051 26.94 24.0582 25.8341 24.0541C20.9378 24.0418 16.0415 24.0377 11.1451 24.0705C10.7217 24.0705 10.2407 24.2843 9.88714 24.5392C9.26636 24.9873 9.0978 25.8137 9.36091 26.5331C9.63225 27.2772 10.2982 27.75 11.1287 27.7541C13.583 27.7623 16.0374 27.7541 18.4958 27.7541ZM15.7208 35.1377C15.7208 35.1377 15.7208 35.1377 15.7208 35.1418C17.209 35.1418 18.6972 35.15 20.1814 35.1336C20.4774 35.1336 20.7939 35.0966 21.0694 34.9856C21.8792 34.669 22.315 33.8015 22.1506 32.9423C21.982 32.079 21.2831 31.4664 20.3746 31.4623C17.2707 31.45 14.1668 31.4582 11.0629 31.4623C10.8944 31.4623 10.7176 31.4911 10.5531 31.5404C9.71447 31.7953 9.15947 32.6258 9.25814 33.4603C9.36503 34.4059 10.0721 35.113 11.0012 35.1253C12.5758 35.15 14.1504 35.1336 15.7208 35.1336V35.1377Z"
        fill="#204D6C"
      />
      <path
        d="M18.4959 27.7541C16.0416 27.7541 13.5873 27.7541 11.1288 27.7541C10.2984 27.7541 9.62828 27.2772 9.36105 26.5331C9.10205 25.8137 9.2665 24.9873 9.88727 24.5392C10.2408 24.2843 10.7218 24.0747 11.1453 24.0705C16.0416 24.0377 20.9379 24.0418 25.8343 24.0541C26.9402 24.0541 27.7665 24.9051 27.7418 25.937C27.7172 26.9894 26.8949 27.7541 25.7726 27.7541C23.3471 27.7541 20.9215 27.7541 18.4959 27.7541Z"
        fill="#204D6C"
      />
      <path
        d="M15.7251 35.1417C14.1505 35.1417 12.576 35.1582 11.0055 35.1335C10.0723 35.1171 9.36931 34.4141 9.26242 33.4685C9.16787 32.6339 9.72287 31.8035 10.5574 31.5486C10.7219 31.4993 10.8986 31.4705 11.0672 31.4705C14.1711 31.4664 17.275 31.4582 20.3789 31.4705C21.2874 31.4705 21.9863 32.0872 22.1549 32.9505C22.3234 33.8097 21.8835 34.6772 21.0736 34.9937C20.8023 35.1006 20.4858 35.1376 20.1856 35.1417C18.6974 35.1541 17.2133 35.1499 15.7251 35.1499C15.7251 35.1499 15.7251 35.1499 15.7251 35.1458V35.1417Z"
        fill="#204D6C"
      />
    </SvgIcon>
  );
};
