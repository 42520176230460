import { Box, Stack, SvgIconProps, Typography } from '@mui/material';
import { FC } from 'react';

export const LandingSliderCard: FC<{
  sliderCard: { title: string; text: string; icon: FC<SvgIconProps> };
}> = ({ sliderCard }) => {
  const IconComponent = sliderCard.icon;
  return (
    <Stack
      sx={{
        backgroundColor: '#115957',
        borderRadius: '12px',
        width: 270,
        height: 350,
      }}
      p={2}
      spacing={2}
    >
      <Box
        sx={{
          backgroundColor: '#6DAC80',
          border: '4px solid #DAEDC2',
          borderRadius: '50%',
          width: '80px',
          height: '80px',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          mx: 'auto',
        }}
      >
        <IconComponent sx={{ fontSize: '55px' }} />
      </Box>
      <Stack spacing={3}>
        <Typography
          sx={{
            color: '#F5F4F8',
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          {sliderCard.title}
        </Typography>
        <Typography
          sx={{
            color: '#FFF3CA',
            fontSize: '16px',
            fontWeight: 500,
            textAlign: 'left',
          }}
        >
          {sliderCard.text}
        </Typography>
      </Stack>
    </Stack>
  );
};
