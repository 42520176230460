import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export type ExitSvgPropsType = {
  svgProps?: SvgIconProps;
};

export const ExitSvg: FC<ExitSvgPropsType> = ({ svgProps }) => {
  return (
    <SvgIcon
      width="37"
      height="38"
      viewBox="0 0 37 38"
      {...svgProps}
      sx={{
        fill: 'none',
        ...svgProps?.sx,
      }}
    >
      <path
        d="M12.7193 0C12.7853 0.0232949 12.8513 0.0543547 12.9173 0.073767C14.0394 0.388248 14.777 1.45205 14.6334 2.55467C14.4742 3.766 13.5424 4.61626 12.3311 4.62015C10.0676 4.62791 7.8041 4.62015 5.54061 4.62015C4.80682 4.62015 4.62823 4.79874 4.62823 5.54029C4.62823 14.171 4.62823 22.8057 4.62823 31.4364C4.62823 32.1935 4.80294 32.3643 5.56779 32.3643C7.81962 32.3643 10.0715 32.3604 12.3233 32.3643C13.5152 32.3682 14.447 33.1874 14.6217 34.3521C14.8236 35.68 13.8142 36.9573 12.4669 36.9728C9.96275 37.0039 7.45467 37.0349 4.95436 36.9651C2.20556 36.8952 0.011965 34.5346 0.00420007 31.7431C-0.00744736 27.1696 0.00420007 22.5921 0.00420007 18.0186C0.00420007 13.8177 0.00420007 9.61689 0.00420007 5.41217C0.00420007 2.64008 1.76296 0.551312 4.50011 0.0582371C4.54281 0.0504722 4.58552 0.0194124 4.62823 0C7.32655 0 10.0249 0 12.7232 0H12.7193Z"
        fill="#204D6C"
      />
      <path
        d="M36.9998 19.1484C36.9222 19.525 36.864 19.9016 36.7669 20.2743C36.4563 21.4351 35.8933 22.464 35.047 23.3181C33.0514 25.3254 31.0519 27.3287 29.0446 29.3205C28.1167 30.2406 26.6725 30.2251 25.7678 29.3166C24.8632 28.4042 24.871 26.9754 25.7989 26.0436C27.4373 24.3975 29.0835 22.7552 30.7296 21.1129C30.8151 21.0275 30.9121 20.9576 31.0014 20.8799C30.9898 20.845 30.9781 20.8062 30.9665 20.7712C30.8228 20.7712 30.6792 20.7712 30.5316 20.7712C23.7412 20.7829 16.9469 20.7984 10.1564 20.8101C9.01495 20.8101 8.1181 20.185 7.81139 19.1872C7.35326 17.6924 8.44423 16.2016 10.0244 16.1899C12.1209 16.1744 14.2136 16.1822 16.3101 16.1783C21.0545 16.1666 25.7989 16.1511 30.5433 16.1394C30.6481 16.1394 30.7529 16.1317 30.9238 16.12C30.7995 15.9841 30.718 15.891 30.6287 15.8017C28.9942 14.1671 27.3519 12.5404 25.729 10.8942C24.4944 9.64018 24.9758 7.59412 26.6259 7.06222C27.5499 6.76327 28.3575 7.0001 29.0408 7.67565C30.4385 9.06946 31.8361 10.4671 33.2338 11.861C33.8201 12.4472 34.4102 13.0335 34.9926 13.6275C36.0797 14.7379 36.732 16.0657 36.9338 17.607C36.9455 17.6886 36.9765 17.7662 36.996 17.8477V19.1484H36.9998Z"
        fill="#204D6C"
      />
    </SvgIcon>
  );
};
