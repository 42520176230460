import {
  Box,
  Button,
  Chip,
  InputAdornment,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Calendar } from 'src/components/atoms/icons/Calendar';
import { Call } from 'src/components/atoms/icons/Call';
import { Gender } from 'src/components/atoms/icons/Gender';
import { IdCode } from 'src/components/atoms/icons/IdCode';
import { User } from 'src/components/atoms/icons/User';
import { MelkTextField } from 'src/components/atoms/MelkTextField';
import { ProfileAvatar } from 'src/components/molecules/ProfileAvatar';
import React, { FC, useState } from 'react';
import { e2p, p2e } from 'src/utils/convertNumbers.util';
import { LoadingButton } from '@mui/lab';
import { UserRo } from 'src/app/services/generatedApi';
import { formatDate } from 'src/utils/dateFormatter';
import { DateSelector } from 'src/components/molecules/datePicker/DateSelector';
import { DatePickerDrawer } from 'src/components/molecules/datePicker/DatePickerDrawer';
import { MelkDrawer } from 'src/components/atoms/MelkDrawer';

export enum ValidationState {
  NOT_VERIFIED,
  VERIFYING,
  VERIFIED,
}

export const ProfileContent: FC<{
  editMode: boolean;
  formik: any;
  isLoading: boolean;
  user?: UserRo;
}> = ({ editMode, formik, isLoading, user }) => {
  const [validationState, setValidationState] = useState(
    ValidationState.NOT_VERIFIED
  );
  console.log(setValidationState);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const [openDateDrawer, setOpenDateDrawer] = useState(false);

  return (
    <form onSubmit={formik.handleSubmit}>
      <React.Fragment key={'bottom'}>
        <Box sx={{ p: 3 }}>
          <ProfileAvatar editMode={editMode} />
          {!editMode && validationState !== ValidationState.VERIFIED && (
            <Box
              sx={{
                backgroundColor:
                  validationState === ValidationState.VERIFYING
                    ? '#E7B401'
                    : '#DF3131',
                padding: 2,
                color: 'white',
                borderRadius: '10px',
                fontSize: '18px',
              }}
            >
              {validationState === ValidationState.VERIFYING
                ? 'در حال بررسی اطلاعات شما هستیم.'
                : 'شما هنوز احرازهویت نکرده‌اید.'}
            </Box>
          )}
          <Stack spacing={2} mt={4} mb={2}>
            <MelkTextField
              disabled={!editMode}
              placeholder="نام"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <User sx={{ stroke: editMode ? '#292D32' : '#6D9EA4' }} />
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps('firstName')}
              error={Boolean(
                formik.errors.firstName && formik.touched.firstName
              )}
            />
            <MelkTextField
              disabled={!editMode}
              placeholder="نام‌خانوادگی"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <User sx={{ stroke: editMode ? '#292D32' : '#6D9EA4' }} />
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps('lastName')}
              error={Boolean(formik.errors.lastName && formik.touched.lastName)}
            />
            <MelkTextField
              disabled
              placeholder="شماره تلفن"
              value={user?.phoneNumber && e2p(user.phoneNumber)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Call sx={{ stroke: editMode ? '#292D32' : '#6D9EA4' }} />
                  </InputAdornment>
                ),
              }}
            />
            <MelkTextField
              disabled={!editMode}
              placeholder="کد ملی"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IdCode sx={{ stroke: editMode ? '#292D32' : '#6D9EA4' }} />
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps('nationalCode')}
              value={e2p(formik.values.nationalCode)}
              onChange={(e) => {
                formik.setFieldValue('nationalCode', p2e(e.target.value, true));
              }}
              error={Boolean(
                formik.touched.nationalCode && !!formik.errors.nationalCode
              )}
            />
            <MelkTextField
              placeholder="تاریخ تولد"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Calendar
                      sx={{ stroke: editMode ? '#292D32' : '#6D9EA4' }}
                    />
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps('birthDate')}
              disabled
              value={formatDate({ date: formik.values.birthDate, lang: 'fa' })}
              error={Boolean(
                formik.errors.birthDate && formik.touched.birthDate
              )}
            />

            <MelkTextField
              placeholder="تست"
              onClick={() => setOpenDateDrawer(true)}
            />
            <MelkDrawer
              anchor={'bottom'}
              open={openDateDrawer}
              onClose={() => setOpenDateDrawer(false)}
            >
              <Typography
                sx={{
                  color: '#252B5C',
                  fontSize: { xs: 16, md: 20 },
                  fontWeight: 700,
                }}
              >
                لطفا تاریخ مورد نظر را انتخاب کنید:
              </Typography>
              <DateSelector onChange={(date) => console.log(date)} />
            </MelkDrawer>
            {/* <DatePickerDrawer /> */}

            <MelkTextField
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Gender sx={{ stroke: editMode ? '#292D32' : '#6D9EA4' }} />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Stack direction="row" spacing={3}>
                      <Chip
                        label="آقا"
                        clickable={editMode}
                        onClick={() => formik.setFieldValue('gender', 'MALE')}
                        sx={{
                          padding: '5px 10px',
                          backgroundColor:
                            formik.values.gender === 'MALE'
                              ? 'white'
                              : 'transparent',
                          color:
                            formik.values.gender === 'MALE'
                              ? '#252B5C'
                              : '#A1A5C1',
                          fontSize: '18px',
                        }}
                      />
                      <Chip
                        label="خانم"
                        clickable={editMode}
                        onClick={() => formik.setFieldValue('gender', 'FEMALE')}
                        sx={{
                          padding: '5px 10px',
                          backgroundColor:
                            formik.values.gender === 'FEMALE'
                              ? 'white'
                              : 'transparent',
                          color:
                            formik.values.gender === 'FEMALE'
                              ? '#252B5C'
                              : '#A1A5C1',
                          fontSize: '18px',
                        }}
                      />
                    </Stack>
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps('gender')}
              value=""
              error={Boolean(formik.errors.gender && formik.touched.gender)}
            />
          </Stack>
          {isMobile && (
            <Stack
              direction="row"
              spacing={2}
              width="100%"
              justifyContent="center"
            >
              {!editMode && (
                <Button
                  variant="outlined"
                  sx={{
                    color: '#DF3131',
                    border: '3px solid #DF3131',
                    '&:hover': {
                      border: '3px solid #DF3131',
                    },
                    py: 2,
                    fontSize: '19px',
                    fontWeight: 800,
                    borderRadius: '10px',
                    width:
                      validationState === ValidationState.VERIFYING
                        ? '80%'
                        : '35%',
                  }}
                >
                  خروج
                </Button>
              )}
              {!editMode && validationState !== ValidationState.VERIFYING && (
                <Button
                  variant="contained"
                  sx={{
                    color: 'white',
                    borderRadius: '10px',
                    backgroundColor:
                      validationState === ValidationState.VERIFIED
                        ? '#204D6C'
                        : '#37A835',
                    py: 2,
                    fontSize: '19px',
                    fontWeight: 500,
                    width: '65%',
                  }}
                  type="submit"
                >
                  {validationState === ValidationState.VERIFIED
                    ? 'حساب‌های بانکی'
                    : 'احراز هویت'}
                </Button>
              )}
              {editMode && (
                <LoadingButton
                  variant="contained"
                  sx={{
                    color: 'white',
                    borderRadius: '10px',
                    backgroundColor: '#37A835',
                    py: 2,
                    fontSize: '19px',
                    fontWeight: 500,
                    width: '80%',
                  }}
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                  loading={isLoading}
                >
                  ثبت تغییرات
                </LoadingButton>
              )}
            </Stack>
          )}
        </Box>
      </React.Fragment>
    </form>
  );
};
