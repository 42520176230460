import { FC } from 'react';
import { Stack, useTheme } from '@mui/material';
import GridLoader from 'react-spinners/GridLoader';

const Loading: FC = () => {
  const theme = useTheme();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: 'rgba(256,256,256,0.8)',
        backdropFilter: 'blur(5px)',
        position: 'fixed',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        width: '100%',
        height: '100%',
        zIndex: 10000,
        overflow: 'hidden',
      }}
    >
      <GridLoader color={theme.palette.primary.main} loading size={30} />
    </Stack>
  );
};

export default Loading;
