import { Box, InputAdornment, Stack, Typography } from '@mui/material';
import { MelkTextField } from 'src/components/atoms/MelkTextField';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import { EmailSvg } from 'src/components/atoms/icons/EmailSvg';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUserControllerUpdateCurrentUserMutation } from 'src/app/services/generatedApi';
import { useEffect } from 'react';

export const LandingEmailJoin = () => {
  const [updateUser, { isLoading }] =
    useUserControllerUpdateCurrentUserMutation();

  let userSchema = yup.object({
    email: yup.string().email('ایمیل صحیح نمی‌باشد.'),
  });

  // const formik = useFormik({
  //   initialValues: {
  //     email: '',
  //   },
  //   validationSchema: userSchema,
  //   onSubmit: (values) => {
  //     updateUser({
  //       userUpdateDto: values,
  //     })
  //       .unwrap()
  //       .then(() => {
  //         toast.success('ایمیل شما با موفقیت ثبت شد.');
  //       })
  //       .catch((err) => {
  //         console.log(err.data);
  //         toast.error(err.data);
  //       });
  //   },
  // });

  // useEffect(() => {
  //   if (!user) {
  //     return;
  //   }

  //   formik.setValues({
  //     email: user.email || '',
  //   });
  // }, [user]);

  return (
    // <form onSubmit={formik.handleSubmit}>
    <Stack spacing={3} p={{ xs: 1, md: 5 }} my={5}>
      <Typography
        sx={{ fontSize: { xs: 35, md: 64 }, fontWeight: 700, color: 'white' }}
      >
        عضویت در اخبار بلوک
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: 23, md: 36 },
          fontWeight: 500,
          color: 'white',
          pl: 5,
        }}
      >
        با عضویت در خبر‌نامه ی بلوک می‌تونین از جدیدترین فرصت‌های سرمایه‌گزاری و
        اخبار تازه قبل از بقیه کاربران باخبر بشوید
      </Typography>
      <Box sx={{ width: '100%', textAlign: 'right' }}>
        <MelkTextField
          placeholder="رایانامه"
          sx={{
            width: { xs: '100%', md: '45%' },
            '& input::placeholder': {
              color: '#BFBFBF',
              fontSize: { xs: 25, md: 30 },
              p: 2,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <WestOutlinedIcon
                  type="submit"
                  sx={{ color: '#034F4F', fontSize: 35 }}
                />
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <EmailSvg sx={{ fontSize: 40 }} />
              </InputAdornment>
            ),
          }}
          // {...formik.getFieldProps('email')}
          // helperText={formik.errors.email}
        />
      </Box>
    </Stack>
    // </form>
  );
};
