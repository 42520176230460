import {
  Box,
  Grow,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';

const texts = [
  {
    title: 'سرمایه گذاری',
    expandedText:
      'پروژه های عمرانی و ساخت و ساز همیشه یکی از مطمئن ترین راه ها برای سرمایه گذاری بوده. از طریق اپلیکیشن بلوک میتونی هر میزان که دوست داری روی پروژه های محتلف به صورت کاملا آنلاین سرمایه گذاری کنی و در مالکیت و سود پروژه ها شریک بشی. در انتهای هر پروژه ، سود کل پروژه بین تمام سرمایه گذارها تقسیم میشه .',
  },
  {
    title: 'مدیریت دارایی ها',
    expandedText:
      'تمام سرمایه گذاری هایی که انجام دادی و دارایی هایی که داری از طریق اپلیکیشن بلوک قابل مدیریته. فقط کافیه به قسمت مدیریت دارایی ها سربزنی و ببینی عملکرد سرمایه گذاری هات چه طور بوده و ارزش روز و سودی که تا امروز داشتن رو بررسی کنی',
  },
  {
    title: 'بررسی پروژه ها',
    expandedText:
      'هر هفته تعدادی پروژه جدید بر روی اپلیکیشن قرار میگیره. این پروژه ها همه شون به دقت بررسی میشن که از هم از نظر سود و هم از نظر اجرا دارای شرایط ایده آل برای سرمایه گذاری باشند. اتمام اغلب پروژه ها معمولا بین 6 تا 9 ماه طول میکشه. اطلاعات جامع هر پروژه نظیر زمان اجرا، سود تخمینی، سازنده، ضمانت ها، جزییات اجرایی و ... رو میتونی بررسی کنی.',
  },
  {
    title: 'کنترل سود و درآمد',
    expandedText:
      'برخی پروژه ها دارای سود بیشتری هستند و برخی دیگه سریعتر به اتمام میرسند. از طریق بخش فرصت ها میتونی زمان اجرا و سود تخمینی و اطلاعات کامل هر پروژه رو بررسی کنی و برای افزایش درآمدت برنامه ریزی کنی.',
  },
  {
    title: 'معامله و فروش',
    expandedText:
      'میتونی سهام خودت رو بفروشی یا با کاربران دیگه معاوضه و تهاتر کنی . از طریق بخش درخواست فروش امکان این رو داری زودتر از پایان پروژه دارایی ت رو نقد کنی. با سرمایه گذاری روی پروژه ها و فروش سهمت به بقیه میتونی در زمان کوتاه تری به سود برسی',
  },
];

export const LandingIntroSection = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const [selectedLine, setSelectedLine] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setSelectedLine((prev) => (prev + 1) % 5);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Stack
      sx={{
        backgroundColor: '#F9F7F1',
        border: '1px solid #CAFFA8',
        borderRadius: '40px',
        maxHeight: { xs: '900px', md: '1000px' },
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
      px={3}
      direction={{ xs: 'column', md: 'row' }}
    >
      <Stack
        sx={{
          width: { xs: '100%', md: '55%' },
          my: 'auto',
          height: '900px',
        }}
        spacing={3}
        py={3}
        px={{ xs: 1, md: 3 }}
        justifyContent="space-between"
      >
        <Typography
          sx={{
            color: '#151515',
            fontSize: { xs: '30px', md: '48px' },
            fontWeight: 500,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          مدیریت کامل فرآیند سرمایه‌گذاری <br />و دارایی‌ها زیر انگشتان شماست
        </Typography>

        {!isMobile && (
          <>
            <Typography
              sx={{
                fontSize: '36px',
                color: selectedLine === 0 ? '#53587A' : '#A1A5C1',
              }}
            >
              {texts[0].title}
            </Typography>
            <Grow
              in={selectedLine === 0}
              style={{ transformOrigin: '0 0 0' }}
              {...(selectedLine === 0 ? { timeout: 1000 } : {})}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  color: '#53587A',
                  display: selectedLine === 0 ? 'block' : 'none',
                }}
              >
                {texts[0].expandedText}
              </Typography>
            </Grow>

            <Typography
              sx={{
                fontSize: '36px',
                color: selectedLine === 1 ? '#53587A' : '#A1A5C1',
              }}
            >
              {texts[1].title}
            </Typography>
            <Typography
              sx={{
                fontSize: '24px',
                color: '#53587A',
                display: selectedLine === 1 ? 'block' : 'none',
              }}
            >
              {texts[1].expandedText}
            </Typography>

            <Typography
              sx={{
                fontSize: '36px',
                color: selectedLine === 2 ? '#53587A' : '#A1A5C1',
              }}
            >
              {texts[2].title}
            </Typography>
            <Typography
              sx={{
                fontSize: '24px',
                color: '#53587A',
                display: selectedLine === 2 ? 'block' : 'none',
              }}
            >
              {texts[2].expandedText}
            </Typography>

            <Typography
              sx={{
                fontSize: '36px',
                color: selectedLine === 3 ? '#53587A' : '#A1A5C1',
              }}
            >
              {texts[3].title}
            </Typography>
            <Typography
              sx={{
                fontSize: '24px',
                color: '#53587A',
                display: selectedLine === 3 ? 'block' : 'none',
              }}
            >
              {texts[3].expandedText}
            </Typography>

            <Typography
              sx={{
                fontSize: '36px',
                color: selectedLine === 4 ? '#53587A' : '#A1A5C1',
              }}
            >
              {texts[4].title}
            </Typography>
            <Typography
              sx={{
                fontSize: '24px',
                color: '#53587A',
                display: selectedLine === 4 ? 'block' : 'none',
              }}
            >
              {texts[4].expandedText}
            </Typography>
          </>
        )}
      </Stack>
      <Box
        sx={{
          width: { xs: '90%', sm: '70%', md: '40%' },
          mx: 'auto',
          position: 'absolute',
          top: { xs: 230, md: 70 },
          right: { xs: 20, md: 70 },
        }}
      >
        <img
          src="/images/phoneScreen.png"
          alt="phoneScreen"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </Stack>
  );
};
