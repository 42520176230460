import { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper';
import { BORDER_RADIUS30 } from 'src/configs/theme';
import { useProject } from 'src/components/hooks/useProject';
import { Stack } from '@mui/material';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

type ImagesSliderPropsType = {};

export const ImagesSlider: FC<ImagesSliderPropsType> = () => {
  const { project } = useProject();

  if (!project?.gallery || project?.gallery.length === 0) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <BrokenImageIcon color="disabled" sx={{ fontSize: 350 }} />
      </Stack>
    );
  }

  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      modules={[Pagination]}
      style={{
        border: '3px solid white',
        borderRadius: BORDER_RADIUS30,
      }}
    >
      {project.gallery.map((imagePath, index) => {
        return (
          <SwiperSlide key={index}>
            <img src={imagePath} alt="" style={{ width: '100%' }} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
