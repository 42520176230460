import {
  FC,
  ChangeEventHandler,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { price2Persian } from 'src/utils/price2Persian.util';
import { onlyNumber } from 'src/utils/onlyNumber.util';
import { ProjectRo } from 'src/app/services/generatedApi';

type BuyOrderPropsType = {
  statusDuration: ProjectRo['statusDuration'];
  priceOfShare: ProjectRo['priceOfShare'];
  estimatedProfit: ProjectRo['estimatedProfit'];
  setShowReceiptDialog: Dispatch<SetStateAction<boolean>>;
  investmentAmount: string;
  setInvestmentAmount: Dispatch<SetStateAction<string>>;
};

export const BuyOrder: FC<BuyOrderPropsType> = ({
  statusDuration,
  priceOfShare,
  estimatedProfit,
  setShowReceiptDialog,
  investmentAmount,
  setInvestmentAmount,
}) => {
  useEffect(() => {
    if (investmentAmount) return;
    setInvestmentAmount(priceOfShare.toString());
  }, [investmentAmount, priceOfShare, setInvestmentAmount]);

  const inputChangeHandler: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const newValue = onlyNumber(event.target.value.replaceAll(',', ''));
    setInvestmentAmount(newValue);
  };

  const hasMinimumAmount = useMemo(
    () => Number(investmentAmount) >= priceOfShare,
    [investmentAmount, priceOfShare]
  );

  const amountCheck = () => {
    if (hasMinimumAmount) return;
    setInvestmentAmount(priceOfShare.toString());
  };

  const estimatedProfitDecimal = estimatedProfit / 100;

  const yourProfit = Number(investmentAmount) * estimatedProfitDecimal;

  const contentArray = [
    {
      title: 'مدت زمان پروژه',
      value: statusDuration.duration + ' روز',
    },
    {
      title: 'برآورد سود',
      value: estimatedProfit + '%',
    },
    {
      title: 'سرمایه اولیه شما',
      value: price2Persian(investmentAmount || priceOfShare) + ' تومان',
    },
    {
      title: 'سود شما پس از اتمام پروژه',
      value: price2Persian(yourProfit) + ' تومان',
    },
  ];

  return (
    <Stack rowGap={3}>
      <Typography variant="t700_20">سرمایه گذاری</Typography>
      <Box>
        <Typography variant="t700_14" gutterBottom>
          مبلغ مورد نظر برای سرمایه گذاری را وارد کنید:
        </Typography>
        <TextField
          onBlur={amountCheck}
          hiddenLabel
          sx={{ textAlign: 'left' }}
          InputProps={{
            sx: { py: 1.2, px: 2, direction: 'ltr', textAlign: 'left' },
            startAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  '&:after': {
                    content: "''",
                    ml: 3,
                    width: '1px',
                    height: '40px',
                    bgcolor: 'text.primary',
                  },
                }}
              >
                تومان
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              direction: 'ltr',
            },
          }}
          variant="filled"
          fullWidth
          value={price2Persian(investmentAmount)}
          onChange={inputChangeHandler}
        />
      </Box>
      <Stack rowGap={1.5}>
        {contentArray.map(({ title, value }, index) => {
          return (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="t400_14">{title}:</Typography>
              <Typography variant="t700_16">{value}</Typography>
            </Stack>
          );
        })}
        <Divider flexItem />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="t400_14">
            سرمایه شما پس از اتمام پروژه:
          </Typography>
          <Typography variant="t700_16">
            {price2Persian(Number(investmentAmount) + yourProfit)} تومان
          </Typography>
        </Stack>
      </Stack>
      <Button variant="contained" onClick={() => setShowReceiptDialog(true)}>
        مشاهده رسید
      </Button>
    </Stack>
  );
};
