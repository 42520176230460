import { Apple } from '@mui/icons-material';
import {
  Box,
  Button,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { GooglePlaySvg } from '../../atoms/icons/GooglePlaySvg';

export const LandingHomePage = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row-reverse' }}
        justifyContent="space-between"
      >
        {isMobile ? (
          <Box sx={{ width: { xs: '100%', md: 'fit-content' }, mt: 10 }}>
            <img
              src="/images/mobileHome.png"
              alt="homePage"
              style={{
                height: '100%',
                width: '100%',
                margin: 'auto',
              }}
            />
          </Box>
        ) : (
          <Box sx={{ width: '30%', my: 10 }}>
            <img
              src="/images/mobileHome.png"
              alt="homePage"
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        )}

        <Stack spacing={4} sx={{ mt: '10%' }}>
          <Typography
            sx={{
              fontSize: { xs: 35, md: '4vw' },

              color: '#CAFFA8',
              fontWeight: 700,
            }}
          >
            سرمایه‌گذاری روی ساخت‌وساز <br />
            برای همه
          </Typography>
          {!isMobile && (
            <Typography
              sx={{ fontSize: 24, fontWeight: 300, color: '#F9F7F1' }}
            >
              از طریق اپلیکیشن بلوک میتونی حتی با 100،000 تومن روی ساخت و ساز
              <br />
              سرمایه‌گذاری تضمینی و پرسود انجام بدی.
            </Typography>
          )}

          <Stack direction="row" spacing={6} py={6}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#CAFFA8',
                color: '#292D32',
                fontSize: '20px',
                fontWeight: 500,
                height: 48,
                borderRadius: '8px',
                width: { xs: '100%', md: 241 },
                mx: { xs: 5, ms: 'unset' },
              }}
            >
              ویژگی ها را جستجو کنید
            </Button>
            {!isMobile && (
              <Stack direction="row" spacing={3}>
                <Button
                  variant="text"
                  sx={{
                    border: '1px solid #CAFFA8',
                    borderRadius: '8px',
                    width: 106,
                    height: 48,
                  }}
                >
                  <GooglePlaySvg />
                </Button>
                <Button
                  variant="text"
                  sx={{
                    border: '1px solid #CAFFA8',
                    borderRadius: '8px',
                    alignItems: 'center',
                    width: 106,
                    height: 48,
                  }}
                >
                  <Apple sx={{ color: 'white', fontSize: '33px' }} />
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
