import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Back } from 'src/components/atoms/icons/Back';
import { AddCardContent } from 'src/components/organisms/creditCard/AddCardContent';

export const AddCreditCardPage = () => {
  return (
    <>
      <Box sx={{ width: '100%', textAlign: 'right', p: 3 }}>
        <Button
          sx={{
            backgroundColor: '#6D86A233',
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            textAlign: 'center',
          }}
          variant="contained"
          component={Link}
          to="/creditCard"
        >
          <Back />
        </Button>
      </Box>
      <AddCardContent />
    </>
  );
};
