import { styled } from '@mui/material/styles';
import { Tab, TabProps } from '@mui/material';
import { BORDER_RADIUS50 } from 'src/configs/theme';

export const CustomTab = styled(Tab)<TabProps>(({ theme }) => ({
  padding: 0,
  color: theme.palette.customColor.color6,
  height: 37,
  borderRadius: BORDER_RADIUS50,
  fontSize: '14px',
  fontWeight: '600',
  '&.MuiButtonBase-root': {
    height: 37,
    padding: 0,
    margin: 0,
    minWidth: 'auto',
    minHeight: 'auto',
  },
  '&.Mui-selected': {
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
  },
  '&>span': {
    display: 'none',
  },
}));
