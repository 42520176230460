import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowDownSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="8"
      height="12"
      viewBox="0 0 8 12"
      {...props}
      sx={{
        fill: 'none',
        ...props?.sx,
      }}
    >
      <path
        d="M6.25 10.5L1.75 6L6.25 1.5"
        stroke="#204D6C"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
