import { FC, useMemo } from 'react';
import { Box, Button, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { BORDER_RADIUS10, BORDER_RADIUS20 } from 'src/configs/theme';
import { LocationSvg } from 'src/components/atoms/icons/LocationSvg';
import { CustomSlider } from 'src/components/atoms/CustomSlider';
import Num2persian from 'src/utils/num2persian.util';
import { useNavigate } from 'react-router-dom';
import {
  ProjectRo,
  useAssetControllerGetCurrentUserAssetsQuery,
  useProjectControllerProjectProgressQuery,
} from 'src/app/services/generatedApi';
import { projectState2persian } from 'src/utils/projectState.util';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { price2Persian } from 'src/utils/price2Persian.util';
type ProjectsCardPropsType = {
  project: ProjectRo;
};

export const ProjectsCard: FC<ProjectsCardPropsType> = ({ project }) => {
  const {
    id,
    title,
    priceOfShare,
    totalShare,
    estimatedProfit,
    statusDuration,
    location,
    gallery,
    state,
    earnedProfit,
  } = project;

  const { data: assets } = useAssetControllerGetCurrentUserAssetsQuery();

  const hasAsset = useMemo(
    () => assets?.find(({ project }) => project === id) || null,
    [assets, id]
  );

  const navigate = useNavigate();

  const gotToProjectPage = () => {
    navigate('/dashboard/projects/' + id);
  };

  const { data: progress } = useProjectControllerProjectProgressQuery(
    { id },
    { skip: !id }
  );

  const enhancedProgress = useMemo(() => {
    if (
      !progress ||
      (progress as number | string) === 'Infinity' ||
      isNaN(progress)
    )
      return 0;
    return progress;
  }, [progress]);

  const totalFundraising = useMemo(() => {
    const result = (enhancedProgress / 100) * totalShare * priceOfShare;
    if (isNaN(result)) {
      return 0;
    } else {
      return result;
    }
  }, [priceOfShare, enhancedProgress, totalShare]);

  return (
    <Stack
      width="100%"
      height="100%"
      bgcolor="customColor.color2"
      borderRadius={BORDER_RADIUS20}
      p={{ xs: 2, md: 1.7, lg: 1.3 }}
      rowGap={2}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box
          sx={{
            position: 'relative',
            width: '45%',
            height: '100%',
          }}
        >
          {gallery.length > 0 ? (
            <img
              src={gallery[0]}
              alt=""
              style={{
                width: '100%',
                borderRadius: BORDER_RADIUS20,
                verticalAlign: 'middle',
              }}
            />
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
            >
              <BrokenImageIcon
                color="disabled"
                sx={{ fontSize: { xs: 130, md: 150 } }}
              />
            </Stack>
          )}
          <Typography
            variant="t400_12"
            color="white"
            sx={{
              position: 'absolute',
              bgcolor: '#234F68B2',
              backdropFilter: 'blur(4px)',
              py: 0.3,
              px: 1,
              bottom: 5,
              left: 5,
              borderRadius: BORDER_RADIUS10,
            }}
          >
            {projectState2persian(state)}
          </Typography>
        </Box>

        <Stack width="50%" rowGap={0.5}>
          <Typography variant="t700_16" color="customColor.color3">
            {title}
          </Typography>
          <Tooltip title={location.address}>
            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              columnGap={0.5}
            >
              <LocationSvg sx={{ fontSize: 10 }} />
              <Typography
                variant="t400_12"
                textOverflow="ellipsis"
                maxWidth="100%"
                noWrap
              >
                {location.address}
              </Typography>
            </Stack>
          </Tooltip>
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="t400_14" color="text.secondary">
              طول مدت پروژه:
            </Typography>
            <Typography variant="t400_14">
              {statusDuration.duration} روز
            </Typography>
          </Stack>
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="t400_14" color="text.secondary">
              سود برآورده شده:
            </Typography>
            <Typography variant="t400_14">{estimatedProfit}٪</Typography>
          </Stack>
          <Typography variant="t400_14">۱۴ سرمایه گذار</Typography>
          <Typography variant="t400_14">تنها ۲۰ روز باقی است.</Typography>
        </Stack>
      </Stack>
      <Paper
        component={Stack}
        rowGap={1.5}
        sx={{ bgcolor: 'secondary.main', px: 2, py: 1.4 }}
      >
        <Stack rowGap={0.8}>
          <Typography color="white" variant="t400_14">
            کل سرمایه مورد نیاز: {Num2persian(priceOfShare * totalShare)} تومان
          </Typography>
          <CustomSlider
            totalValue={priceOfShare * totalShare}
            currentValue={totalFundraising}
          />
        </Stack>
        <Stack rowGap={0.8} pb={1}>
          <Typography color="white" variant="t400_14">
            پیشرفت پروژه
          </Typography>
          <CustomSlider totalValue={100} currentValue={enhancedProgress || 0} />
        </Stack>
      </Paper>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        columnGap={2}
      >
        {hasAsset && (
          <Stack sx={{ minWidth: 70 }}>
            <Typography variant="t400_9">سرمایه شما:</Typography>
            <Typography variant="t400_14">
              {price2Persian((hasAsset?.shareAmount || 0) * priceOfShare || '')}
            </Typography>
            <Typography variant="t400_9">تومان</Typography>
          </Stack>
        )}
        {hasAsset && (
          <Stack>
            <Typography variant="t400_9">ارزش امروز:</Typography>
            <Typography variant="t400_14">
              {price2Persian(
                (hasAsset?.shareAmount || 0) * priceOfShare * earnedProfit
              )}
            </Typography>
            <Typography variant="t400_9">تومان</Typography>
          </Stack>
        )}
        <Button
          fullWidth
          onClick={gotToProjectPage}
          sx={{
            height: 40,
            py: hasAsset ? '5px' : 'auto',
            color: 'white',
            bgcolor: 'customColor.color3',
            '&:hover': { bgcolor: 'text.secondary' },
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          اطلاعات بیشتر
        </Button>
      </Stack>
    </Stack>
  );
};
