import { FC, useState, useRef, useMemo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { BORDER_RADIUS50 } from 'src/configs/theme';
import { useTheme } from '@mui/system';
import { PlaySvg } from 'src/components/atoms/icons/PlaySvg';
import { LoadingButton } from '@mui/lab';

export const TOTAL_DURATION = 30;

const mimeType = 'video/webm; codecs="opus,vp8"';

type VideoValidationPropsType = {};

export const VideoValidation: FC<VideoValidationPropsType> = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [recordingStatus, setRecordingStatus] = useState<
    'recording' | 'inactive'
  >('inactive');
  const [recordedVideo, setRecordedVideo] = useState<string | null>(null);
  const [preparing, setPreparing] = useState(false);
  const [isPlaybackPlaying, setIsPlaybackPlaying] = useState(false);

  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const liveVideoFeed = useRef<HTMLVideoElement | null>(null);
  const recordedVideoRef = useRef<HTMLVideoElement | null>(null);
  const circleRef = useRef<HTMLDivElement | null>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);

  const startRecording = async () => {
    setPreparing(true);
    const videoConstraints = {
      audio: false,
      video: true,
    };
    const audioConstraints = { audio: true };
    let audioStream: MediaStream;
    let videoStream: MediaStream;

    try {
      audioStream = await navigator.mediaDevices.getUserMedia(audioConstraints);
      videoStream = await navigator.mediaDevices.getUserMedia(videoConstraints);
    } catch (err) {
      console.log(err);
      // TODO:
      return;
    }

    const combinedStream = new MediaStream([
      ...videoStream!.getVideoTracks(),
      ...audioStream!.getAudioTracks(),
    ]);

    liveVideoFeed!.current!.srcObject = videoStream!;

    const media = new MediaRecorder(combinedStream, { mimeType });

    mediaRecorder.current = media;

    mediaRecorder.current.onstart = () => {
      setRecordingStatus('recording');
      timer.current = setTimeout(() => {
        stopRecording();
      }, 1000 * TOTAL_DURATION);
      setPreparing(false);
    };

    mediaRecorder.current.start();

    let localVideoChunks: Blob[] = [];

    mediaRecorder.current.ondataavailable = ({ data }) => {
      if (typeof data === 'undefined') return;
      if (data.size === 0) return;
      localVideoChunks.push(data);
    };

    mediaRecorder.current.onstop = () => {
      const videoBlob = new Blob(localVideoChunks, { type: mimeType });
      const videoUrl = URL.createObjectURL(videoBlob);

      setRecordedVideo(videoUrl);
    };
  };

  const stopRecording = () => {
    if (!mediaRecorder.current) return;
    setRecordingStatus('inactive');
    mediaRecorder.current.stop();
  };

  const resetAnimation = () => {
    if (!circleRef.current) return;
    circleRef.current.style.animationName = 'unset';
    setTimeout(() => {
      if (!circleRef.current) return;
      circleRef.current.style.animationName = 'videoRecorderProgress';
    }, 10);
  };

  const restartStates = () => {
    if (!circleRef.current) return;
    setRecordingStatus('inactive');
    setRecordedVideo(null);
    mediaRecorder.current = null;
    liveVideoFeed.current = null;
    resetAnimation();
  };

  const startPlayback = () => {
    if (!recordedVideoRef.current || isPlaybackPlaying) return;
    recordedVideoRef.current.onplaying = () => {
      resetAnimation();
      setIsPlaybackPlaying(true);
    };
    recordedVideoRef.current.onended = () => {
      setIsPlaybackPlaying(false);
    };
    recordedVideoRef.current.play();
    return;
  };

  const recordButtonClickHandler = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }

    if (recordingStatus === 'inactive' && !recordedVideo) {
      startRecording();
    } else if (recordingStatus === 'inactive' && recordedVideo) {
      restartStates();
      startRecording();
    } else {
      stopRecording();
    }
  };

  const recordButtonText = useMemo(() => {
    if (recordingStatus === 'inactive' && !recordedVideo) {
      return 'شروع ضبط';
    } else if (recordingStatus === 'inactive' && recordedVideo) {
      return 'ضبط مجدد';
    } else {
      return 'اتمام ضبط';
    }
  }, [recordedVideo, recordingStatus]);

  const uploadVideo = () => {};

  return (
    <Container maxWidth="xs" sx={{ p: 0 }}>
      <Box
        border={isSm ? `1px solid ${theme.palette.customColor.color3}` : 'none'}
        borderRadius={BORDER_RADIUS50}
        p={3.1}
      >
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          mb={12.35}
        >
          <Box width={128} height={34} bgcolor="#D9D9D9"></Box>
          <Box></Box>
        </Stack>
        <Typography textAlign="center" variant="t700_24" sx={{ mb: 3.8 }}>
          احراز هویت با ویدئو
        </Typography>
        <Stack alignItems="center" justifyContent="center" mb={4.7}>
          <Stack
            ref={circleRef}
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            width={310}
            height={310}
            position="relative"
            sx={{
              background: `radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(${
                isPlaybackPlaying ? '#df3131' : '#204d6c'
              } calc(var(--progress-value) * 1%), transparent 0)`,
              animationDuration: `${TOTAL_DURATION}s`,
              animationFillMode: 'forwards',
              animationName: 'videoRecorderProgress',
              animationPlayState: isPlaybackPlaying
                ? 'running'
                : recordingStatus === 'recording'
                ? 'running'
                : 'paused',
              animationTimingFunction: 'linear',
            }}
          >
            <Stack
              display={preparing ? 'flex' : 'none'}
              minWidth={295}
              minHeight={295}
              bgcolor="#D9D9D9"
              borderRadius="50%"
              alignItems="center"
              justifyContent="center"
              overflow="hidden"
            >
              <CircularProgress />
            </Stack>
            {recordingStatus === 'inactive' && recordedVideo && (
              <Stack
                onClick={startPlayback}
                position="absolute"
                bottom={0}
                left={45}
                width={55}
                height={55}
                border={1}
                alignItems="center"
                justifyContent="center"
                borderRadius={BORDER_RADIUS50}
                bgcolor="success.main"
                sx={{ cursor: 'pointer' }}
                zIndex={1}
              >
                <PlaySvg />
              </Stack>
            )}
            <Box
              display={!preparing ? 'block' : 'none'}
              width={295}
              height={295}
              bgcolor="#D9D9D9"
              borderRadius="50%"
              overflow="hidden"
            >
              {!recordedVideo && (
                <video
                  ref={liveVideoFeed}
                  autoPlay
                  style={{
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '50%',
                    transform: 'rotateY(180deg)',
                  }}
                />
              )}
              <video
                ref={recordedVideoRef}
                src={recordedVideo || ''}
                style={{
                  display: recordedVideo ? 'block' : 'none',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  transform: 'rotateY(180deg)',
                }}
              />
            </Box>
          </Stack>
        </Stack>
        <Typography
          variant="t400_16"
          mb={11}
          textAlign="center"
          maxWidth={190}
          mx="auto"
        >
          شروع ضبط را بزنید و این جمله را بخوانید: “این می‌تواند یک جمله مادبانه
          باشد!”
        </Typography>
        <Stack mx={4.3} rowGap={2}>
          <LoadingButton
            variant="contained"
            loading={preparing}
            fullWidth
            sx={{
              height: 70,
              fontSize: 16,
              fontWeight: 700,
            }}
            onClick={recordButtonClickHandler}
            color={recordingStatus === 'recording' ? 'error' : 'success'}
          >
            {recordButtonText}
          </LoadingButton>
          {!preparing && recordingStatus === 'inactive' && recordedVideo && (
            <Button
              fullWidth
              sx={{
                height: 70,
                fontSize: 16,
                fontWeight: 700,
              }}
              onClick={uploadVideo}
              color="success"
            >
              ارسال ویدئو
            </Button>
          )}
        </Stack>
      </Box>
    </Container>
  );
};
