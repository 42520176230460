import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const Gender: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.8334 16.125C17.6868 16.125 20 13.5227 20 10.3125C20 7.10234 17.6868 4.5 14.8334 4.5C11.9799 4.5 9.66669 7.10234 9.66669 10.3125C9.66669 13.5227 11.9799 16.125 14.8334 16.125Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M22.3334 1.875L18.6667 6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M18 1.875H22.3333V6.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M9 17.25C11.8995 17.25 14.25 14.6057 14.25 11.3438C14.25 8.08182 11.8995 5.4375 9 5.4375C6.10051 5.4375 3.75 8.08182 3.75 11.3438C3.75 14.6057 6.10051 17.25 9 17.25Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M9 17.25V22.3125"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M11.25 19.7812H6.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </SvgIcon>
  );
};
