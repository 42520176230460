/* eslint-disable jsx-a11y/iframe-has-title */
import { FC, MouseEventHandler } from 'react';
import { Dialog, DialogContent, DialogProps, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { LocationRo } from 'src/app/services/generatedApi';
import { Map } from './Map';

type MapDialogPropsType = {
  dialogProps: DialogProps;
  location?: LocationRo;
};

export const MapDialog: FC<MapDialogPropsType> = ({
  dialogProps,
  location,
}) => {
  const closeDialogHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (!dialogProps.onClose) return;
    dialogProps.onClose(event, 'escapeKeyDown');
  };

  return (
    <Dialog
      {...dialogProps}
      PaperProps={{
        sx: {
          borderRadius: 0,
          width: '100%',
          height: { xs: '100%', lg: '80vh' },
          m: { xs: 0, md: 'auto' },
          position: 'relative',
          maxHeight: 'fit-content',
        },
      }}
    >
      <DialogContent
        sx={{
          width: '100%',
          height: '100%',
          p: 0,
          overflow: 'hidden',
          border: '10px solid white',
        }}
      >
        <IconButton
          onClick={closeDialogHandler}
          sx={{
            position: 'absolute',
            top: 20,
            left: 20,
            bgcolor: 'error.main',
            backdropFilter: 'blur(10px)',
          }}
        >
          <Close sx={{ color: 'white' }} />
        </IconButton>
        <Map location={location} />
      </DialogContent>
    </Dialog>
  );
};
