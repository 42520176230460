import { FC, useState, useMemo } from 'react';
import { Typography } from '@mui/material';

type ShowMoreTextPropsType = {
  text: string;
};

export const ShowMoreText: FC<ShowMoreTextPropsType> = ({ text }) => {
  const [showCompleteDesc, setShowCompleteDesc] = useState<boolean>(false);

  const descIsMoreThan300Characters = useMemo(() => text.length > 300, [text]);

  const showMoreToggle = () => setShowCompleteDesc((prevState) => !prevState);

  return (
    <Typography textAlign="justify" variant="t400_14">
      {text.substring(0, showCompleteDesc ? text.length : 300)}
      <Typography
        variant="t700_14"
        onClick={showMoreToggle}
        component="span"
        sx={{ cursor: 'pointer', px: 1 }}
      >
        {descIsMoreThan300Characters
          ? showCompleteDesc
            ? 'مطالعه کمتر'
            : '... مطالعه بیشتر'
          : null}
      </Typography>
    </Typography>
  );
};
