import { FC } from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';
import { BORDER_RADIUS25 } from 'src/configs/theme';
import { price2Persian } from 'src/utils/price2Persian.util';
import { useProfile } from 'src/components/hooks/useProfile';
import { useAssetControllerGetCurrentUserAssetsValuationQuery } from 'src/app/services/generatedApi';

type AssetsSummaryPropsType = {};

export const AssetsSummary: FC<AssetsSummaryPropsType> = () => {
  const { profile } = useProfile();

  const { data: userAssetsValue = [], isLoading: getUserAssetsValue } =
    useAssetControllerGetCurrentUserAssetsValuationQuery();

  return (
    <Stack
      component={Stack}
      sx={{
        border: ({ palette }) => '1px solid ' + palette.customColor.color4,
      }}
      width="100%"
      borderRadius={BORDER_RADIUS25}
      rowGap={3}
      overflow="hidden"
      pt={2}
    >
      <Stack direction="row" justifyContent="space-between" px={1.9}>
        <Typography variant="t400_14" color="text.secondary">
          موجودی کیف پول
        </Typography>
        <Stack alignItems="end">
          <Typography variant="t700_14" color="text.secondary">
            {getUserAssetsValue ? (
              <Skeleton />
            ) : (
              price2Persian(profile?.walletBalance || 0)
            )}
          </Typography>
          <Typography variant="t700_14" color="text.secondary">
            تومان
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" px={1.9}>
        <Typography variant="t400_14" color="text.secondary">
          مجموع ارزش روز دارایی‌ها
        </Typography>
        <Stack alignItems="end">
          <Typography variant="t700_14" color="text.secondary">
            {getUserAssetsValue ? (
              <Skeleton />
            ) : (
              price2Persian(
                userAssetsValue.length > 0 ? userAssetsValue[0].totalValue : 0
              )
            )}
          </Typography>
          <Typography variant="t700_14" color="text.secondary">
            تومان
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        bgcolor="customColor.color2"
        py={2.4}
        px={1.9}
      >
        <Typography variant="t400_14">جمع کل</Typography>
        <Stack alignItems="end">
          <Typography variant="t700_14">
            {getUserAssetsValue ? (
              <Skeleton />
            ) : (
              price2Persian(
                userAssetsValue.length > 0 && profile
                  ? userAssetsValue[0].totalValue + profile.walletBalance
                  : 0
              )
            )}
          </Typography>
          <Typography variant="t700_14">تومان</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
