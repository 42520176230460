import { FC, useMemo, useState } from 'react';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { ShowMoreText } from 'src/components/atoms/ShowMoreText';
// import { BedSvg } from 'src/components/atoms/icons/BedSvg';
// import { BathtubSvg } from 'src/components/atoms/icons/BathtubSvg';
// import { WaterDropSvg } from 'src/components/atoms/icons/WaterDropSvg';
import { useWindowSize } from 'react-use';
import { VerificationSvg } from 'src/components/atoms/icons/VerificationSvg';
import { DocSvg } from 'src/components/atoms/icons/DocSvg';
import {
  BORDER_RADIUS15,
  BORDER_RADIUS25,
  BORDER_RADIUS50,
  customBreakpoints,
} from 'src/configs/theme';
import { Map } from '../../map/Map';
import { PinSvg } from 'src/components/atoms/icons/PinSvg';
import { useProject } from 'src/components/hooks/useProject';
import { MapDialog } from '../../map/MapDialog';

// enum ProjectFeaturesEnum {
//   BATHROOM,
//   BEDROOM,
//   WATER,
// }

const projectInfo = {
  guarantee: [
    'سیبیل اصغر قصاب',
    'سیبیل اصغر قصاب',
    'سیبیل اصغر قصاب',
    'سیبیل اصغر قصاب',
  ],
  documents: [
    'کاتالوگ پروژه',
    'تحلیل اقتصادی',
    'شرایط خرید و فروش',
    'سیبیل اصغر قصاب',
  ],
};

type DetailsPropsType = {};

export const Details: FC<DetailsPropsType> = () => {
  const [showDialog, setShowDialog] = useState(false);

  const { project } = useProject();

  // const featuresIconRenderHelper = useCallback((type: ProjectFeaturesEnum) => {
  //   switch (type) {
  //     case ProjectFeaturesEnum.BATHROOM:
  //       return <BathtubSvg />;
  //     case ProjectFeaturesEnum.BEDROOM:
  //       return <BedSvg />;
  //     case ProjectFeaturesEnum.WATER:
  //       return <WaterDropSvg fontSize="small" />;
  //     default:
  //       return <WaterDropSvg fontSize="small" />;
  //   }
  // }, []);

  const { width } = useWindowSize();

  const wrapperBoxSizeCalculator = useMemo(() => {
    let w = width - 65;
    if (width > customBreakpoints.md && width < customBreakpoints.lg) {
      w = width - 200;
    }
    if (width > customBreakpoints.lg && width < customBreakpoints.xl) {
      w = (width / 12) * 6 - 133;
    }
    if (width > customBreakpoints.xl) {
      w = (customBreakpoints.xl / 12) * 6 - 130;
    }
    return w;
  }, [width]);

  return (
    <>
      <Stack rowGap={2.5}>
        <ShowMoreText text={project?.detail || ''} />
        <Typography variant="t700_20">
          چه چیزهایی این سرمایه گذاری را ارزشمند می‌کند؟
        </Typography>
        <Box sx={{ overflowX: 'hidden', minHeight: 90 }}>
          <Typography variant="t700_16" gutterBottom>
            ویژگی‌های پروژه
          </Typography>
          <Box
            sx={{
              maxWidth: wrapperBoxSizeCalculator + 'px',
              overflowX: 'auto',
              pb: 1.5,
            }}
          >
            <Stack direction="row" alignItems="center" columnGap={1}>
              {project?.attributesFeatures.attributes.map((item, index) => {
                return (
                  <Chip
                    key={index}
                    sx={{
                      // p: 2,
                      height: 50,
                      borderRadius: BORDER_RADIUS50,
                    }}
                    label={item}
                    // icon={featuresIconRenderHelper(
                    //   ProjectFeaturesEnum[
                    //     type as keyof typeof ProjectFeaturesEnum
                    //   ]
                    // )}
                  />
                );
              })}
            </Stack>
          </Box>
        </Box>
        <Box sx={{ minHeight: 90 }}>
          <Typography variant="t700_16" gutterBottom>
            ویژگی‌های منطقه
          </Typography>
          <Box
            sx={{
              maxWidth: wrapperBoxSizeCalculator + 'px',
              overflow: 'auto',
              pb: 1.5,
            }}
          >
            <Stack direction="row" alignItems="center" columnGap={1}>
              {project?.attributesFeatures.features.map((item, index) => {
                return (
                  <Chip
                    key={index}
                    sx={{ height: 50, borderRadius: BORDER_RADIUS50 }}
                    label={item}
                    // icon={featuresIconRenderHelper(
                    //   ProjectFeaturesEnum[
                    //     type as keyof typeof ProjectFeaturesEnum
                    //   ]
                    // )}
                  />
                );
              })}
            </Stack>
          </Box>
        </Box>
        <Box sx={{ minHeight: 95 }}>
          <Typography variant="t700_20">ضمانت پروژه</Typography>
          {projectInfo.guarantee.map((item, index) => {
            return (
              <Chip
                key={index}
                label={item}
                sx={{
                  bgcolor: 'customColor.color5',
                  borderRadius: BORDER_RADIUS25,
                  height: 60,
                  px: 1.25,
                  my: 0.5,
                  mr: 1,
                }}
                icon={
                  <Box
                    sx={{
                      borderRadius: BORDER_RADIUS15,
                      bgcolor: 'success.main',
                      width: 40,
                      height: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <VerificationSvg svgProps={{ fontSize: 'small' }} />
                  </Box>
                }
              />
            );
          })}
        </Box>
        <Box sx={{ minHeight: 95 }}>
          <Typography variant="t700_20">اسناد پروژه</Typography>
          {project?.medias.map(({ filePath, name }, index) => {
            return (
              <Chip
                component="a"
                href={filePath}
                key={index}
                label={name}
                sx={{
                  bgcolor: 'customColor.color5',
                  borderRadius: BORDER_RADIUS25,
                  height: 60,
                  px: 1.25,
                  my: 0.5,
                  mr: 1,
                  cursor: 'pointer',
                }}
                icon={
                  <Box
                    sx={{
                      borderRadius: BORDER_RADIUS15,
                      bgcolor: 'success.main',
                      width: 40,
                      height: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <DocSvg fontSize="small" />
                  </Box>
                }
              />
            );
          })}
        </Box>
        <Stack rowGap={1}>
          <Typography variant="t700_20">محل پروژه</Typography>
          <Stack
            sx={{
              border: '1px solid #eee',
              borderRadius: BORDER_RADIUS25,
              overflow: 'hidden',
            }}
          >
            <Box sx={{ height: 250 }}>
              <Map location={project?.location} />
            </Box>
            <Button
              variant="text"
              size="large"
              sx={{
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomRightRadius: BORDER_RADIUS25,
                borderBottomLeftRadius: BORDER_RADIUS25,
                color: 'text.primary',
                fontSize: 12,
                fontWeight: 500,
                height: 47,
              }}
              onClick={() => setShowDialog(true)}
            >
              مشاهده روی نقشه
            </Button>
          </Stack>
          <Stack direction="row" columnGap={1} alignItems="center">
            <Stack
              width={50}
              height={50}
              alignItems="center"
              justifyContent="center"
              bgcolor="customColor.color2"
              borderRadius={BORDER_RADIUS50}
            >
              <PinSvg fontSize="small" />
            </Stack>

            <Typography variant="t400_14">
              {project?.location.address}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <MapDialog
        dialogProps={{
          open: showDialog,
          onClose: () => setShowDialog(false),
          maxWidth: 'lg',
        }}
        location={project?.location}
      />
    </>
  );
};
