import { FC, useState, MouseEvent } from 'react';
import {
  Avatar,
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PersonSvg } from 'src/components/atoms/icons/PersonSvg';
import { ExitSvg } from 'src/components/atoms/icons/ExitSvg';
import { SettingSvg } from 'src/components/atoms/icons/SettingSvg';
import { useProfile } from 'src/components/hooks/useProfile';
import { useAppDispatch } from 'src/app/reduxCustomHooks';
import { logoutAction } from 'src/app/slices/auth.slice';
import { useNavigate } from 'react-router-dom';

type ProfileMenuPropsType = {};

export const ProfileMenu: FC<ProfileMenuPropsType> = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = ({ currentTarget }: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'profilePopover' : undefined;

  const { profile } = useProfile();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profilePopoverItemsArray = [
    {
      text: 'حساب کاربری',
      Icon: PersonSvg,
      onClick: () => navigate('/profile'),
    },
    {
      text: 'تنظیمات',
      Icon: SettingSvg,
      onClick: () => navigate('/settings'),
    },
    {
      text: 'خروج',
      Icon: ExitSvg,
      onClick: () => dispatch(logoutAction()),
    },
  ];

  return (
    <>
      <Button
        onClick={handleClick}
        aria-label="profile menu"
        startIcon={
          <Avatar
            sx={{
              width: '25px',
              height: '25px',
            }}
          />
        }
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              filter: 'drop-shadow(0 0 1rem crimson) !important',
              mx: 1,
            }}
          />
        }
        sx={{
          color: 'text.primary',
          bgcolor: 'customColor.color1',
          borderRadius: '50px',
          py: 0.625,
          fontWeight: 400,
          fontSize: 14,
          '&:hover': {
            bgcolor: 'customColor.color1',
          },
        }}
      >
        {!profile?.firstName && !profile?.lastName && 'کاربر'}
        {`${profile?.firstName || ''} ${profile?.lastName || ''}`}
      </Button>
      <Popover
        elevation={0}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            minWidth: '130px',
            bgcolor: 'customColor.color1',
            backdropFilter: 'blur(10px)',
            mt: 0.8,
            py: 2,
            borderRadius: '25px',
          },
        }}
      >
        {profilePopoverItemsArray.map(({ text, Icon, onClick }, index) => {
          return (
            <ListItemButton key={index} onClick={onClick} sx={{ py: 0 }}>
              <ListItemIcon
                sx={{
                  minWidth: 28,
                }}
              >
                <Icon svgProps={{ fontSize: 'small' }} />
              </ListItemIcon>
              <ListItemText
                primary={text}
                primaryTypographyProps={{
                  fontSize: '14px',
                  color: index === 2 ? 'error.main' : 'text.primary',
                }}
              />
            </ListItemButton>
          );
        })}
      </Popover>
    </>
  );
};
