import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GuideButtonMobile = styled(Button)(() => ({
  backgroundColor: '#034F4D',
  color: 'white',
  width: '100%',
  fontSize: '25px',
  fontWeight: 600,
  py: 0.1,
}));
