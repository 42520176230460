import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BackArrow: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_410_4704)">
        <path
          d="M7.828 10.9997H20V12.9997H7.828L13.192 18.3637L11.778 19.7777L4 11.9997L11.778 4.22168L13.192 5.63568L7.828 10.9997Z"
          fill="#03053D"
        />
      </g>
      <defs>
        <clipPath id="clip0_410_4704">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
