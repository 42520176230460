import { TextField } from '@mui/material';
import { forwardRef } from 'react';
import { e2p, p2e } from 'src/utils/convertNumbers.util';

type CodeTextfieldPropsType = {
  goNext: () => void;
  goBack: () => void;
  value?: number;
  autoFocus: boolean;
  setValue: (value?: number) => void;
  loading?: boolean;
};

export const CodeTextfield = forwardRef<
  HTMLInputElement,
  CodeTextfieldPropsType
>(({ goNext, goBack, value, autoFocus, setValue, loading = false }, ref) => {
  return (
    <TextField
      autoFocus={autoFocus}
      inputRef={ref}
      type="text"
      disabled={loading}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        style: {
          textAlign: 'center',
        },
      }}
      sx={{
        width: '60px',
        height: '60px',
        borderRadius: '10px',
        backgroundColor: '#F5F4F8',
        '& fieldset': {
          border: 'none',
        },
        '& :focus': {
          border: '2px solid #234F68',
          borderRadius: '10px',
        },
      }}
      value={e2p(value === undefined ? '' : value)}
      onKeyUp={(e) => {
        const key = p2e(e.key);
        const isNumber = /^[0-9]$/i.test(key);
        if (key === 'Backspace' || e.keyCode === 8) {
          setValue(undefined);
          goBack();
        } else if (isNumber) {
          setValue(+key);
          goNext();
        } else {
          e.preventDefault();
        }
      }}
    />
  );
});
