import {
  Box,
  Button,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { GuideButtonMobile } from 'src/components/atoms/threeStepGuide/GuideButton';
import { GuideButtonDesktop } from 'src/components/atoms/threeStepGuide/GuideButtonDesktop';
import { GuideContentMobile } from 'src/components/atoms/threeStepGuide/GuideContent';
import { GuideContentDesktop } from 'src/components/atoms/threeStepGuide/GuideContentDesktop';
import { GuideNumberCircleMobile } from 'src/components/atoms/threeStepGuide/GuideNumberCircle';
import { GuideNumberCircleDesktop } from 'src/components/atoms/threeStepGuide/GuideNumberCircleDesktop';
import { GuideTitleMobile } from 'src/components/atoms/threeStepGuide/GuideTitle';
import { GuideTitleDesktop } from 'src/components/atoms/threeStepGuide/GuideTitleDesktop';

export const LandingGuideSection = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Box
      sx={{
        position: 'relative',
        background: '#034F4F',
      }}
    >
      <Box
        sx={{
          zIndex: 2,
          position: 'sticky',
          top: 0,
          height: 108,
          backgroundColor: '#034F4F',
        }}
      />
      <Box
        sx={{
          zIndex: 5,
          position: 'sticky',
          top: 108,
          bottom: 156,
          backgroundColor: '#034F4F',
        }}
      >
        <Stack
          sx={{
            borderTop: '1px solid black',
            borderRadius: '3rem 3rem 0 0',
            backgroundColor: '#F9F7F1',
            height: 78,
            p: '1rem 1.5rem',
            position: 'sticky',
          }}
          direction="row"
          alignItems="center"
          spacing={2}
        >
          {isMobile ? (
            <GuideNumberCircleMobile>1</GuideNumberCircleMobile>
          ) : (
            <GuideNumberCircleDesktop>1</GuideNumberCircleDesktop>
          )}
          {isMobile ? (
            <GuideTitleMobile>اکانت خودت رو بساز</GuideTitleMobile>
          ) : (
            <GuideTitleDesktop>اکانت خودت رو بساز</GuideTitleDesktop>
          )}
        </Stack>
      </Box>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          backgroundColor: '#F9F7F1',
          p: '1rem 1.5rem',
          width: '100%',
          zIndex: 2,
        }}
        justifyContent="space-around"
        alignItems="center"
      >
        <Stack
          spacing={4}
          order={{ xs: 2, md: 1 }}
          width={{ xs: '100%', md: '65%' }}
        >
          {isMobile ? (
            <GuideContentMobile>
              در کمتر از 1 دقیقه اکانتت رو بساز و سرمایه‌گذاری رو شروع کن
            </GuideContentMobile>
          ) : (
            <GuideContentDesktop>
              در کمتر از 1 دقیقه اکانتت رو بساز و سرمایه‌گذاری رو شروع کن
            </GuideContentDesktop>
          )}
          {isMobile ? (
            <GuideButtonMobile variant="contained">
              ثبت‌نام کن
            </GuideButtonMobile>
          ) : (
            <GuideButtonDesktop variant="contained">
              ثبت‌نام کن
            </GuideButtonDesktop>
          )}
        </Stack>
        <Box
          order={{ xs: 1, md: 2 }}
          width={{ xs: '100%', md: '35%' }}
          sx={{ textAlign: 'center' }}
        >
          <img src="/images/avatar01.png" alt="avatar01" />
        </Box>
      </Stack>
      <Box
        sx={{
          zIndex: 4,
          position: 'sticky',
          top: 186,
          bottom: 78,
          backgroundColor: '#F9F7F1',
        }}
      >
        <Stack
          sx={{
            borderTop: '1px solid black',
            borderRadius: '3rem 3rem 0 0',
            backgroundColor: '#F9F7F1',
            height: '78px',
            p: '1rem 1.5rem',
            position: 'sticky',
          }}
          direction="row"
          alignItems="center"
          spacing={2}
        >
          {isMobile ? (
            <GuideNumberCircleMobile>2</GuideNumberCircleMobile>
          ) : (
            <GuideNumberCircleDesktop>2</GuideNumberCircleDesktop>
          )}
          {isMobile ? (
            <GuideTitleMobile>به سبک خودت سرمایه‌گذاری کن</GuideTitleMobile>
          ) : (
            <GuideTitleDesktop>به سبک خودت سرمایه‌گذاری کن</GuideTitleDesktop>
          )}
        </Stack>
      </Box>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          backgroundColor: '#F9F7F1',
          p: '1rem 1.5rem',
          width: '100%',
          zIndex: 2,
        }}
        justifyContent="space-around"
        alignItems="center"
      >
        <Stack
          spacing={4}
          order={{ xs: 2, md: 1 }}
          width={{ xs: '100%', md: '65%' }}
        >
          {isMobile ? (
            <GuideContentMobile>
              بلند مدت یا کوتاه مدت، با هر میزان سرمایه که داری و با هر استراتژی
              که میپسندی روی پروژه ها سرمایه گذاری کن
            </GuideContentMobile>
          ) : (
            <GuideContentDesktop>
              بلند مدت یا کوتاه مدت، با هر میزان سرمایه که داری و با هر استراتژی
              که میپسندی روی پروژه ها سرمایه گذاری کن
            </GuideContentDesktop>
          )}
          {isMobile ? (
            <GuideButtonMobile variant="contained">
              سرمایه‌گذاری کن
            </GuideButtonMobile>
          ) : (
            <GuideButtonDesktop variant="contained">
              سرمایه‌گذاری کن
            </GuideButtonDesktop>
          )}
        </Stack>
        <Box
          order={{ xs: 1, md: 2 }}
          width={{ xs: '100%', md: '35%' }}
          sx={{ textAlign: 'center' }}
        >
          <img src="/images/avatar02.png" alt="avatar02" />
        </Box>
      </Stack>
      <Box
        sx={{
          zIndex: 3,
          position: 'sticky',
          top: 264,
          bottom: 0,
          backgroundColor: '#F9F7F1',
        }}
      >
        <Stack
          sx={{
            borderTop: '1px solid black',
            borderRadius: '3rem',
            backgroundColor: '#F9F7F1',
            height: '78px',
            p: '1rem 1.5rem',
            position: 'sticky',
          }}
          direction="row"
          alignItems="center"
          spacing={2}
        >
          {isMobile ? (
            <GuideNumberCircleMobile>3</GuideNumberCircleMobile>
          ) : (
            <GuideNumberCircleDesktop>3</GuideNumberCircleDesktop>
          )}
          {isMobile ? (
            <GuideTitleMobile>دارایی های خودت رو مدیریت کن</GuideTitleMobile>
          ) : (
            <GuideTitleDesktop>دارایی های خودت رو مدیریت کن</GuideTitleDesktop>
          )}
        </Stack>
      </Box>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          backgroundColor: '#F9F7F1',
          p: '1rem 1.5rem',
          width: '100%',
          borderRadius: '0 0 3rem 3rem',
          zIndex: 2,
        }}
        justifyContent="space-around"
        alignItems="center"
      >
        <Stack
          spacing={4}
          order={{ xs: 2, md: 1 }}
          width={{ xs: '100%', md: '65%' }}
        >
          {isMobile ? (
            <GuideContentMobile>
              با سرمایه گذاری روی انواع پروژه ها ، درآمد و سودت رو مشاهده و
              مدیریت کن.
            </GuideContentMobile>
          ) : (
            <GuideContentDesktop>
              با سرمایه گذاری روی انواع پروژه ها ، درآمد و سودت رو مشاهده و
              مدیریت کن.
            </GuideContentDesktop>
          )}
          {isMobile ? (
            <GuideButtonMobile variant="contained">
              ثبت نام کن
            </GuideButtonMobile>
          ) : (
            <GuideButtonDesktop variant="contained">
              ثبت نام کن
            </GuideButtonDesktop>
          )}
        </Stack>
        <Box
          order={{ xs: 1, md: 2 }}
          width={{ xs: '100%', md: '35%' }}
          sx={{ textAlign: 'center' }}
        >
          <img src="/images/avatar03.png" alt="avatar03" />
        </Box>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          zIndex: 10,
          width: '100%',
          position: 'absolute',
          transform: 'translateY(-100%)',
        }}
      >
        <Box
          sx={{
            width: '3rem',
            height: '3rem',
            backgroundColor: '#034F4F',
            maskImage:
              'radial-gradient(3rem at 3rem calc(100% - 3rem), transparent 100%, black 100%)',
            transform: 'scaleX(-1)',
          }}
        />
        <Box
          sx={{
            width: '3rem',
            height: '3rem',
            backgroundColor: '#034F4F',
            maskImage:
              'radial-gradient(3rem at 3rem calc(100% - 3rem), transparent 100%, black 100%)',
          }}
        />
      </Stack>
    </Box>
  );
};
