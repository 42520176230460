import { Box, Container, Theme, useMediaQuery } from '@mui/material';
import { DesktopFooter } from 'src/components/molecules/landing/DesktopFooter';
import { LandingNavBar } from 'src/components/molecules/landing/LandingNavBar';
import { LandingBlogSection } from 'src/components/molecules/landing/LandingBlogSection';
import { LandingCommentSlider } from 'src/components/molecules/landing/LandingCommentSlider';
import { LandingFAQ } from 'src/components/molecules/landing/LandingFAQ';
import { LandingHomePage } from 'src/components/molecules/landing/LandingHomePage';
import { LandingGuideSection } from 'src/components/molecules/landing/LandingGuideSection';
import { LandingIntroSection } from 'src/components/molecules/landing/LandingIntroSection';
import { LandingSlider } from 'src/components/molecules/landing/LandingSlider';
import { LandingCustomerLogo } from 'src/components/molecules/landing/LandingCustomerLogo';
import { LandingEmailJoin } from 'src/components/molecules/landing/LandingEmailJoin';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const DesktopLanding = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      const id = window.location.hash.replace('#', '');

      const element = document.getElementById(id);
      if (element) {
        const yOffset = -100;
        const y = Math.max(
          0,
          element.getBoundingClientRect().top + window.pageYOffset + yOffset
        );

        window.scrollTo({ top: y, behavior: 'smooth' });
        window.history.pushState(
          '',
          document.title,
          window.location.pathname + window.location.search
        );
      }
    }, 100);
  }, [location]);

  return (
    <Box sx={{ backgroundColor: '#034F4F' }}>
      <LandingNavBar />
      <Box sx={{ position: 'relative' }}>
        <Container maxWidth="xl">
          <LandingHomePage />
        </Container>
        {!isMobile && (
          <Box sx={{ position: 'absolute', bottom: -20, width: '100%' }}>
            <LandingCustomerLogo />
          </Box>
        )}
      </Box>
      <Container maxWidth="xl" sx={{ mt: 3 }}>
        <LandingIntroSection />
        <LandingSlider />
        <LandingGuideSection />
        <LandingBlogSection />
      </Container>
      <LandingFAQ />
      <Container maxWidth="xl">
        <LandingEmailJoin />
      </Container>
      <LandingCommentSlider />
      <DesktopFooter />
    </Box>
  );
};
