import { LoadingButton } from '@mui/lab';
import { Box, InputAdornment, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { toast } from 'react-toastify';
import { useAuthControllerSendOtpMutation } from 'src/app/services/generatedApi';
import { Call } from 'src/components/atoms/icons/Call';
import { MelkTextField } from 'src/components/atoms/MelkTextField';
import { e2p, p2e } from 'src/utils/convertNumbers.util';
import * as yup from 'yup';
import { AuthContentPropsType } from './AuthContent';

export const AuthNumber: FC<AuthContentPropsType> = ({
  changePage,
  setPhoneNumber,
}) => {
  const [sendOtp, { isLoading }] = useAuthControllerSendOtpMutation();

  const formik = useFormik({
    initialValues: { phoneNumber: '' },
    validationSchema: yup.object({
      phoneNumber: yup
        .string()
        .required('وارد کردن شماره تلفن الزامی می‌باشد.')
        .matches(/^\d+$/, 'شماره تلفن باید عدد باشد.')
        .matches(/^09/, 'شماره تلفن باید با ۰۹ شروع شود.')
        .matches(/\d{11}$/, 'شماره تلفن باید ۱۱ رقم باشد.'),
    }),
    onSubmit: (values) => {
      sendOtp({ phoneNumberDto: { phoneNumber: values.phoneNumber } })
        .unwrap()
        .then(() => {
          setPhoneNumber(values.phoneNumber);
          changePage(1);
        })
        .catch(() => {
          toast.error('دوباره تلاش کنید.');
        });
    },
  });

  const isPhoneNumberFilled = formik.values.phoneNumber !== '';
  const ifErrors = formik.touched.phoneNumber && !!formik.errors.phoneNumber;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ width: '100%' }}>
        <img src="/images/login.png" alt="login" style={{ width: '100%' }} />
      </Box>
      <Stack p={4} spacing={2}>
        <Typography variant="h4" sx={{ color: '#204D6C', fontWeight: 'bold' }}>
          وارد شوید
        </Typography>
        <Typography sx={{ fontSize: '18px' }}>
          لطفا شماره تلفن خود را وارد کنید.
        </Typography>
        <Stack sx={{ height: '160px' }} spacing={3}>
          {ifErrors && (
            <Box
              sx={{
                backgroundColor: '#DF3131',
                borderRadius: '10px',
                p: 2,
                fontSize: '18px',
                color: 'white!important',
              }}
            >
              شماره تلفن اشتباه وارد شده است.
            </Box>
          )}
          <Box
            sx={{
              backgroundImage: ifErrors
                ? 'radial-gradient(116.28% 116.28% at 0% -16.28%, #FF3939 4.69%, #FFC2AE 98.31%)'
                : ' #F5F4F8',
              borderRadius: '10px',
              p: '2px',
            }}
          >
            <MelkTextField
              placeholder="۰۹۱۹۹۹۳۰۷۳۹"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Call sx={{ stroke: '#252B5C' }} />
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps('phoneNumber')}
              value={e2p(formik.values.phoneNumber)}
              onChange={(e) => {
                formik.setFieldValue('phoneNumber', p2e(e.target.value, true));
              }}
              error={Boolean(
                formik.touched.phoneNumber && !!formik.errors.phoneNumber
              )}
            />
          </Box>
        </Stack>
      </Stack>
      {isPhoneNumberFilled && (
        <Box sx={{ px: 7 }}>
          <LoadingButton
            loading={isLoading}
            type="submit"
            sx={{
              backgroundColor: '#37A835',
              borderRadius: '10px',
              fontSize: '25px',
              fontWeight: 800,
              py: '10px',
            }}
            fullWidth
            variant="contained"
          >
            ورود
          </LoadingButton>
        </Box>
      )}
    </form>
  );
};
