import { useParams } from 'react-router-dom';
import {
  ProjectRo,
  useProjectControllerFindProjectByIdQuery,
} from 'src/app/services/generatedApi';

type useProjectType = (id?: string) => {
  project: ProjectRo | null;
  isLoading: boolean;
};

export const useProject: useProjectType = (id) => {
  const { projectId } = useParams();

  const { data: project, isLoading } = useProjectControllerFindProjectByIdQuery(
    { id: id! || projectId! },
    { skip: !projectId && !id }
  );

  return {
    project: project || null,
    isLoading,
  };
};
