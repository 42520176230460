import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export type BasketSvgPropsType = {
  svgProps?: SvgIconProps;
  isSelected?: boolean;
};

export const BasketSvg: FC<BasketSvgPropsType> = ({ svgProps, isSelected }) => {
  return (
    <SvgIcon
      width="37"
      height="37"
      viewBox="0 0 37 37"
      {...svgProps}
      sx={{
        fill: 'none',
        ...svgProps?.sx,
      }}
    >
      {isSelected ? (
        <path
          d="M17.4835 0C18.1337 0 18.7839 0 19.4341 0C19.8113 0.0538835 20.1956 0.0862136 20.5692 0.16165C26.2737 1.26447 30.024 4.5765 31.7842 10.1157C32.0105 10.8306 32.1183 11.5814 32.2835 12.3321C32.3769 12.3357 32.4703 12.3429 32.5673 12.3465C34.5789 12.4291 36.2637 13.7475 36.8169 15.6765C36.8852 15.9172 36.939 16.165 37.0001 16.4093V17.4942C36.9211 17.9899 36.8349 18.4856 36.763 18.985C36.2888 22.2826 35.7859 25.5767 35.3477 28.8816C34.8591 32.578 32.1793 35.7104 28.5691 36.648C27.7968 36.8491 26.9814 36.9749 26.1839 36.9784C21.0542 37.0072 15.9209 37.0144 10.7912 36.9856C7.92098 36.9677 5.54292 35.8182 3.67855 33.6341C2.3602 32.0894 1.76748 30.2538 1.50525 28.2637C1.09574 25.1277 0.603599 21.9988 0.183308 18.8628C0.0611721 17.9612 -0.0717406 17.0236 0.0503954 16.1363C0.309036 14.2145 1.82137 12.774 3.73962 12.4219C4.04496 12.3645 4.35389 12.3465 4.63049 12.3142C4.77418 11.6281 4.86399 10.9563 5.05797 10.3133C6.63855 5.02194 10.1051 1.68476 15.4647 0.341262C16.1256 0.176019 16.8118 0.111359 17.4871 0H17.4835ZM29.0864 12.3178C28.5296 7.5868 24.1255 2.81631 17.9253 3.10369C12.1634 3.36951 8.27661 8.02505 7.8204 12.3178H29.0864Z"
          fill="#204D6C"
        />
      ) : (
        <path
          d="M36.9998 16.4057V17.4906C36.9424 17.8211 36.8813 18.1516 36.8346 18.482C36.3353 21.9701 35.8719 25.4689 35.3259 28.9498C34.7403 32.6749 32.6281 35.1931 29.0897 36.5043C28.0983 36.8707 27.0565 36.9964 26.004 36.9964C20.9929 36.9964 15.9817 37.0036 10.9741 36.9964C8.03567 36.9928 5.59295 35.8756 3.67829 33.6413C2.35635 32.0966 1.76363 30.261 1.50499 28.2709C1.09547 25.1205 0.599744 21.9845 0.179453 18.8341C0.0609092 17.9324 -0.0755956 16.9913 0.0609092 16.1112C0.391395 13.963 2.16955 12.4722 4.34285 12.3573C4.58353 12.3429 4.66256 12.2747 4.70207 12.0304C4.80266 11.4017 4.89605 10.7659 5.08285 10.1588C6.70654 4.9501 10.1623 1.65961 15.4644 0.341262C16.1254 0.176019 16.8115 0.111359 17.4832 0C18.1334 0 18.7836 0 19.4338 0C19.811 0.0538835 20.1954 0.0862136 20.569 0.16165C26.2734 1.26447 30.0237 4.5801 31.7839 10.1157C32.0102 10.8306 32.118 11.585 32.2832 12.3321C32.3766 12.3357 32.47 12.3429 32.567 12.3465C34.5787 12.4291 36.2634 13.7475 36.8166 15.6765C36.8849 15.9172 36.9388 16.165 36.9998 16.4093V16.4057ZM18.4675 33.9035C20.9964 33.9035 23.5254 33.9035 26.0543 33.9035C26.0795 33.9035 26.101 33.9035 26.1262 33.9035C29.3125 33.8783 31.8701 31.5901 32.3012 28.4325C32.8005 24.7613 33.3537 21.0972 33.8566 17.4259C33.9105 17.038 33.8674 16.5925 33.7165 16.2369C33.4435 15.5939 32.8544 15.3963 32.1719 15.3963C23.044 15.4035 13.9162 15.3999 4.78829 15.3999C4.71644 15.3999 4.6446 15.3999 4.57275 15.3999C3.61722 15.4322 2.93829 16.2117 3.04605 17.1637C3.08916 17.5337 3.15023 17.9037 3.20411 18.2701C3.67829 21.5929 4.1812 24.9121 4.62304 28.2385C5.07567 31.6404 7.59741 33.8999 11.0316 33.8999C13.5138 33.8999 15.9925 33.8999 18.4747 33.8999L18.4675 33.9035ZM7.81295 12.3142H29.1041C28.5329 7.55087 24.0642 2.71932 17.7419 3.11447C11.9261 3.47728 8.21528 8.24058 7.81295 12.3142Z"
          fill="#204D6C"
        />
      )}
    </SvgIcon>
  );
};
