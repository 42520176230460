import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GuideNumberCircleDesktop = styled(Box)(() => ({
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  border: '3px solid #034F4F',
  color: 'black',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  fontSize: '28px',
  fontWeight: 400,
}));
