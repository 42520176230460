import { Box, Container, Stack, Theme, useMediaQuery } from '@mui/material';
import { FAQContent } from './FAQContent';

export const LandingFAQ = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <Box
      id="FrequentQuestions"
      sx={{ backgroundColor: '#CAFFA8', py: 10, position: 'relative' }}
    >
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-around">
          {!isMobile && (
            <Box sx={{ width: '30%' }}>
              <img
                src="./images/Rectangle.png"
                alt="phone-screen"
                style={{
                  overflow: 'hidden',
                  position: 'absolute',
                  bottom: -30,
                  height: 700,
                }}
              />
            </Box>
          )}
          <Stack
            sx={{
              width: { xs: '100%', md: '50%' },
              justifyContent: 'center',
            }}
          >
            <FAQContent />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
