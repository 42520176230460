import { FC } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';

export const CustomIconButton: FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      {...props}
      sx={{
        bgcolor: 'customColor.color1',
        '&:hover': { bgcolor: 'customColor.color1' },
        ...props?.sx,
      }}
    >
      {props.children}
    </IconButton>
  );
};
