import { useEffect } from 'react';
import { CacheProvider } from '@emotion/react';
import { Router } from 'src/routes/Router';
import { ToastContainer } from 'react-toastify';
import createEmotionCache from './createEmotionCache';
import { CssBaseline, ThemeProvider, responsiveFontSizes } from '@mui/material';
import { useAppDispatch, useAppSelector } from './app/reduxCustomHooks';
import { isExpiredJwt } from './utils/jwt.util';
import { logoutAction } from './app/slices/auth.slice';

import 'react-toastify/dist/ReactToastify.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'src/assets/styles/globals.css';
import theme from './configs/theme';

export const App = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((store) => store.auth.token);

  useEffect(() => {
    if (!token) return;
    const expired = isExpiredJwt(token);
    if (expired) {
      dispatch(logoutAction());
    }
  }, [dispatch, token]);

  return (
    <CacheProvider value={createEmotionCache()}>
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        <Router />
        <ToastContainer rtl />
      </ThemeProvider>
    </CacheProvider>
  );
};
