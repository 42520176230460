import { Box, Stack } from '@mui/material';

const customerLogos = [
  { title: 'لوگو مشتری' },
  { title: 'لوگو مشتری' },
  { title: 'لوگو مشتری' },
  { title: 'لوگو مشتری' },
  { title: 'لوگو مشتری' },
];

export const LandingCustomerLogo = () => {
  return (
    <Stack
      direction="row"
      py={4}
      spacing={3}
      justifyContent="center"
      sx={{
        backgroundColor: '#034F4F',
        borderTop: '1px solid #1D605E',
        borderBottom: '1px solid #1D605E',
      }}
    >
      {customerLogos.map((customerLogo, index) => {
        return (
          <Box
            key={index}
            sx={{
              borderRadius: '12px',
              backgroundColor: '#D9D9D9',
              px: 3,
              py: 1,
            }}
          >
            {customerLogo.title}
          </Box>
        );
      })}
    </Stack>
  );
};
