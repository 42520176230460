import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PinSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="16"
      height="19"
      viewBox="0 0 16 19"
      {...props}
      sx={{
        fill: 'none',
        ...props?.sx,
      }}
    >
      <path
        d="M14.3996 7.33268C14.3996 11.0143 7.99961 17.3327 7.99961 17.3327C7.99961 17.3327 1.59961 11.0143 1.59961 7.33268C1.59961 5.56457 2.27389 3.86888 3.47413 2.61864C4.67436 1.36839 6.30222 0.666016 7.99961 0.666016C9.697 0.666016 11.3249 1.36839 12.5251 2.61864C13.7253 3.86888 14.3996 5.56457 14.3996 7.33268V7.33268Z"
        stroke="#53587A"
        strokeWidth="1.25"
      />
      <path
        d="M7.99955 8C8.25416 8 8.49834 7.89464 8.67837 7.70711C8.85841 7.51957 8.95955 7.26522 8.95955 7C8.95955 6.73478 8.85841 6.48043 8.67837 6.29289C8.49834 6.10536 8.25416 6 7.99955 6C7.74494 6 7.50076 6.10536 7.32073 6.29289C7.14069 6.48043 7.03955 6.73478 7.03955 7C7.03955 7.26522 7.14069 7.51957 7.32073 7.70711C7.50076 7.89464 7.74494 8 7.99955 8Z"
        fill="#53587A"
        stroke="#53587A"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
