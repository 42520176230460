import { Menu } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from '@mui/material';
import { cloneElement } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/app/reduxCustomHooks';

function ElevationScroll(props: { children: any }) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export const LandingNavBar = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const navigate = useNavigate();

  const token = useAppSelector((state) => state.auth.token);

  const loginHandler = () => {
    if (token) {
      navigate('/dashboard/projects');
      return;
    } else {
      navigate('/projectTour');
    }
  };

  return (
    <>
      <ElevationScroll>
        <AppBar sx={{ backgroundColor: '#034F4F' }}>
          <Container maxWidth="xl" sx={{ p: '0 !important' }}>
            <Stack
              direction="row"
              width="100%"
              py={2}
              px={{ xs: 2, md: 6 }}
              sx={{ color: 'white' }}
              spacing={4}
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  sx={{
                    backgroundColor: '#D9D9D9',
                    width: '25px',
                    height: '25px',
                    transform: 'rotate(45deg)',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: { xs: '24px', md: '32px' },
                    fontWeight: { xs: 700, md: 800 },
                  }}
                >
                  بلوک
                </Typography>
              </Stack>
              {isMobile ? (
                <IconButton>
                  <Menu sx={{ color: '#CAFFA8', fontSize: 35 }} />
                </IconButton>
              ) : (
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  width="100%"
                >
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="text"
                      sx={{
                        color: 'white',
                        fontSize: { lg: 20, md: 15 },
                        fontWeight: 400,
                        py: 0.8,
                      }}
                    >
                      همکاران ما
                    </Button>
                    <Button
                      variant="text"
                      sx={{
                        color: 'white',
                        fontSize: { lg: 20, md: 15 },
                        fontWeight: 400,
                        py: 0.8,
                      }}
                      component={Link}
                      to="/dashboard/blog"
                    >
                      بلاگ
                    </Button>
                    <Button
                      variant="text"
                      sx={{
                        color: 'white',
                        fontSize: { lg: 20, md: 15 },
                        fontWeight: 400,
                        py: 0.8,
                      }}
                      component={Link}
                      to="/#FrequentQuestions"
                    >
                      سوالات پرتکرار
                    </Button>
                    <Button
                      variant="text"
                      sx={{
                        color: 'white',
                        fontSize: { lg: 20, md: 15 },
                        fontWeight: 400,
                        py: 0.8,
                      }}
                    >
                      تماس با ما
                    </Button>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button
                      variant="outlined"
                      sx={{
                        color: '#CAFFA8',
                        border: '1px solid #CAFFA8',
                        borderRadius: '10px',
                        fontSize: '20px',
                        fontWeight: 500,
                        py: 0.6,
                        width: 88,
                      }}
                      onClick={loginHandler}
                    >
                      ورود
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: '#CAFFA8',
                        color: '#034F4F',
                        fontSize: '20px',
                        fontWeight: 500,
                        py: 0.6,
                        width: 114,
                      }}
                    >
                      خرید
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Container>
        </AppBar>
      </ElevationScroll>
    </>
  );
};
