import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import Wallet from '../pages/Wallet';
import Transactions from '../pages/Transactions';
import Blog from '../pages/blog/Blog';
import Assets from '../pages/Assets';
import Projects from '../pages/project/Projects';
import MainTemplate, {
  MainTemplatePropsType,
} from 'src/components/templates/MainTemplate';
import { Profile } from 'src/pages/Profile';
import ProjectSinglePage from 'src/pages/project/ProjectSinglePage';
import { CreditCardPage } from 'src/pages/creditCard/CreditCardPage';
import { DesktopLanding } from 'src/pages/DesktopLanding';
import { Login } from 'src/pages/login/DesktopLogin';
import Notfound from 'src/pages/notfound';
import Validation from 'src/pages/Validation';
import Policy from 'src/pages/Policy';
import LoginTemplate, {
  LoginTemplatePropsType,
} from 'src/components/templates/LoginTemplate';
import { BlogContent } from 'src/pages/blog/BlogContent';
import { AddCreditCardPage } from 'src/pages/creditCard/AddCreditCardPage';
import { DesktopProjectTour } from 'src/pages/DesktopProjectTour';

type mainTemplateType = (
  PageComponent: FC,
  templateProps?: MainTemplatePropsType
) => JSX.Element;

type loginTemplateType = (
  PageComponent: FC,
  templateProps?: LoginTemplatePropsType
) => JSX.Element;

const mainTemplate: mainTemplateType = (PageComponent, templateProps) => (
  <MainTemplate {...templateProps}>
    <PageComponent />
  </MainTemplate>
);

const loginTemplate: loginTemplateType = (PageComponent, templateProps) => (
  <LoginTemplate {...templateProps}>
    <PageComponent />
  </LoginTemplate>
);

export const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/dashboard">
          <Route path="/dashboard/policy" element={loginTemplate(Policy)} />
          <Route
            path="/dashboard/validation"
            element={loginTemplate(Validation)}
          />
          <Route path="/dashboard/wallet" element={mainTemplate(Wallet)} />
          <Route
            path="/dashboard/transactions"
            element={mainTemplate(Transactions)}
          />
          <Route
            path="/dashboard/transactions/:transactionId"
            element={mainTemplate(Transactions, { showBackButton: true })}
          />
          <Route path="/dashboard/blog" element={mainTemplate(Blog)} />
          <Route
            path="/dashboard/blog/:blogId"
            element={mainTemplate(Blog, { showBackButton: true })}
          />
          <Route path="/dashboard/assets" element={mainTemplate(Assets)} />
          <Route path="/dashboard/projects" element={mainTemplate(Projects)} />
          <Route
            path="/dashboard/projects/:projectId"
            element={mainTemplate(ProjectSinglePage, {
              showBackButton: true,
              hideFooter: true,
            })}
          />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/projectTour" element={<DesktopProjectTour />} />
        <Route path="/" element={<DesktopLanding />} />
        <Route path="/blogContent" element={<BlogContent />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/creditCard" element={<CreditCardPage />} />
        <Route path="/addCreditCard" element={<AddCreditCardPage />} />
        <Route element={<PrivateRoute />}></Route>
        <Route element={<PrivateRoute isAdmin />}></Route>
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
};
