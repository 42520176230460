import { Box } from '@mui/material';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useScroll } from 'react-use';
import { CycleSelectItem } from './CycleSelectItem';

export const CycleSelect: FC<{
  onChange: (index: string) => void;
  items: string[];
  initIndex: number;
}> = ({ onChange, items, initIndex }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { y } = useScroll(scrollRef);

  useEffect(() => {
    if (!scrollRef.current) return;
    scrollRef.current.scrollTop = initIndex * 50;
  }, [initIndex]);

  useEffect(() => {
    const fitPositions = () => {
      if (!scrollRef.current) return;
      scrollRef.current.scrollTop =
        Math.round(scrollRef.current.scrollTop / 50) * 50;
    };
    scrollRef.current?.addEventListener('scrollend', fitPositions);

    return () => {
      scrollRef.current?.removeEventListener('scrollend', fitPositions);
    };
  }, []);

  const selectedIndex = useMemo(() => Math.round(y / 50), [y]);

  useEffect(() => {
    onChange(items[selectedIndex]);
  }, [selectedIndex, items, onChange]);

  return (
    <>
      <Box
        sx={{
          overflow: 'scroll',
          maxHeight: 250,
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        ref={scrollRef}
      >
        <Box sx={{ height: 100 }} />
        {items.map((item, index) => {
          if (Math.abs(index - Math.round(y / 50)) > 20)
            return <div style={{ height: '50px' }}></div>;
          return (
            <CycleSelectItem
              label={item}
              diffFromSelected={Math.round(y / 50) - index}
              onClick={() => {
                if (!scrollRef.current) return;
                scrollRef.current.scrollTop = index * 50;
              }}
            />
          );
        })}
        <Box sx={{ height: 100 }} />
      </Box>
    </>
  );
};
