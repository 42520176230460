import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LocationSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="38"
      height="45"
      viewBox="0 0 38 45"
      {...props}
      sx={{
        fill: 'none',
        ...props?.sx,
      }}
    >
      <path
        d="M20.4662 44.3214H17.5139C17.4197 44.2886 17.3296 44.2518 17.2314 44.2313C14.7827 43.7317 12.805 42.4829 11.3677 40.4478C9.8117 38.2407 8.2598 36.0296 6.78979 33.7652C4.86936 30.8088 3.14139 27.7377 1.85154 24.4456C1.07764 22.4555 0.467526 20.4286 0.500283 18.2666C0.55761 14.6714 1.5772 11.367 3.54267 8.34507C7.62102 2.07603 15.5239 -1.24479 22.7592 0.429953C29.9824 2.1047 34.6913 6.55977 36.8165 13.6559C37.6395 16.3953 37.7583 19.192 36.9598 21.9846C36.3783 24.0197 35.5594 25.9565 34.6053 27.836C32.2836 32.4098 29.4255 36.6438 26.379 40.7549C25.257 42.2699 23.7584 43.3059 21.9649 43.8996C21.4735 44.0634 20.9699 44.1781 20.4703 44.3173L20.4662 44.3214ZM11.5888 18.4263C11.5683 22.5047 14.8851 25.8214 19.0003 25.8378C23.0336 25.8582 26.3749 22.5374 26.3913 18.4918C26.4077 14.3971 23.1237 11.0558 19.0658 11.0394C14.9178 11.0271 11.6093 14.2906 11.5888 18.4222V18.4263Z"
        fill="#204D6C"
      />
    </SvgIcon>
  );
};
