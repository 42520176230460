import { Box, Dialog, Theme, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { onboardPages } from './constant';

export const OnboardDialog: FC<{
  open: boolean;
  handleClose: () => void;
  flatMode?: boolean;
}> = ({ open, handleClose, flatMode }) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const [pageNumber, setPageNumber] = useState(0);

  const OnboardContent = onboardPages[pageNumber].component;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="xs"
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          ...(flatMode && {
            boxShadow: 'unset',
            border: '1px solid #204D6C',
            borderRadius: '50px',
          }),
        },
      }}
      BackdropProps={{
        sx: {
          ...(flatMode && {
            background: 'white',
          }),
        },
      }}
    >
      <Box
        sx={{
          height: isMobile ? '100vh' : '80vh',
          maxHeight: isMobile ? '100vh' : '900px',
        }}
      >
        <OnboardContent
          changePage={(page: number) => setPageNumber(page)}
          handleCloseDialog={handleClose}
        />
      </Box>
    </Dialog>
  );
};
