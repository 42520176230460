import { Box, IconButton, Stack, Typography } from '@mui/material';
import { DesktopTemplate } from 'src/components/templates/DesktopTemplate';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ShareIcon from '@mui/icons-material/Share';

export const BlogContent = () => {
  return (
    <DesktopTemplate>
      <Stack direction="row" spacing={2}>
        <Box sx={{ width: '70%', py: 5 }}>
          <Box sx={{ position: 'relative' }}>
            <img
              src="/images/blogBanner.png"
              alt="blogBanner"
              style={{ width: '100%', height: '100%' }}
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{ position: 'absolute', bottom: 30, left: 20 }}
            >
              <Typography
                sx={{
                  backgroundColor: '#234F68B2',
                  color: 'white',
                  borderRadius: '25px',
                  p: 2,
                  width: 'fit-content',
                  backdropFilter: 'blur(4px)',
                }}
              >
                آموزشی
              </Typography>
              <Typography
                sx={{
                  backgroundColor: '#234F68B2',
                  color: 'white',
                  borderRadius: '25px',
                  p: 2,
                  width: 'fit-content',
                  backdropFilter: 'blur(4px)',
                }}
              >
                سرمایه‌گذاری
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ textAlign: 'left', py: 1 }}>
            <Typography
              sx={{ color: '#204D6C', fontSize: 24, fontWeight: 700 }}
            >
              پروژه حکمت رضوی
            </Typography>
          </Box>
          <Stack direction="row" spacing={3} width="100%">
            <Box sx={{ width: '50%' }}>
              <Typography
                sx={{
                  color: '#252B5C',
                  fontSize: 14,
                  fontWeight: 400,
                  textAlign: 'justify',
                }}
              >
                لورم ایپسوم یا طرح‌نما (به انگلیسی: Lorem ipsum) به متنی آزمایشی
                و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود.
                طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن
                صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده
                می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و ...لورم ایپسوم
                یا طرح‌نما (به انگلیسی: Lorem ipsum) به متنی آزمایشی و بی‌معنی
                در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک
                از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و
                ارایه اولیه شکل ظاهری و کلی طرح سفارش گر
              </Typography>
              <Box sx={{ py: 3 }}>
                <img
                  src="/images/blogContent.png"
                  alt="blogContent"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography
                sx={{
                  color: '#252B5C',
                  fontSize: 14,
                  fontWeight: 400,
                  textAlign: 'justify',
                }}
              >
                لورم ایپسوم یا طرح‌نما (به انگلیسی: Lorem ipsum) به متنی آزمایشی
                و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود.
                طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن
                صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده
                می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و ...لورم ایپسوم
                یا طرح‌نما (به انگلیسی: Lorem ipsum) به متنی آزمایشی و بی‌معنی
                در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک
                از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و
                ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می
                نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و ...لورم ایپسوم یا
                طرح‌نما (به انگلیسی: Lorem ipsum) به متنی آزمایشی و بی‌معنی در
                صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از
                این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه
                اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا
                از نظر گرافیکی نشانگر چگونگی نوع و ...لورم ایپسوم یا طرح‌نما (به
                انگلیسی: Lorem ipsum) به متنی آزمایشی و بی‌معنی در صنعت چاپ،
                صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به
                عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل
                ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر
                گرافیکی نشانگر چگونگی نوع و ...لورم ایپسوم یا طرح‌نما (به
                انگلیسی: Lorem ipsum) به متنی آزمایشی و بی‌معنی در صنعت چاپ،
                صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به
                عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل
                ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر
                گرافیکی نشانگر چگونگی نوع و ...لورم ایپسوم یا طرح‌نما (به
                انگلیسی: Lorem ipsum) به متنی آزمایشی و بی‌معنی در صنعت چاپ،
                صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به
                عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل
                ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر
                گرافیکی نشانگر چگونگی نوع و ...
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Stack sx={{ width: '30%', p: 5 }} spacing={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ backgroundColor: '#F5F4F8', p: 3, borderRadius: '20px' }}
          >
            <Typography sx={{ color: '#53587A' }}>
              آیا مطلب برای شما مفید بود؟
            </Typography>
            <IconButton sx={{ backgroundColor: 'white', borderRadius: '50%' }}>
              <ThumbDownIcon sx={{ color: '#204D6C' }} />
            </IconButton>
            <IconButton sx={{ backgroundColor: 'white', borderRadius: '50%' }}>
              <ThumbUpIcon sx={{ color: '#204D6C' }} />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ backgroundColor: '#F5F4F8', p: 3, borderRadius: '20px' }}
          >
            <Typography sx={{ color: '#53587A' }}>
              به اشتراک بگذارید.
            </Typography>
            <IconButton sx={{ backgroundColor: 'white', borderRadius: '50%' }}>
              <ShareIcon sx={{ color: '#204D6C' }} />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </DesktopTemplate>
  );
};
