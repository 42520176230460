import { FC } from 'react';
import { Container, Stack } from '@mui/material';

export type LoginTemplatePropsType = {
  children: any;
};

const LoginTemplate: FC<LoginTemplatePropsType> = ({ children }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: '100vh',
          position: 'relative',
          minHeight: '600px',
          minWidth: '300px',
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            height: { xs: '100%', sm: 'auto' },
            p: { xs: 0, sm: 'auto' },
            m: { xs: 0, sm: 'auto' },
            borderRadius: { xs: 0, sm: 'auto' },
          }}
        >
          {children}
        </Container>
      </Stack>
    </>
  );
};

export default LoginTemplate;
