import { FC, useState } from 'react';
import { Box, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { Details } from 'src/components/organisms/projects/projectSinglePage/Details';
import { Notice } from 'src/components/organisms/projects/projectSinglePage/Notice';
import { Overview } from 'src/components/organisms/projects/projectSinglePage/Overview';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { BORDER_RADIUS30, BORDER_RADIUS50 } from 'src/configs/theme';
import { Navigate, useParams } from 'react-router-dom';
import Loading from 'src/components/atoms/Loading';
import { useProject } from 'src/components/hooks/useProject';
import { CustomTab } from 'src/components/atoms/CustomTab';
import { YourAsset } from 'src/components/organisms/projects/projectSinglePage/YourAsset';
import { Actions } from 'src/components/organisms/projects/projectSinglePage/Actions';
import { useAssetControllerGetAssetByProjectIdQuery } from 'src/app/services/generatedApi';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ px: 3, pb: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

type ProjectSinglePagePropsType = {};

const ProjectSinglePage: FC<ProjectSinglePagePropsType> = () => {
  const [value, setValue] = useState(0);

  const { project, isLoading } = useProject();

  const { projectId } = useParams();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const { data: hasAsset } = useAssetControllerGetAssetByProjectIdQuery(
    { id: projectId! },
    { skip: !projectId }
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (!project && !isLoading) return <Navigate to="/dashboard/projects" />;
  if (isLoading) return <Loading />;
  return (
    <Grid2
      container
      direction={{ xs: 'column', lg: 'row' }}
      gap={1}
      py={2.9}
      justifyContent={{ lg: 'space-between' }}
      alignItems="stretch"
    >
      <Grid2
        xs={12}
        lg={3}
        rowGap={1}
        direction="column"
        overflow="hidden"
        sx={{
          borderRadius: '30px',
          border: isDesktop
            ? ({ palette }) => '1px solid ' + palette.customColor.color3
            : 'none',
        }}
      >
        <Overview />
      </Grid2>
      <Grid2
        container
        xs={12}
        lg={6}
        rowGap={1}
        direction="column"
        overflow="hidden"
        borderRadius={BORDER_RADIUS30}
        sx={{
          border: isDesktop
            ? ({ palette }) => '1px solid ' + palette.customColor.color3
            : 'none',
        }}
      >
        <Box
          sx={{
            p: { xs: 1, md: 2, lg: 3.1 },
          }}
        >
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            sx={{
              bgcolor: 'customColor.color2',
              borderRadius: BORDER_RADIUS50,
              '& .MuiTabs-indicator': {
                display: 'none',
              },
              p: '8px 10px',
            }}
          >
            {hasAsset && <CustomTab label="دارایی شما" {...a11yProps(0)} />}
            <CustomTab label="جزئیات" {...a11yProps(hasAsset ? 1 : 0)} />
            <CustomTab label="اعلان‌ها" {...a11yProps(hasAsset ? 2 : 1)} />
          </Tabs>
        </Box>
        {hasAsset && (
          <TabPanel value={value} index={0}>
            <YourAsset />
          </TabPanel>
        )}
        <TabPanel value={value} index={hasAsset ? 1 : 0}>
          <Details />
        </TabPanel>
        <TabPanel value={value} index={hasAsset ? 2 : 1}>
          <Notice />
        </TabPanel>
      </Grid2>
      <Grid2
        container
        xs={12}
        lg={2.8}
        rowGap={1}
        direction="column"
        px={{ xs: 2, lg: 1 }}
      >
        <Actions />
      </Grid2>
    </Grid2>
  );
};

export default ProjectSinglePage;
