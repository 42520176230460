import { FC } from 'react';
import { Stack, Toolbar } from '@mui/material';
import { NAVBAR_HEIGHT, Navbar } from '../organisms/layout/navbar/Navbar';
import { Header } from '../organisms/layout/header/Header';

export const SIDEBAR_MIN_WIDTH = 82;

type MobileTemplatePropsType = {
  children: any;
  hideFooter?: boolean;
  showBackButton?: boolean;
};

export const MobileTemplate: FC<MobileTemplatePropsType> = ({
  children,
  hideFooter,
  showBackButton,
}) => {
  return (
    <Stack
      sx={{
        minHeight: '100vh',
        width: '100%',
        maxWidth: '100%',
        overflowX: 'hidden',
        bgcolor: 'white',
        overflowY: 'overlay',
      }}
    >
      <Header showBackButton={showBackButton} />
      <Toolbar />
      {children}
      {!hideFooter && (
        <>
          <Navbar />
          <Toolbar sx={{ height: NAVBAR_HEIGHT + 30 }} />
        </>
      )}
    </Stack>
  );
};
