import { FC, useMemo } from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  BORDER_RADIUS10,
  BORDER_RADIUS15,
  BORDER_RADIUS20,
} from 'src/configs/theme';
import { AssetRo } from 'src/app/services/generatedApi';
import { useProject } from 'src/components/hooks/useProject';
import { price2Persian } from 'src/utils/price2Persian.util';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { projectState2persian } from 'src/utils/projectState.util';
import { useNavigate } from 'react-router-dom';

type AssetsCardPropsType = {
  asset: AssetRo;
};

export const AssetsCard: FC<AssetsCardPropsType> = ({ asset }) => {
  const { project: projectId, shareAmount } = asset;

  const { project } = useProject(projectId);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const todaysValueOfTheAsset = useMemo(() => {
    if (!project) return 0;
    return project.earnedProfit * shareAmount * project.priceOfShare;
  }, [project, shareAmount]);

  const goToProject = () => navigate('/dashboard/projects/' + projectId);

  return (
    <Grid2
      container
      direction={{ xs: 'row', md: 'column' }}
      width="100%"
      bgcolor="customColor.color2"
      borderRadius={BORDER_RADIUS20}
      rowGap={2}
      py={{ xs: 1, md: 2, lg: 3.1, cursor: 'pointer' }}
      alignItems="center"
      onClick={goToProject}
    >
      <Grid2
        xs={3.65}
        md={12}
        sx={{ height: { xs: 104, md: 'auto', position: 'relative' } }}
      >
        {!project || project.gallery.length === 0 ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            width="100%"
            height={{ xs: '100%', md: 104 }}
          >
            <BrokenImageIcon
              color="disabled"
              sx={{ fontSize: { xs: 70, md: 120 } }}
            />
          </Stack>
        ) : isMd ? (
          <Stack direction="row" sx={{ width: 'fit-content' }} columnGap={2}>
            {project?.gallery?.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item}
                  alt=""
                  style={{
                    width: 104,
                    height: 104,
                    borderRadius: BORDER_RADIUS15,
                  }}
                />
              );
            })}
          </Stack>
        ) : (
          <img
            src={project!.gallery[0]}
            alt=""
            style={{
              width: 104,
              height: 104,
              borderRadius: BORDER_RADIUS15,
            }}
          />
        )}
        <Typography
          variant="t400_12"
          color="white"
          sx={{
            position: 'absolute',
            bgcolor: '#234F68B2',
            backdropFilter: 'blur(4px)',
            py: 0.3,
            px: 1,
            bottom: 10,
            left: 20,
            borderRadius: BORDER_RADIUS10,
          }}
        >
          {projectState2persian(project?.state)}
        </Typography>
      </Grid2>
      <Grid2 xs={8} md={12} px={{ xs: 1, md: 2, lg: 3.1 }}>
        <Stack rowGap={{ xs: 0, md: 0.5 }}>
          <Typography variant="t700_16" pb={{ xs: 1.25, md: 1.3 }}>
            {project?.title}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="t400_14" color="text.secondary">
              تا انتهای پروژه
            </Typography>
            <Typography variant="t400_16">۱۸ ماه</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="t400_14" color="text.secondary">
              سرمایه اولیه شما
            </Typography>
            <Typography variant="t400_16">
              {price2Persian(shareAmount * (project?.priceOfShare || 0))} تومان
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="t400_14" color="text.secondary">
              ارزش امروز دارایی
            </Typography>
            <Typography variant="t400_16">
              {price2Persian(todaysValueOfTheAsset)} تومان
            </Typography>
          </Stack>
        </Stack>
      </Grid2>
    </Grid2>
  );
};
