import { FC } from 'react';
import { AuthCode } from './AuthCode';
import { AuthNumber } from './AuthNumber';

type authContentType = { title: string; component: FC<AuthContentPropsType> };

export const authContents: authContentType[] = [
  { title: 'AuthNumber', component: AuthNumber },
  { title: 'AuthCode', component: AuthCode },
];

export type AuthContentPropsType = {
  changePage: (page: number) => void;
  handleCloseDialog: () => void;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
};
