import { enhancedApi } from './generatedApi';

const addTagTypes = [
  'Profile',
  'Asset',
  'Installment',
  'Transaction',
  'Project',
];

export const api = enhancedApi.enhanceEndpoints({
  addTagTypes,
  endpoints: {
    // =============================== PROJECT =============================== //
    projectControllerFindAll: {
      providesTags: () => ['Project'],
    },
    projectControllerFindProjectById: {
      providesTags: () => ['Project'],
    },
    // =============================== PROFILE =============================== //
    userControllerGetCurrentUserProfile: {
      providesTags: () => ['Profile'],
    },
    authControllerLogin: {
      invalidatesTags: () => ['Profile'],
    },
    userControllerUpdateCurrentUser: {
      invalidatesTags: () => ['Profile'],
    },
    // =============================== TRANSACTION =============================== //
    transactionControllerCreatePortalTransaction: {
      invalidatesTags: () => ['Transaction'],
    },
    transactionControllerGetMyTransactions: {
      providesTags: () => ['Transaction'],
    },
    // =============================== ASSET =============================== //
    assetControllerGetAssetById: {
      providesTags: () => ['Asset', 'Installment'], //TODO:fix
    },
    assetControllerGetCurrentUserAssets: {
      providesTags: () => ['Asset', 'Installment'], //TODO:fix
    },
    assetControllerGetCurrentUserAssetsValuation: {
      providesTags: () => ['Asset', 'Installment'], //TODO:fix
    },
  },
});
